import Vue from 'vue'

class TicketRevenues {
  async createTicket({ticketId, data}) {
    return await Vue.axios
      .post('admin/ticket-revenues-by-ticket/' + ticketId, data)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('admin/ticket-revenues/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('admin/ticket-revenues/' + id)
  }
}

const ticketRevenues = new TicketRevenues()

export default ticketRevenues
