<template>
  <v-card
      class="ma-3"
      width="350"
  >
    <v-card-title>
      <span
          class="text-truncate"
          style="max-width: 300px"
      >
        {{ User.name }}
      </span>

      <v-spacer/>

      <v-btn
          v-if="['ROLE_MASTER', 'ROLE_EDITOR', 'ROLE_MANAGER'].includes($store.state.auth.User.role)"
          :small="true"
          :icon="true"
          :outlined="true"
          color="success"
          @click="createUserRemind"
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <template
          v-if="sortedUserRemindsNotArchive.length > 0"
      >
        <UserRemind
            v-for="UserRemind in sortedUserRemindsNotArchive"
            :key="UserRemind.id"
            :UserRemind="UserRemind"
            @refresh="$emit('init')"
        />
      </template>

      <p
          v-else
      >
        Žiadne
      </p>

      <v-expansion-panels
          v-if="sortedUserRemindsArchive.length > 0"
          v-model="expansionPanelsArchive"
          :multiple="true"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span>
              <v-icon
                  color="secondary"
              >
                mdi-archive
              </v-icon>

              Archív
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <p
                v-for="UserRemind in sortedUserRemindsArchive"
                :key="UserRemind.id"
                :class="
                  UserRemind.strikeOut
                    ? 'text-decoration-line-through'
                    : ''
                "
            >
              {{ new Date(UserRemind.createdAt)|dateFormat('DD.MM.YYYY') }} - {{ UserRemind.content }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
  import UserReminds from '@/services/user-reminds'

  import UserRemind from '@/components/Home/Users/User/UserRemind'

  export default {
    name: 'User',
    props: [
      'User'
    ],
    components: {
      UserRemind
    },
    data() {
      return {
        expansionPanelsArchive: [],
        errors: {}
      }
    },
    computed: {
      sortedUserRemindsNotArchive() {
        const sortedUserRemindsNotArchive = [...this.User.userReminds]

        sortedUserRemindsNotArchive.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

        return sortedUserRemindsNotArchive.filter((UserRemind) => !UserRemind.archive)
      },
      sortedUserRemindsArchive() {
        const sortedUserRemindsArchive = [...this.User.userReminds]

        sortedUserRemindsArchive.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

        return sortedUserRemindsArchive.filter((UserRemind) => UserRemind.archive)
      }
    },
    methods: {
      createUserRemind() {
        UserReminds
          .createUser({
            userId: this.User.id,
            data: {}
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 403:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/home')

                break
            }
          })
      }
    }
  }
</script>
