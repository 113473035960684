<template>
  <v-row>
    <v-col
        cols="12"
    >
      <Projects/>
    </v-col>
  </v-row>
</template>

<script>
  import Projects from '@/components/Health/Projects'

  export default {
    name: 'Health',
    components: {
      Projects
    },
    metaInfo() {
      return {
        title: 'Zdravie'
      }
    }
  }
</script>
