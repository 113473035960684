import Vue from 'vue'

import router from '@/plugins/router'
import store from '@/plugins/store'
import vuetify from '@/plugins/vuetify'

import '@/plugins/axios'
import '@/plugins/filter-date-format'
import '@/plugins/highcharts'
import '@/plugins/meta'
import '@/plugins/vuelidate'

import App from '@/App'

import '@/assets/css/style.css'

const options = {
  router,
  store,
  vuetify,
  render: (element) => {
    return element(App)
  }
}

new Vue(options).$mount('#app')
