import Vue from 'vue'

import Qs from 'qs'

class TicketItems {
  async indexAuth({params}) {
    return await Vue.axios
      .get('admin/ticket-items-by-auth', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async indexTicket({ticketId, params}) {
    return await Vue.axios
      .get('admin/ticket-items-by-ticket/' + ticketId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async indexUser({userId, params}) {
    return await Vue.axios
      .get('admin/ticket-items-by-user/' + userId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async createTicket({ticketId, data}) {
    return await Vue.axios
      .post('admin/ticket-items-by-ticket/' + ticketId, data)
  }

  async read({id}) {
    return await Vue.axios
      .get('admin/ticket-items/' + id)
  }

  async readAuth({id}) {
    return await Vue.axios
      .get('admin/ticket-items-by-auth/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('admin/ticket-items/' + id, data)
  }

  async updateAuth({id, data}) {
    return await Vue.axios
      .put('admin/ticket-items-by-auth/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('admin/ticket-items/' + id)
  }
}

const ticketItems = new TicketItems()

export default ticketItems
