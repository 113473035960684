<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="warning"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-pencil
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          Upraviť tarifu

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre upravenie tarify je potrebné vyplniť všetky povinné polia.
          </p>

          <v-text-field
              v-model="TariffForm.title"
              :error-messages="errors.title"
              :outlined="true"
              :dense="true"
              label="Názov"
          />

          <v-textarea
              v-model="TariffForm.description"
              :error-messages="errors.description"
              :outlined="true"
              :dense="true"
              label="Popis"
              :rows="2"
          />

          <v-text-field
              v-model="TariffForm.price"
              :error-messages="errors.price"
              :outlined="true"
              :dense="true"
              label="Sadzba (€)"
          />

          <v-select
              v-model="TariffForm.unit"
              :error-messages="errors.unit"
              :outlined="true"
              :dense="true"
              label="Jednotka"
              :items="$store.getters['register/getTariffUnit']"
          />

          <v-select
              v-model="TariffForm.frequency"
              :error-messages="errors.frequency"
              :outlined="true"
              :dense="true"
              label="Frekvencia"
              :items="$store.getters['register/getTariffFrequency']"
          />

          <v-select
              v-model="TariffForm.type"
              :error-messages="errors.type"
              :outlined="true"
              :dense="true"
              label="Typ"
              :items="$store.getters['register/getTariffType']"
          />

          <template
              v-if="TariffForm.type === $store.state.register.Tariff.type['group'].value"
          >
            <v-text-field
                v-model="TariffForm.timeOffers"
                :error-messages="errors.timeOffers"
                :outlined="true"
                :dense="true"
                label="Čas práce (h)"
            />

            <v-text-field
                v-model="TariffForm.priceOffers"
                :error-messages="errors.priceOffers"
                :outlined="true"
                :dense="true"
                label="Cena práce (€)"
            />
          </template>

          <template
              v-if="TariffForm.type === $store.state.register.Tariff.type['simple'].value"
          >
            <v-text-field
                v-model="TariffForm.minProfit"
                :error-messages="errors.minProfit"
                :outlined="true"
                :dense="true"
                label="Minimálny zárobok (%)"
            />
          </template>

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="warning"
              @click="submit"
          >
            <v-icon>
              mdi-pencil
            </v-icon>

            Upraviť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Tariffs from '@/services/tariffs'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    data() {
      return {
        loading: false,
        dialog: false,
        Tariff: {},
        TariffForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Tariffs
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Tariff = response.data.data.Tariff

            this.TariffForm = {
              title: response.data.data.Tariff.title,
              description: response.data.data.Tariff.description,
              price: response.data.data.Tariff.price,
              unit: response.data.data.Tariff.unit,
              frequency: response.data.data.Tariff.frequency,
              type: response.data.data.Tariff.type,
              timeOffers: response.data.data.Tariff.timeOffers,
              priceOffers: response.data.data.Tariff.priceOffers,
              minProfit: response.data.data.Tariff.minProfit
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.dialog = true

            this.loading = false
          })
      },
      submit() {
        this.loading = true

        Tariffs
          .update({
            id: this.id,
            data: this.TariffForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
