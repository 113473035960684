<template>
  <v-card>
    <v-card-title>
      Projekty ({{ count }})

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-tabs
              v-model="tabsHealthResult"
              color="secondary"
          >
            <v-tab
                @click="setHealthResult('')"
            >
              Všetko
            </v-tab>

            <v-tab
                v-for="(healthResult, i) in $store.getters['register/getProjectHealthResult']"
                :key="i"
                @click="setHealthResult(healthResult.value)"
            >
              <v-icon
                  :color="healthResult.color"
              >
                {{ healthResult.icon }}
              </v-icon>

              &nbsp;

              {{ healthResult.text }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-expansion-panels
              v-model="expansionPanelsFilter"
              :multiple="true"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>
                  <v-icon
                      color="info"
                  >
                    mdi-filter
                  </v-icon>

                  Filtre
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.name"
                        :error-messages="errors.Filters && errors.Filters.name"
                        :outlined="true"
                        :dense="true"
                        label="Meno"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-card>
            <v-card-text>
              <v-progress-linear
                  v-if="loading"
                  :indeterminate="true"
              />

              <v-simple-table>
                <template
                    v-slot:default
                >
                  <thead>
                  <tr>
                    <th
                        class="fixed"
                    >
                      <a
                          @click="setOrder($store.state.register.Project.Filter.order['name'].value)"
                      >
                        <template
                            v-if="params.order === $store.state.register.Project.Filter.order['name'].value"
                        >
                          <template
                              v-if="params.by === $store.state.register.Project.Filter.by['ASC'].value"
                          >
                            <v-icon>
                              mdi-chevron-up
                            </v-icon>
                          </template>

                          <template
                              v-else
                          >
                            <v-icon>
                              mdi-chevron-down
                            </v-icon>
                          </template>
                        </template>

                        Meno
                      </a>
                    </th>

                    <th
                        class="text-right fixed"
                    />
                  </tr>
                  </thead>

                  <tbody>
                  <template
                      v-if="projects.length > 0"
                  >
                    <tr
                        v-for="Project in projects"
                        :key="Project.id"
                    >
                      <td
                          class="fixed"
                      >
                        <v-icon
                            :color="$store.state.register.Project.healthResult[Project.healthResult].color"
                        >
                          {{ $store.state.register.Project.healthResult[Project.healthResult].icon }}
                        </v-icon>

                        &nbsp;

                        <span
                            class="text-truncate"
                            style="max-width: 200px"
                        >
                            {{ Project.name }}
                          </span>
                      </td>

                      <td
                          class="text-right fixed"
                      >
                        <Detail
                            :icon="true"
                            :id="Project.id"
                        />
                      </td>
                    </tr>
                  </template>

                  <template
                      v-else
                  >
                    <tr>
                      <td
                          :colspan="2"
                          class="text-center"
                      >
                        Nenašli sa žiadne projekty.
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
          v-if="pages !== 0"
          align="center"
      >
        <v-col
            cols="4"
        >
          <a
              v-for="(limit, i) in $store.getters['register/getProjectFilterLimit']"
              :key="i"
              @click="setLimit(limit.value)"
          >
            {{ limit.text }}
          </a>
        </v-col>

        <v-spacer/>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === 1 || loading"
              color="primary"
              @click="previousPage"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-col>

        <v-col
            cols="auto"
        >
          {{ params.page }} / {{ pages }}
        </v-col>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === pages || loading"
              color="primary"
              @click="nextPage"
          >
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import Qs from 'qs'
  import QueryTypes from 'query-types'

  import Projects from '@/services/projects'

  import Detail from '@/components/Health/Projects/Detail'

  export default {
    name: 'Projects',
    components: {
      Detail
    },
    data() {
      return {
        tabsHealthResult: 0,
        expansionPanelsFilter: [],
        loading: false,
        count: 0,
        pages: 1,
        projects: [],
        params: {
          Filters: {
            name: '',
            healthResult: ''
          },
          order: this.$store.state.register.Project.Filter.order['name'].value,
          by: this.$store.state.register.Project.Filter.by['ASC'].value,
          limit: this.$store.state.register.Project.Filter.limit[25].value,
          page: 1
        },
        errors: {}
      }
    },
    beforeMount() {
      this.beforeInit()
    },
    mounted() {
      this.init()
    },
    methods: {
      beforeInit() {
        this.params = {
          ...this.params,
          ...Qs.parse(QueryTypes.parseObject(this.$route.query))
        }

        this.tabsHealthResult = (this.$store.getters['register/getProjectHealthResult'].map((healthResult) => healthResult.value).indexOf(this.params.Filters.healthResult) + 1)
      },
      init() {
        this.loadData()
      },
      setHealthResult(healthResult) {
        this.params.Filters.healthResult = healthResult

        this.params.page = 1

        this.init()
      },
      setFilter() {
        this.params.page = 1

        this.init()
      },
      setOrder(order) {
        this.params.order = order

        switch (this.params.by) {
          case this.$store.state.register.Project.Filter.by['ASC'].value:
            this.params.by = this.$store.state.register.Project.Filter.by['DESC'].value

            break

          case this.$store.state.register.Project.Filter.by['DESC'].value:
            this.params.by = this.$store.state.register.Project.Filter.by['ASC'].value

            break
        }

        this.params.page = 1

        this.init()
      },
      setLimit(limit) {
        this.params.limit = limit

        this.params.page = 1

        this.init()
      },
      previousPage() {
        this.params.page -= 1

        window.scrollTo(0, 0)

        this.init()
      },
      nextPage() {
        this.params.page += 1

        window.scrollTo(0, 0)

        this.init()
      },
      loadData() {
        this.loading = true

        this.$router.replace('?' + Qs.stringify(this.params))

        Projects
            .indexOnlyHealth({
              params: this.params
            })
            .then((response) => {
              this.count = response.data.data.count

              this.pages = response.data.data.pages

              this.projects = response.data.data.projects

              this.errors = {}
            })
            .catch((error) => {
              switch (error.response.status) {
                case 400:
                  this.errors = error.response.data.errors

                  break

                case 401:
                case 500:
                  this.$store.dispatch('layout/openSnackbar', {
                    message: error.response.data.message
                  })

                  break
              }
            })
            .finally(() => {
              this.loading = false
            })
      }
    }
  }
</script>
