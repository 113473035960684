import Vue from 'vue'

import Qs from 'qs'

class Projects {
  async suggest() {
    return await Vue.axios
      .get('admin/projects/suggest')
  }

  async index({params}) {
    return await Vue.axios
      .get('admin/projects', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async indexOnlyHealth({params}) {
    return await Vue.axios
        .get('admin/projects-only-health', {
          params,
          paramsSerializer: (params) => {
            return Qs.stringify(params)
          }
        })
  }

  async create({data}) {
    return await Vue.axios
      .post('admin/projects', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('admin/projects/' + id)
  }

  async readOnlyHealth({id}) {
    return await Vue.axios
        .get('admin/projects-only-health/' + id)
  }

  async readTicket({ticketId}) {
    return await Vue.axios
      .get('admin/projects-by-ticket/' + ticketId)
  }

  async readTicketItem({ticketItemId}) {
    return await Vue.axios
      .get('admin/projects-by-ticket-item/' + ticketItemId)
  }

  async readTicketItemAuth({ticketItemId}) {
    return await Vue.axios
      .get('admin/projects-by-ticket-item-and-auth/' + ticketItemId)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('admin/projects/' + id, data)
  }

  async updateTicket({ticketId, data}) {
    return await Vue.axios
      .put('admin/projects-by-ticket/' + ticketId, data)
  }

  async updateTicketItem({ticketItemId, data}) {
    return await Vue.axios
      .put('admin/projects-by-ticket-item/' + ticketItemId, data)
  }

  async updateTicketItemAuth({ticketItemId, data}) {
    return await Vue.axios
      .put('admin/projects-by-ticket-item-and-auth/' + ticketItemId, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('admin/projects/' + id)
  }
}

const projects = new Projects()

export default projects
