<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="warning"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-pencil
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="1000"
    >
      <v-card>
        <v-card-title>
          Upraviť projekt

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre upravenie projektu je potrebné vyplniť všetky povinné polia.
          </p>

          <v-select
              v-model="ProjectForm.status"
              :error-messages="errors.status"
              :outlined="true"
              :dense="true"
              label="Stav"
              :items="$store.getters['register/getProjectStatus']"
          />

          <v-checkbox
              v-model="ProjectForm.priority"
              :error-messages="errors.priority"
              class="ma-0 pa-0"
              label="Priorita"
          />

          <ImageFiles
              :outlined="true"
              :dense="true"
              label="Obrázok"
              :File="ProjectForm.File"
              :errorsFile="errors.File"
              @setFile="setFile"
          />

          <v-text-field
              v-model="ProjectForm.name"
              :error-messages="errors.name"
              :outlined="true"
              :dense="true"
              label="Meno"
          />

          <SuggestClients
              v-model="ProjectForm.Client"
              :error-messages="errors.Client"
              :outlined="true"
              :dense="true"
              label="Klient"
              :clearable="true"
              :createButton="true"
          />

          <v-select
              v-model="ProjectForm.type"
              :error-messages="errors.type"
              :outlined="true"
              :dense="true"
              label="Typ"
              :items="$store.getters['register/getProjectType']"
          />

          <v-text-field
              v-model="ProjectForm.discount"
              :error-messages="errors.discount"
              :outlined="true"
              :dense="true"
              label="Zľava (%)"
          />

          <EditorPartials
              v-model="ProjectForm.noteProduction"
              label="Prístupy - Produkčné"
          />

          <EditorPartials
              v-model="ProjectForm.noteDevelopment"
              label="Prístupy - Vývojové"
          />

          <v-checkbox
              v-model="ProjectForm.healthFunction"
              :error-messages="errors.healthFunction"
              class="ma-0 pa-0"
              label="Funkcia zdravia"
          />

          <v-text-field
              v-if="ProjectForm.healthFunction"
              v-model="ProjectForm.healthUrl"
              :error-messages="errors.healthUrl"
              :outlined="true"
              :dense="true"
              label="URL funkcie zdravia"
          />

          <EditorPartials
              v-model="ProjectForm.about"
              label="..."
          />

          <v-textarea
              v-model="ProjectForm.description"
              :error-messages="errors.description"
              :outlined="true"
              :dense="true"
              label="Popis"
              :rows="2"
          />

          <v-textarea
              v-model="ProjectForm.keywords"
              :error-messages="errors.keywords"
              :outlined="true"
              :dense="true"
              label="Kľúčové slová"
              :rows="2"
              :persistent-hint="true"
              hint="V prípade, že viac tak: ,"
          />

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="warning"
              @click="submit"
          >
            <v-icon>
              mdi-pencil
            </v-icon>

            Upraviť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Projects from '@/services/projects'

  import SuggestClients from '@/components/Clients/Suggest'

  import ImageFiles from '@/components/Files/Image'

  import EditorPartials from '@/components/_Partials/Editor'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    components: {
      SuggestClients,
      ImageFiles,
      EditorPartials
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Project: {},
        ProjectForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Projects
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Project = response.data.data.Project

            this.ProjectForm = {
              status: response.data.data.Project.status,
              priority: response.data.data.Project.priority,
              File: (
                response.data.data.Project.File
                  ? response.data.data.Project.File.id
                  : ''
              ),
              name: response.data.data.Project.name,
              Client: (
                response.data.data.Project.Client
                  ? response.data.data.Project.Client.id
                  : ''
              ),
              type: response.data.data.Project.type,
              discount: response.data.data.Project.discount,
              noteDevelopment: response.data.data.Project.noteDevelopment,
              noteProduction: response.data.data.Project.noteProduction,
              about: response.data.data.Project.about,
              description: response.data.data.Project.description,
              keywords: response.data.data.Project.keywords,
              healthFunction: response.data.data.Project.healthFunction,
              healthUrl: response.data.data.Project.healthUrl
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.dialog = true

            this.loading = false
          })
      },
      setFile(File) {
        this.ProjectForm.File = File
      },
      submit() {
        this.loading = true

        Projects
          .update({
            id: this.id,
            data: this.ProjectForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
