<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="success"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-plus
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="1000"
    >
      <v-card>
        <v-card-title>
          Vytvoriť pracovné miesto

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre vytvorenie pracovného miesta je potrebné vyplniť všetky povinné polia.
          </p>

          <ImageFiles
              :outlined="true"
              :dense="true"
              label="Obrázok"
              :File="JobForm.File"
              :errorsFile="errors.File"
              @setFile="setFile"
          />

          <v-text-field
              v-model="JobForm.title"
              :error-messages="errors.title"
              :outlined="true"
              :dense="true"
              label="Názov"
          />

          <EditorPartials
              v-model="JobForm.about"
              label="..."
          />

          <v-textarea
              v-model="JobForm.description"
              :error-messages="errors.description"
              :outlined="true"
              :dense="true"
              label="Popis"
              :rows="2"
          />

          <v-textarea
              v-model="JobForm.keywords"
              :error-messages="errors.keywords"
              :outlined="true"
              :dense="true"
              label="Kľúčové slová"
              :rows="2"
              :persistent-hint="true"
              hint="V prípade, že viac tak: ,"
          />

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="success"
              @click="submit"
          >
            <v-icon>
              mdi-plus
            </v-icon>

            Vytvoriť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Jobs from '@/services/jobs'

  import ImageFiles from '@/components/Files/Image'

  import EditorPartials from '@/components/_Partials/Editor'

  export default {
    name: 'Create',
    components: {
      ImageFiles,
      EditorPartials
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Job: {},
        JobForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = true

        this.JobForm = {
          File: null,
          title: '',
          about: '',
          description: '',
          keywords: ''
        }

        this.errors = {}
      },
      setFile(File) {
        this.JobForm.File = File
      },
      submit() {
        this.loading = true

        Jobs
          .create({
            data: this.JobForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
