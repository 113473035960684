<template>
  <Tickets
      type="new"
      title="Nové tikety"
  />
</template>

<script>
  import Tickets from '@/views/Tickets'

  export default {
    name: 'TicketsNew',
    components: {
      Tickets
    }
  }
</script>
