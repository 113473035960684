<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="success"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-plus
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="500"
    >
      <v-card>
        <template
            v-if="window"
        >
          <v-card-title>
            Čo ďalej?

            <v-spacer/>

            <v-btn
                :icon="true"
                color="default"
                @click="closeDialog"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <p>
              Pre pokračovanie je potrebné vybrať z možností.
            </p>

            <template
                v-if="TicketItem.ticketItemOfferCreatable"
            >
              <CreateTicketItemOffer
                  :rounded="true"
                  :TicketItem="TicketItem"
                  @refresh="$emit('refresh')"
                  @init="$emit('init')"
              >
                Cenová ponuka
              </CreateTicketItemOffer>

              &nbsp;
            </template>

            <v-btn
                :rounded="true"
                :outlined="true"
                :loading="loading"
                :disabled="loading"
                color="success"
                @click="init"
            >
              <v-icon>
                mdi-plus
              </v-icon>

              Ďalšia položka
            </v-btn>
          </v-card-text>
        </template>

        <template
            v-else
        >
          <v-card-title>
            Vytvoriť položku

            <v-spacer/>

            <v-btn
                :icon="true"
                color="default"
                @click="closeDialog"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <p>
              Pre vytvorenie položky je potrebné vyplniť všetky povinné polia.
            </p>

            <SuggestTariffs
                v-model="TicketItemForm.Tariff"
                :error-messages="errors.Tariff"
                :outlined="true"
                :dense="true"
                label="Tarifa"
                :clearable="true"
                :createButton="['ROLE_MASTER'].includes($store.state.auth.User.role)"
            />

            <v-text-field
                v-model="TicketItemForm.title"
                :error-messages="errors.title"
                :outlined="true"
                :dense="true"
                label="Názov"
            />

            <v-textarea
                v-model="TicketItemForm.description"
                :error-messages="errors.description"
                :outlined="true"
                :dense="true"
                label="Popis"
                :rows="2"
            />

            <template
                v-if="Ticket.type === $store.state.register.Ticket.type['pricelist'].value"
            >
              <v-text-field
                  v-model="TicketItemForm.pricelistQuantity"
                  :error-messages="errors.pricelistQuantity"
                  :outlined="true"
                  :dense="true"
                  label="Množstvo (x)"
              />

              <v-text-field
                  v-model="TicketItemForm.pricelistIncrease"
                  :error-messages="errors.pricelistIncrease"
                  :outlined="true"
                  :dense="true"
                  label="Prirážka (€)"
              />
            </template>

            <v-btn
                :rounded="true"
                :outlined="true"
                :loading="loading"
                :disabled="loading"
                color="success"
                @click="submit"
            >
              <v-icon>
                mdi-plus
              </v-icon>

              Vytvoriť
            </v-btn>
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import TicketItems from '@/services/ticket-items'

  import CreateTicketItemOffer from '@/components/TicketItem/TicketItemOffers/Create'

  import SuggestTariffs from '@/components/Tariffs/Suggest'

  export default {
    name: 'Create',
    props: [
      'Ticket'
    ],
    components: {
      CreateTicketItemOffer,
      SuggestTariffs
    },
    data() {
      return {
        loading: false,
        dialog: false,
        TicketItem: {},
        TicketItemForm: {},
        errors: {},
        window: false
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = true

        this.TicketItemForm = {
          Tariff: null,
          title: '',
          description: ''
        }

        if (this.Ticket.type === this.$store.state.register.Ticket.type['pricelist'].value) {
          this.TicketItemForm = {
            ...this.TicketItemForm,
            pricelistIncrease: 0,
            pricelistQuantity: 0
          }
        }

        this.errors = {}

        this.window = false
      },
      submit() {
        this.loading = true

        TicketItems
          .createTicket({
            ticketId: this.Ticket.id,
            data: this.TicketItemForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.$emit('refresh')

            this.$emit('init')

            TicketItems
              .read({
                id: response.data.data.id
              })
              .then((response) => {
                this.TicketItem = response.data.data.TicketItem
              })
              .catch((error) => {
                switch (error.response.status) {
                  case 401:
                  case 404:
                  case 500:
                    this.$store.dispatch('layout/openSnackbar', {
                      message: error.response.data.message
                    })

                    this.closeDialog()

                    break
                }
              })
              .finally(() => {
                this.window = true
              })
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 403:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
        .finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>
