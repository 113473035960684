<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="success"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-plus
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="1000"
    >
      <v-card>
        <v-card-title>
          Vytvoriť kontakt

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre vytvorenie kontaktu je potrebné vyplniť všetky povinné polia.
          </p>

          <v-text-field
              v-model="ContactForm.title"
              :error-messages="errors.title"
              :outlined="true"
              :dense="true"
              label="Názov"
          />

          <v-select
              v-model="ContactForm.type"
              :error-messages="errors.type"
              :outlined="true"
              :dense="true"
              label="Typ"
              :items="$store.getters['register/getContactType']"
          />

          <template
              v-if="ContactForm.type === $store.state.register.Contact.type['person'].value"
          >
            <ImageFiles
                :outlined="true"
                :dense="true"
                label="Obrázok"
                :File="ContactForm.File"
                :errorsFile="errors.File"
                @setFile="setFile"
            />
          </template>

          <template
              v-if="ContactForm.type === $store.state.register.Contact.type['info'].value"
          >
            <v-text-field
                v-model="ContactForm.icon"
                :error-messages="errors.icon"
                :outlined="true"
                :dense="true"
                label="Ikona"
            />
          </template>

          <EditorPartials
              v-model="ContactForm.about"
              label="..."
          />

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="success"
              @click="submit"
          >
            <v-icon>
              mdi-plus
            </v-icon>

            Vytvoriť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Contacts from '@/services/contacts'

  import ImageFiles from '@/components/Files/Image'

  import EditorPartials from '@/components/_Partials/Editor'

  export default {
    name: 'Create',
    components: {
      ImageFiles,
      EditorPartials
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Contact: {},
        ContactForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = true

        this.ContactForm = {
          title: '',
          type: this.$store.state.register.Contact.type['info'].value,
          File: null,
          icon: '',
          about: ''
        }

        this.errors = {}
      },
      setFile(File) {
        this.ContactForm.File = File
      },
      submit() {
        this.loading = true

        Contacts
          .create({
            data: this.ContactForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
