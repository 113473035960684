<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="primary"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-magnify
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="1000"
    >
      <v-card>
        <v-card-title>
          {{ Project.name }}

          <template
              v-if="Project.healthStatusCode"
          >
            ({{ Project.healthStatusCode }})
          </template>

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle>
          {{ new Date(Project.healthSyncAt)|dateFormat('DD.MM.YYYY HH:mm') }}
        </v-card-subtitle>

        <v-card-text>
          <v-simple-table>
            <template
                v-slot:default
            >
              <thead>
                <tr>
                  <th
                      class="fixed"
                  >
                    Kontroly
                  </th>

                  <th>
                    Dátum poslednej kontroly
                  </th>

                  <th>
                    Verzia
                  </th>

                  <th>
                    Aktualizácia
                  </th>
                </tr>
              </thead>

              <tbody>
                <template
                    v-if="healthResponse"
                >
                  <tr
                      key="settingsa"
                      @click="toggleRecord('settings')"
                      :class="
                        healthResponse.settings.count_updates > 0
                          ? 'orange lighten-4'
                          : ''
                      "
                  >
                    <td
                        class="fixed"
                    >
                      Nastavenia
                    </td>

                    <td>
                      {{ new Date(healthResponse.settings.last_checked)|dateFormat('DD.MM.YYYY HH:mm') }}
                    </td>

                    <td>
                      {{ healthResponse.settings.count }}
                    </td>

                    <td>
                      {{ healthResponse.settings.count_updates }}
                    </td>
                  </tr>

                  <tr
                      v-if="records.includes('settings')"
                      key="settingsb"
                  >
                    <td
                        :colspan="4"
                    >
                      <h3
                          class="mt-4"
                      >
                        Zoznam
                      </h3>

                      <v-simple-table
                          class="my-4"
                      >
                        <template
                            v-slot:default
                        >
                          <thead>
                            <tr>
                              <th
                                  class="fixed"
                              >
                                Názov
                              </th>

                              <th>
                                Verzia
                              </th>

                              <th>
                                Aktualizácia
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <template
                                v-if="healthResponse.settings.rows.length > 0"
                            >
                              <tr
                                  v-for="Row in healthResponse.settings.rows"
                                  :key="Row.id"
                                  :class="
                                      Row.update.status
                                        ? 'orange lighten-4'
                                        : ''
                                    "
                              >
                                <td
                                    class="fixed"
                                >
                                    <span
                                        class="text-truncate"
                                        style="max-width: 300px"
                                    >
                                      {{ Row.name }}
                                    </span>
                                </td>

                                <td>
                                  <template
                                      v-if="Row.value"
                                  >
                                    áno
                                  </template>

                                  <template
                                      v-else
                                  >
                                    nie
                                  </template>
                                </td>

                                <td>
                                  <template
                                      v-if="Row.update.status"
                                  >
                                    <template
                                        v-if="Row.update.value"
                                    >
                                      áno
                                    </template>

                                    <template
                                        v-else
                                    >
                                      nie
                                    </template>
                                  </template>

                                  <template
                                      v-else
                                  >
                                    -
                                  </template>
                                </td>
                              </tr>
                            </template>

                            <template
                                v-else
                            >
                              <tr>
                                <td
                                    :colspan="3"
                                    class="text-center"
                                >
                                  Nenašli sa žiadne nastavenia.
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </td>
                  </tr>

                  <tr
                      key="wordpressa"
                      :class="
                        healthResponse.core.update.status
                          ? 'orange lighten-4'
                          : ''
                      "
                  >
                    <td
                        class="fixed"
                    >
                      Wordpress
                    </td>

                    <td>
                      {{ new Date(healthResponse.core.last_checked)|dateFormat('DD.MM.YYYY HH:mm') }}
                    </td>

                    <td>
                      {{ healthResponse.core.version }}
                    </td>

                    <td>
                      <template
                          v-if="healthResponse.core.update.status"
                      >
                        {{ healthResponse.core.update.version }}

                        <small
                            v-if="healthResponse.core.update.requires_mysql || healthResponse.core.update.requires_php"
                        >
                          <br/>

                          <template
                              v-if="healthResponse.core.update.requires_mysql"
                          >
                            <v-icon
                                :small="true"
                            >
                              mdi-database-outline
                            </v-icon>

                            {{ healthResponse.core.update.requires_mysql}}
                          </template>

                          <template
                              v-if="healthResponse.core.update.requires_php"
                          >
                            <v-icon
                                :small="true"
                            >
                              mdi-language-php
                            </v-icon>

                            {{ healthResponse.core.update.requires_php}}
                          </template>
                        </small>
                      </template>

                      <template
                          v-else
                      >
                        -
                      </template>
                    </td>
                  </tr>

                  <tr
                      key="themesa"
                      @click="toggleRecord('themes')"
                      :class="
                        healthResponse.themes.count_updates > 0
                          ? 'orange lighten-4'
                          : ''
                      "
                  >
                    <td
                        class="fixed"
                    >
                      Šablóny
                    </td>

                    <td>
                      {{ new Date(healthResponse.themes.last_checked)|dateFormat('DD.MM.YYYY HH:mm') }}
                    </td>

                    <td>
                      {{ healthResponse.themes.count }}
                    </td>

                    <td>
                      {{ healthResponse.themes.count_updates }}
                    </td>
                  </tr>

                  <tr
                      v-if="records.includes('themes')"
                      key="themesb"
                  >
                    <td
                        :colspan="4"
                    >
                      <h3
                          class="mt-4"
                      >
                        Zoznam
                      </h3>

                      <v-simple-table
                          class="my-4"
                      >
                        <template
                            v-slot:default
                        >
                          <thead>
                            <tr>
                              <th
                                  class="fixed"
                              >
                                Názov
                              </th>

                              <th>
                                Verzia
                              </th>

                              <th>
                                Aktualizácia
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <template
                                v-if="healthResponse.themes.rows.length > 0"
                            >
                              <tr
                                  v-for="Row in healthResponse.themes.rows"
                                  :key="Row.id"
                                  :class="
                                    Row.update.status
                                      ? 'orange lighten-4'
                                      : ''
                                  "
                              >
                                <td
                                    class="fixed"
                                >
                                  <span
                                      class="text-truncate"
                                      style="max-width: 300px"
                                  >
                                    {{ Row.name }}
                                  </span>
                                </td>

                                <td>
                                  {{ Row.version }}
                                </td>

                                <td>
                                  <template
                                      v-if="Row.update.status"
                                  >
                                    {{ Row.update.version }}

                                    <small
                                        v-if="Row.update.requires_wp || Row.update.requires_php"
                                    >
                                      <br/>

                                      <template
                                          v-if="Row.update.requires_wp"
                                      >
                                        <v-icon
                                            :small="true"
                                        >
                                          mdi-wordpress
                                        </v-icon>

                                        {{ Row.update.requires_wp}}
                                      </template>

                                      <template
                                          v-if="Row.update.requires_php"
                                      >
                                        <v-icon
                                            :small="true"
                                        >
                                          mdi-language-php
                                        </v-icon>

                                        {{ Row.update.requires_php}}
                                      </template>
                                    </small>
                                  </template>

                                  <template
                                      v-else
                                  >
                                    -
                                  </template>
                                </td>
                              </tr>
                            </template>

                            <template
                                v-else
                            >
                              <tr>
                                <td
                                    :colspan="3"
                                    class="text-center"
                                >
                                  Nenašli sa žiadne šablóny.
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </td>
                  </tr>

                  <tr
                      key="pluginsa"
                      @click="toggleRecord('plugins')"
                      :class="
                        healthResponse.plugins.count_updates > 0
                          ? 'orange lighten-4'
                          : ''
                      "
                  >
                    <td
                        class="fixed"
                    >
                      Pluginy
                    </td>

                    <td>
                      {{ new Date(healthResponse.plugins.last_checked)|dateFormat('DD.MM.YYYY HH:mm') }}
                    </td>

                    <td>
                      {{ healthResponse.plugins.count }}
                    </td>

                    <td>
                      {{ healthResponse.plugins.count_updates }}
                    </td>
                  </tr>

                  <tr
                      v-if="records.includes('plugins')"
                      key="pluginsb"
                  >
                    <td
                        :colspan="4"
                    >
                      <h3
                          class="mt-4"
                      >
                        Zoznam
                      </h3>

                      <v-simple-table
                          class="my-4"
                      >
                        <template
                            v-slot:default
                        >
                          <thead>
                            <tr>
                              <th
                                  class="fixed"
                              >
                                Názov
                              </th>

                              <th>
                                Verzia
                              </th>

                              <th>
                                Aktualizácia
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <template
                                v-if="healthResponse.plugins.rows.length > 0"
                            >
                              <tr
                                  v-for="Row in healthResponse.plugins.rows"
                                  :key="Row.id"
                                  :class="
                                    Row.update.status
                                      ? 'orange lighten-4'
                                      : ''
                                  "
                              >
                                <td
                                    class="fixed"
                                >
                                  <span
                                      class="text-truncate"
                                      style="max-width: 300px"
                                  >
                                    {{ Row.name }}
                                  </span>
                                </td>

                                <td>
                                  {{ Row.version }}
                                </td>

                                <td>
                                  <template
                                      v-if="Row.update.status"
                                  >
                                    {{ Row.update.version }}

                                    <small
                                        v-if="Row.update.requires_wp || Row.update.requires_php"
                                    >
                                      <br/>

                                      <template
                                          v-if="Row.update.requires_wp"
                                      >
                                        <v-icon
                                            :small="true"
                                        >
                                          mdi-wordpress
                                        </v-icon>

                                        {{ Row.update.requires_wp}}
                                      </template>

                                      <template
                                          v-if="Row.update.requires_php"
                                      >
                                        <v-icon
                                            :small="true"
                                        >
                                          mdi-language-php
                                        </v-icon>

                                        {{ Row.update.requires_php}}
                                      </template>
                                    </small>
                                  </template>

                                  <template
                                      v-else
                                  >
                                    -
                                  </template>
                                </td>
                              </tr>
                            </template>

                            <template
                                v-else
                            >
                              <tr>
                                <td
                                    :colspan="3"
                                    class="text-center"
                                >
                                  Nenašli sa žiadne pluginy.
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </td>
                  </tr>
                </template>
              </tbody>

              <tfoot>
                <tr>
                  <th>
                    Výsledok
                  </th>

                  <th
                      :colspan="3"
                  >
                    {{ $store.state.register.Project.healthResult[Project.healthResult].text }}
                  </th>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Projects from '@/services/projects'

  export default {
    name: 'Detail',
    props: [
      'id'
    ],
    data() {
      return {
        loading: false,
        dialog: false,
        Project: {},
        records: []
      }
    },
    computed: {
      healthResponse() {
        return JSON.parse(this.Project.healthResponse)
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      toggleRecord(record) {
        const index = this.records.indexOf(record)

        if (index === -1) {
          this.records.push(record)
        } else {
          this.records.splice(index, 1)
        }
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Projects
          .readOnlyHealth({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Project = response.data.data.Project
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.dialog = true

            this.loading = false
          })
      }
    }
  }
</script>
