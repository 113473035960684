<template>
  <v-card>
    <v-card-title>
      Stanovy

      <v-spacer/>

      <Create
          v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
          :icon="true"
          @init="init"
      />

      &nbsp;

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-progress-linear
          v-if="loading"
          :indeterminate="true"
      />

      <v-expansion-panels
          v-if="sortedStatutes.length > 0"
          v-model="expansionPanelsList"
          :multiple="true"
      >
        <v-expansion-panel
            v-for="(Statute) in sortedStatutes"
            :key="Statute.id"
        >
          <v-expansion-panel-header>
            <span>
              {{ Statute.title }}
              k
              {{ new Date(Statute.validFor)|dateFormat('DD.MM.YYYY') }}

              &nbsp;

              <Update
                  :icon="true"
                  :id="Statute.id"
                  :x-small="true"
                  @init="init"
              />

              &nbsp;

              <Delete
                  :icon="true"
                  :id="Statute.id"
                  :x-small="true"
                  @init="init"
              />
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <div
                v-if="Statute.about"
                v-html="Statute.about"
                class="ql-editor"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-alert
          v-else
          type="error"
      >
        Nenašli sa žiadne stanovy.
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
  import Statutes from '@/services/statutes'

  import Create from '@/components/Statutes/Create'
  import Update from '@/components/Statutes/Update'
  import Delete from '@/components/Statutes/Delete'

  export default {
    name: 'Statutes',
    components: {
      Create,
      Update,
      Delete
    },
    data() {
      return {
        loading: false,
        expansionPanelsList: [],
        statutes: []
      }
    },
    metaInfo() {
      return {
        title: 'Stanovy'
      }
    },
    mounted() {
      this.init()
    },
    computed: {
      sortedStatutes() {
        const sortedStatutes = [...this.statutes]

        sortedStatutes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

        return sortedStatutes
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      loadData() {
        this.loading = true

        Statutes
          .all()
          .then((response) => {
            this.statutes = response.data.data.statutes
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
