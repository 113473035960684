import Vue from 'vue'

class TicketReminds {
  async createTicket({ticketId, data}) {
    return await Vue.axios
      .post('admin/ticket-reminds-by-ticket/' + ticketId, data)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('admin/ticket-reminds/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('admin/ticket-reminds/' + id)
  }
}

const ticketReminds = new TicketReminds()

export default ticketReminds
