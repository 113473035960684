<template>
  <Tickets
      type="all"
      title="Všetky tikety"
  />
</template>

<script>
  import Tickets from '@/views/Tickets'

  export default {
    name: 'TicketsAll',
    components: {
      Tickets
    }
  }
</script>
