var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',_vm._g(_vm._b({attrs:{"outlined":true,"loading":_vm.loading,"disabled":_vm.loading,"color":"primary"},on:{"click":_vm.init}},'v-btn',_vm.$attrs,false),_vm.$listeners),[_c('v-icon',[_vm._v(" mdi-magnify ")]),_vm._t("default"),_c('v-dialog',{attrs:{"persistent":true,"scrollable":true,"max-width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.Project.name)+" "),(_vm.Project.healthStatusCode)?[_vm._v(" ("+_vm._s(_vm.Project.healthStatusCode)+") ")]:_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":true,"color":"default"},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],2),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(new Date(_vm.Project.healthSyncAt),'DD.MM.YYYY HH:mm'))+" ")]),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"fixed"},[_vm._v(" Kontroly ")]),_c('th',[_vm._v(" Dátum poslednej kontroly ")]),_c('th',[_vm._v(" Verzia ")]),_c('th',[_vm._v(" Aktualizácia ")])])]),_c('tbody',[(_vm.healthResponse)?[_c('tr',{key:"settingsa",class:_vm.healthResponse.settings.count_updates > 0
                        ? 'orange lighten-4'
                        : '',on:{"click":function($event){return _vm.toggleRecord('settings')}}},[_c('td',{staticClass:"fixed"},[_vm._v(" Nastavenia ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(new Date(_vm.healthResponse.settings.last_checked),'DD.MM.YYYY HH:mm'))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.healthResponse.settings.count)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.healthResponse.settings.count_updates)+" ")])]),(_vm.records.includes('settings'))?_c('tr',{key:"settingsb"},[_c('td',{attrs:{"colspan":4}},[_c('h3',{staticClass:"mt-4"},[_vm._v(" Zoznam ")]),_c('v-simple-table',{staticClass:"my-4",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"fixed"},[_vm._v(" Názov ")]),_c('th',[_vm._v(" Verzia ")]),_c('th',[_vm._v(" Aktualizácia ")])])]),_c('tbody',[(_vm.healthResponse.settings.rows.length > 0)?_vm._l((_vm.healthResponse.settings.rows),function(Row){return _c('tr',{key:Row.id,class:Row.update.status
                                      ? 'orange lighten-4'
                                      : ''},[_c('td',{staticClass:"fixed"},[_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"300px"}},[_vm._v(" "+_vm._s(Row.name)+" ")])]),_c('td',[(Row.value)?[_vm._v(" áno ")]:[_vm._v(" nie ")]],2),_c('td',[(Row.update.status)?[(Row.update.value)?[_vm._v(" áno ")]:[_vm._v(" nie ")]]:[_vm._v(" - ")]],2)])}):[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":3}},[_vm._v(" Nenašli sa žiadne nastavenia. ")])])]],2)]},proxy:true}],null,false,1076886574)})],1)]):_vm._e(),_c('tr',{key:"wordpressa",class:_vm.healthResponse.core.update.status
                        ? 'orange lighten-4'
                        : ''},[_c('td',{staticClass:"fixed"},[_vm._v(" Wordpress ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(new Date(_vm.healthResponse.core.last_checked),'DD.MM.YYYY HH:mm'))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.healthResponse.core.version)+" ")]),_c('td',[(_vm.healthResponse.core.update.status)?[_vm._v(" "+_vm._s(_vm.healthResponse.core.update.version)+" "),(_vm.healthResponse.core.update.requires_mysql || _vm.healthResponse.core.update.requires_php)?_c('small',[_c('br'),(_vm.healthResponse.core.update.requires_mysql)?[_c('v-icon',{attrs:{"small":true}},[_vm._v(" mdi-database-outline ")]),_vm._v(" "+_vm._s(_vm.healthResponse.core.update.requires_mysql)+" ")]:_vm._e(),(_vm.healthResponse.core.update.requires_php)?[_c('v-icon',{attrs:{"small":true}},[_vm._v(" mdi-language-php ")]),_vm._v(" "+_vm._s(_vm.healthResponse.core.update.requires_php)+" ")]:_vm._e()],2):_vm._e()]:[_vm._v(" - ")]],2)]),_c('tr',{key:"themesa",class:_vm.healthResponse.themes.count_updates > 0
                        ? 'orange lighten-4'
                        : '',on:{"click":function($event){return _vm.toggleRecord('themes')}}},[_c('td',{staticClass:"fixed"},[_vm._v(" Šablóny ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(new Date(_vm.healthResponse.themes.last_checked),'DD.MM.YYYY HH:mm'))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.healthResponse.themes.count)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.healthResponse.themes.count_updates)+" ")])]),(_vm.records.includes('themes'))?_c('tr',{key:"themesb"},[_c('td',{attrs:{"colspan":4}},[_c('h3',{staticClass:"mt-4"},[_vm._v(" Zoznam ")]),_c('v-simple-table',{staticClass:"my-4",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"fixed"},[_vm._v(" Názov ")]),_c('th',[_vm._v(" Verzia ")]),_c('th',[_vm._v(" Aktualizácia ")])])]),_c('tbody',[(_vm.healthResponse.themes.rows.length > 0)?_vm._l((_vm.healthResponse.themes.rows),function(Row){return _c('tr',{key:Row.id,class:Row.update.status
                                    ? 'orange lighten-4'
                                    : ''},[_c('td',{staticClass:"fixed"},[_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"300px"}},[_vm._v(" "+_vm._s(Row.name)+" ")])]),_c('td',[_vm._v(" "+_vm._s(Row.version)+" ")]),_c('td',[(Row.update.status)?[_vm._v(" "+_vm._s(Row.update.version)+" "),(Row.update.requires_wp || Row.update.requires_php)?_c('small',[_c('br'),(Row.update.requires_wp)?[_c('v-icon',{attrs:{"small":true}},[_vm._v(" mdi-wordpress ")]),_vm._v(" "+_vm._s(Row.update.requires_wp)+" ")]:_vm._e(),(Row.update.requires_php)?[_c('v-icon',{attrs:{"small":true}},[_vm._v(" mdi-language-php ")]),_vm._v(" "+_vm._s(Row.update.requires_php)+" ")]:_vm._e()],2):_vm._e()]:[_vm._v(" - ")]],2)])}):[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":3}},[_vm._v(" Nenašli sa žiadne šablóny. ")])])]],2)]},proxy:true}],null,false,1544631327)})],1)]):_vm._e(),_c('tr',{key:"pluginsa",class:_vm.healthResponse.plugins.count_updates > 0
                        ? 'orange lighten-4'
                        : '',on:{"click":function($event){return _vm.toggleRecord('plugins')}}},[_c('td',{staticClass:"fixed"},[_vm._v(" Pluginy ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(new Date(_vm.healthResponse.plugins.last_checked),'DD.MM.YYYY HH:mm'))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.healthResponse.plugins.count)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.healthResponse.plugins.count_updates)+" ")])]),(_vm.records.includes('plugins'))?_c('tr',{key:"pluginsb"},[_c('td',{attrs:{"colspan":4}},[_c('h3',{staticClass:"mt-4"},[_vm._v(" Zoznam ")]),_c('v-simple-table',{staticClass:"my-4",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"fixed"},[_vm._v(" Názov ")]),_c('th',[_vm._v(" Verzia ")]),_c('th',[_vm._v(" Aktualizácia ")])])]),_c('tbody',[(_vm.healthResponse.plugins.rows.length > 0)?_vm._l((_vm.healthResponse.plugins.rows),function(Row){return _c('tr',{key:Row.id,class:Row.update.status
                                    ? 'orange lighten-4'
                                    : ''},[_c('td',{staticClass:"fixed"},[_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"300px"}},[_vm._v(" "+_vm._s(Row.name)+" ")])]),_c('td',[_vm._v(" "+_vm._s(Row.version)+" ")]),_c('td',[(Row.update.status)?[_vm._v(" "+_vm._s(Row.update.version)+" "),(Row.update.requires_wp || Row.update.requires_php)?_c('small',[_c('br'),(Row.update.requires_wp)?[_c('v-icon',{attrs:{"small":true}},[_vm._v(" mdi-wordpress ")]),_vm._v(" "+_vm._s(Row.update.requires_wp)+" ")]:_vm._e(),(Row.update.requires_php)?[_c('v-icon',{attrs:{"small":true}},[_vm._v(" mdi-language-php ")]),_vm._v(" "+_vm._s(Row.update.requires_php)+" ")]:_vm._e()],2):_vm._e()]:[_vm._v(" - ")]],2)])}):[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":3}},[_vm._v(" Nenašli sa žiadne pluginy. ")])])]],2)]},proxy:true}],null,false,4028032293)})],1)]):_vm._e()]:_vm._e()],2),_c('tfoot',[_c('tr',[_c('th',[_vm._v(" Výsledok ")]),_c('th',{attrs:{"colspan":3}},[_vm._v(" "+_vm._s(_vm.$store.state.register.Project.healthResult[_vm.Project.healthResult].text)+" ")])])])]},proxy:true}])})],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }