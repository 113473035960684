<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="success"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-plus
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          Vytvoriť šablónu

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre vytvorenie šablóny je potrebné vyplniť všetky povinné polia.
          </p>

          <ImageFiles
              :outlined="true"
              :dense="true"
              label="Obrázok"
              :File="TemplateForm.File"
              :errorsFile="errors.File"
              @setFile="setFile"
          />

          <v-text-field
              v-model="TemplateForm.title"
              :error-messages="errors.title"
              :outlined="true"
              :dense="true"
              label="Názov"
          />

          <v-text-field
              v-model="TemplateForm.url"
              :error-messages="errors.url"
              :outlined="true"
              :dense="true"
              label="URL"
          />

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="success"
              @click="submit"
          >
            <v-icon>
              mdi-plus
            </v-icon>

            Vytvoriť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Templates from '@/services/templates'

  import ImageFiles from '@/components/Files/Image'

  export default {
    name: 'Create',
    components: {
      ImageFiles
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Template: {},
        TemplateForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = true

        this.TemplateForm = {
          File: null,
          title: '',
          url: ''
        }

        this.errors = {}
      },
      setFile(File) {
        this.TemplateForm.File = File
      },
      submit() {
        this.loading = true

        Templates
          .create({
            data: this.TemplateForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
