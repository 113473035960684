<template>
  <v-textarea
      v-if="TicketItemRemind.updatable"
      v-model="TicketItemRemindForm.content"
      :error-messages="errors.content"
      :outlined="true"
      :dense="true"
      :label="new Date(TicketItemRemind.createdAt)|dateFormat('DD.MM.YYYY')"
      :rows="1"
      @change="submit"
  >
    <template
        v-slot:prepend
    >
      <v-checkbox
          v-model="TicketItemRemindForm.strikeOut"
          :error-messages="errors.strikeOut"
          class="ma-0 pa-0"
          @change="submit"
      />
    </template>

    <template
        v-slot:append-outer
    >
      <v-btn
          v-if="TicketItemRemind.archivable"
          :small="true"
          :icon="true"
          :outlined="true"
          color="secondary"
          @click="makeArchive"
      >
        <v-icon>
          mdi-archive
        </v-icon>
      </v-btn>

      <v-btn
          v-if="TicketItemRemind.deletable"
          :small="true"
          :icon="true"
          :outlined="true"
          color="error"
          @click="deleteTicketItemRemind"
      >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>
  </v-textarea>

  <p
      v-else
      :class="
        TicketItemRemind.strikeOut
          ? 'text-decoration-line-through'
          : ''
      "
  >
    {{ new Date(TicketItemRemind.createdAt)|dateFormat('DD.MM.YYYY') }} - {{ TicketItemRemind.content }}
  </p>
</template>

<script>
  import TicketItemReminds from '@/services/ticket-item-reminds'

  export default {
    name: 'TicketItemRemind',
    props: [
      'TicketItemRemind'
    ],
    data() {
      return {
        TicketItemRemindForm: {},
        errors: {}
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.loadData()
      },
      deleteTicketItemRemind() {
        TicketItemReminds
          .delete({
            id: this.TicketItemRemind.id
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.$emit('refresh')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 403:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/tickets')

                break
            }
          })
      },
      loadData() {
        if (this.TicketItemRemind.updatable) {
          this.TicketItemRemindForm = {
            content: this.TicketItemRemind.content,
            strikeOut: this.TicketItemRemind.strikeOut,
            archive: this.TicketItemRemind.archive
          }
        }

        this.errors = {}
      },
      makeArchive() {
        this.TicketItemRemindForm.archive = true

        this.submit()
      },
      submit() {
        TicketItemReminds
          .update({
            id: this.TicketItemRemind.id,
            data: this.TicketItemRemindForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.$emit('refresh')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 403:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/tickets')

                break
            }
          })
      }
    }
  }
</script>
