<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="success"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-plus
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="1000"
    >
      <v-card>
        <v-card-title>
          Vytvoriť klienta

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre vytvorenie klienta je potrebné vyplniť všetky povinné polia.
          </p>

          <v-select
              v-model="ClientForm.status"
              :error-messages="errors.status"
              :outlined="true"
              :dense="true"
              label="Stav"
              :items="$store.getters['register/getClientStatus']"
          />

          <v-checkbox
              v-model="ClientForm.priority"
              :error-messages="errors.priority"
              class="ma-0 pa-0"
              label="Priorita"
          />

          <ImageFiles
              :outlined="true"
              :dense="true"
              label="Obrázok"
              :File="ClientForm.File"
              :errorsFile="errors.File"
              @setFile="setFile"
          />

          <v-text-field
              v-model="ClientForm.name"
              :error-messages="errors.name"
              :outlined="true"
              :dense="true"
              label="Meno"
          />

          <v-text-field
              v-model="ClientForm.email"
              :error-messages="errors.email"
              :outlined="true"
              :dense="true"
              label="E-mail"
          />

          <v-text-field
              v-model="ClientForm.phone"
              :error-messages="errors.phone"
              :outlined="true"
              :dense="true"
              label="Telefón"
              :persistent-hint="true"
              hint="V prípade, že nevieš tak: xxxxxxxxxxxx"
          />

          <v-checkbox
              v-model="ClientForm.billingOnCompany"
              :error-messages="errors.keyOffice"
              class="ma-0 pa-0"
              label="Fakturovať na firmu"
          />

          <v-text-field
              v-model="ClientForm.billingName"
              :error-messages="errors.billingName"
              :outlined="true"
              :dense="true"
              label="Spoločnosť"
          />

          <template
              v-if="ClientForm.billingOnCompany"
          >
            <v-text-field
                v-model="ClientForm.billingBusinessId"
                :error-messages="errors.billingBusinessId"
                :outlined="true"
                :dense="true"
                label="IČO"
            />

            <v-text-field
                v-model="ClientForm.billingTaxId"
                :error-messages="errors.billingTaxId"
                :outlined="true"
                :dense="true"
                label="DIČ"
            />

            <v-text-field
                v-model="ClientForm.billingVatId"
                :error-messages="errors.billingVatId"
                :outlined="true"
                :dense="true"
                label="IČ DPH"
            />
          </template>

          <v-text-field
              v-model="ClientForm.billingStreet"
              :error-messages="errors.billingStreet"
              :outlined="true"
              :dense="true"
              label="Ulica"
              :persistent-hint="true"
              hint="V prípade, že nevieš tak: -"
          />

          <v-text-field
              v-model="ClientForm.billingCity"
              :error-messages="errors.billingCity"
              :outlined="true"
              :dense="true"
              label="Mesto"
              :persistent-hint="true"
              hint="V prípade, že nevieš tak: -"
          />

          <v-text-field
              v-model="ClientForm.billingZip"
              :error-messages="errors.billingZip"
              :outlined="true"
              :dense="true"
              label="PSČ"
              :persistent-hint="true"
              hint="V prípade, že nevieš tak: -"
          />

          <v-select
              v-model="ClientForm.billingCountry"
              :error-messages="errors.billingCountry"
              :outlined="true"
              :dense="true"
              label="Krajina"
              :items="$store.getters['register/getClientBillingCountry']"
          />

          <v-text-field
              v-model="ClientForm.billingSign"
              :error-messages="errors.billingSign"
              :outlined="true"
              :dense="true"
              label="Zastúpenie"
              :persistent-hint="true"
              hint="V prípade, že viac tak: ,"
          />

          <EditorPartials
              v-model="ClientForm.about"
              label="..."
          />

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="success"
              @click="submit"
          >
            <v-icon>
              mdi-plus
            </v-icon>

            Vytvoriť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Clients from '@/services/clients'

  import ImageFiles from '@/components/Files/Image'

  import EditorPartials from '@/components/_Partials/Editor'

  export default {
    name: 'Create',
    components: {
      ImageFiles,
      EditorPartials
    },
    data() {
      return {
        loading: false,
        dialog: false,
        ClientForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = true

        this.ClientForm = {
          status: this.$store.state.register.Client.status['hide'].value,
          priority: 0,
          File: null,
          name: '',
          email: '',
          phone: '',
          billingOnCompany: false,
          billingName: '',
          billingBusinessId: '',
          billingTaxId: '',
          billingVatId: '',
          billingStreet: '',
          billingCity: '',
          billingZip: '',
          billingCountry: this.$store.state.register.Client.billingCountry['sk'].value,
          billingSign: '',
          about: ''
        }

        this.errors = {}
      },
      setFile(File) {
        this.ClientForm.File = File
      },
      submit() {
        this.loading = true

        Clients
          .create({
            data: this.ClientForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
