import Vue from 'vue'

import Qs from 'qs'

class Users {
  async all() {
    return await Vue.axios
      .get('admin/users/all')
  }

  async suggest() {
    return await Vue.axios
      .get('admin/users/suggest')
  }

  async index({params}) {
    return await Vue.axios
      .get('admin/users', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('admin/users', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('admin/users/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('admin/users/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('admin/users/' + id)
  }
}

const users = new Users()

export default users
