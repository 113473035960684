<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="warning"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-pencil
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="1000"
    >
      <v-card>
        <v-card-title>
          Upraviť stanovy

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre upravenie stanov je potrebné vyplniť všetky povinné polia.
          </p>

          <v-text-field
              v-model="StatuteForm.title"
              :error-messages="errors.title"
              :outlined="true"
              :dense="true"
              label="Názov"
          />

          <EditorPartials
              v-model="StatuteForm.about"
              label="..."
          />

          <v-text-field
              v-model="StatuteForm.validFor"
              :error-messages="errors.validFor"
              :outlined="true"
              :dense="true"
              label="Dátum platnosti"
          />

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="warning"
              @click="submit"
          >
            <v-icon>
              mdi-pencil
            </v-icon>

            Upraviť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import { dateFormat } from '@vuejs-community/vue-filter-date-format'

  import Statutes from '@/services/statutes'

  import EditorPartials from '@/components/_Partials/Editor'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    components: {
      EditorPartials
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Statute: {},
        StatuteForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Statutes
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Statute - response.data.data.Statute

            this.StatuteForm = {
              title: response.data.data.Statute.title,
              about: response.data.data.Statute.about,
              validFor: dateFormat(new Date(response.data.data.Statute.validFor), 'DD.MM.YYYY')
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.dialog = true

            this.loading = false
          })
      },
      submit() {
        this.loading = true

        Statutes
          .update({
            id: this.id,
            data: this.StatuteForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
