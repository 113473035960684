<template>
  <v-row>
    <v-col
        cols="12"
    >
      <template
          v-if="$store.state.auth.isGranted"
      >
        <v-row>
          <v-col
              cols="12"
          >
            <Users/>
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
          >
            <TicketItemsAuth/>
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
          >
            <UserStatisticsAuth/>
          </v-col>
        </v-row>
      </template>

      <template
          v-else
      >
        ...
      </template>
    </v-col>
  </v-row>
</template>

<script>
  import Users from '@/components/Home/Users'
  import TicketItemsAuth from '@/components/Home/TicketItemsAuth'
  import UserStatisticsAuth from '@/components/Home/UserStatisticsAuth'

  export default {
    name: 'Home',
    components: {
      Users,
      TicketItemsAuth,
      UserStatisticsAuth
    },
    metaInfo() {
      return {
        title: 'Domov'
      }
    }
  }
</script>
