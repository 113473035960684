<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="success"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-plus
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="1000"
    >
      <v-card>
        <v-card-title>
          Vytvoriť užívateľa

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre vytvorenie užívateľa je potrebné vyplniť všetky povinné polia.
          </p>

          <v-select
              v-model="UserForm.status"
              :error-messages="errors.status"
              :outlined="true"
              :dense="true"
              label="Stav"
              :items="$store.getters['register/getUserStatus']"
          />

          <v-checkbox
              v-model="UserForm.priority"
              :error-messages="errors.priority"
              class="ma-0 pa-0"
              label="Priorita"
          />

          <ImageFiles
              :outlined="true"
              :dense="true"
              label="Obrázok - normálny"
              :File="UserForm.FileNormal"
              :errorsFile="errors.FileNormal"
              @setFile="setFileNormal"
          />

          <ImageFiles
              :outlined="true"
              :dense="true"
              label="Obrázok - zábavný"
              :File="UserForm.FileFunny"
              :errorsFile="errors.FileFunny"
              @setFile="setFileFunny"
          />

          <v-text-field
              v-model="UserForm.name"
              :error-messages="errors.name"
              :outlined="true"
              :dense="true"
              label="Meno"
          />

          <v-text-field
              v-model="UserForm.username"
              :error-messages="errors.username"
              :outlined="true"
              :dense="true"
              label="Užívateľské meno"
          />

          <v-text-field
              v-model="UserForm.emailBusiness"
              :error-messages="errors.emailBusiness"
              :outlined="true"
              :dense="true"
              label="E-mail firemný"
          />

          <v-text-field
              v-model="UserForm.emailPersonal"
              :error-messages="errors.emailPersonal"
              :outlined="true"
              :dense="true"
              label="E-mail osobný"
          />

          <v-text-field
              v-model="UserForm.phone"
              :error-messages="errors.phone"
              :outlined="true"
              :dense="true"
              label="Telefón"
              :persistent-hint="true"
              hint="V prípade, že nevieš tak: xxxxxxxxxxxx"
          />

          <v-select
              v-model="UserForm.type"
              :error-messages="errors.type"
              :outlined="true"
              :dense="true"
              label="Typ"
              :items="$store.getters['register/getUserType']"
          />

          <v-select
              v-model="UserForm.division"
              :error-messages="errors.division"
              :outlined="true"
              :dense="true"
              label="Odvetvie"
              :items="$store.getters['register/getUserDivision']"
          />

          <v-text-field
              v-model="UserForm.position"
              :error-messages="errors.position"
              :outlined="true"
              :dense="true"
              label="Pozícia"
          />

          <v-text-field
              v-model="UserForm.priceHour"
              :error-messages="errors.priceHour"
              :outlined="true"
              :dense="true"
              label="Plat (€ / h)"
          />

          <v-text-field
              v-model="UserForm.minPriceHour"
              :error-messages="errors.minPriceHour"
              :outlined="true"
              :dense="true"
              label="Minimálny plat (€ / h)"
          />

          <v-checkbox
              v-model="UserForm.keyOffice"
              :error-messages="errors.keyOffice"
              class="ma-0 pa-0"
              label="Kľúč kancelária"
          />

          <v-checkbox
              v-model="UserForm.keyBuilding"
              :error-messages="errors.keyBuilding"
              class="ma-0 pa-0"
              label="Kľúč budova"
          />

          <EditorPartials
              v-model="UserForm.about"
              label="..."
          />

          <v-textarea
              v-model="UserForm.description"
              :error-messages="errors.description"
              :outlined="true"
              :dense="true"
              label="Popis"
              :rows="2"
          />

          <v-textarea
              v-model="UserForm.keywords"
              :error-messages="errors.keywords"
              :outlined="true"
              :dense="true"
              label="Kľúčové slová"
              :rows="2"
              :persistent-hint="true"
              hint="V prípade, že viac tak: ,"
          />

          <!-- TODO -->
          <SuggestUsers
              v-model="UserForm.owners"
              :error-messages="errors.owners"
              :outlined="true"
              :dense="true"
              label="Prístupy"
              :clearable="true"
              :multiple="true"
              :createButton="true"
          />

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="success"
              @click="submit"
          >
            <v-icon>
              mdi-plus
            </v-icon>

            Vytvoriť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Users from '@/services/users'

  import ImageFiles from '@/components/Files/Image'

  import SuggestUsers from '@/components/Users/Suggest'

  import EditorPartials from '@/components/_Partials/Editor'

  export default {
    name: 'Create',
    components: {
      ImageFiles,
      SuggestUsers,
      EditorPartials
    },
    data() {
      return {
        loading: false,
        dialog: false,
        UserForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = true

        this.UserForm = {
          status: this.$store.state.register.User.status['hide'].value,
          priority: 0,
          FileNormal: null,
          FileFunny: null,
          name: '',
          username: '',
          emailBusiness: '',
          emailPersonal: '',
          phone: '',
          type: this.$store.state.register.User.type['worker'].value,
          division: this.$store.state.register.User.division['creative'].value,
          position: '',
          priceHour: 0,
          minPriceHour: 0,
          keyOffice: false,
          keyBuilding: false,
          about: '',
          description: '',
          keywords: '',
          owners: []
        }

        this.errors = {}
      },
      setFileNormal(FileNormal) {
        this.UserForm.FileNormal = FileNormal
      },
      setFileFunny(FileFunny) {
        this.UserForm.FileFunny = FileFunny
      },
      submit() {
        this.loading = true

        Users
          .create({
            data: this.UserForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
