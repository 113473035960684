import Vue from 'vue'

import Qs from 'qs'

class Accounts {
  async suggest() {
    return await Vue.axios
      .get('admin/accounts/suggest')
  }

  async index({params}) {
    return await Vue.axios
      .get('admin/accounts', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('admin/accounts', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('admin/accounts/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('admin/accounts/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('admin/accounts/' + id)
  }
}

const accounts = new Accounts()

export default accounts
