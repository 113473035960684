<template>
  <tr>
    <td
        class="fixed"
    >
      {{ new Date(TicketBonus.Bonus.workedAt)|dateFormat('DD.MM.YYYY') }}
    </td>

    <td>
      <span
          class="text-truncate"
          style="max-width: 200px"
      >
        {{ TicketBonus.Bonus.currentUserName }}
      </span>
    </td>

    <td>
      <span
          class="text-truncate"
          style="max-width: 300px"
      >
        {{ TicketBonus.Bonus.title }}
      </span>
    </td>

    <td
        class="purple lighten-3"
    >
      {{ TicketBonus.pricePart }}
    </td>

    <td
        class="text-right fixed"
    >
      <v-btn
          v-if="TicketBonus.deletable"
          :icon="true"
          :outlined="true"
          color="error"
          @click="deleteTicketBonus"
      >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>

      &nbsp;

      <TicketsBonus
          :icon="true"
          :id="TicketBonus.Bonus.id"
      />
    </td>
  </tr>
</template>

<script>
  import TicketBonuses from '@/services/ticket-bonuses'

  import TicketsBonus from '@/components/Bonuses/Tickets'

  export default {
    name: 'TicketBonus',
    props: [
      'TicketBonus'
    ],
    components: {
      TicketsBonus
    },
    methods: {
      deleteTicketBonus() {
        TicketBonuses
          .delete({
            id: this.TicketBonus.id
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.$emit('refresh')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 403:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/tickets')

                break
            }
          })
      }
    }
  }
</script>
