<template>
  <tr>
    <td
        class="fixed"
    >
      {{ new Date(TicketRevenue.Revenue.workedAt)|dateFormat('DD.MM.YYYY') }}
    </td>

    <td>
      <span
          class="text-truncate"
          style="max-width: 300px"
      >
        {{ TicketRevenue.Revenue.title }}
      </span>
    </td>

    <td
        class="green lighten-3"
    >
      {{ TicketRevenue.pricePart }}
    </td>

    <td
        class="text-right fixed"
    >
      <v-btn
          v-if="TicketRevenue.deletable"
          :icon="true"
          :outlined="true"
          color="error"
          @click="deleteTicketRevenue"
      >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>

      &nbsp;

      <TicketsRevenue
          :icon="true"
          :id="TicketRevenue.Revenue.id"
      />
    </td>
  </tr>
</template>

<script>
  import TicketRevenues from '@/services/ticket-revenues'

  import TicketsRevenue from '@/components/Revenues/Tickets'

  export default {
    name: 'TicketRevenue',
    props: [
      'TicketRevenue'
    ],
    components: {
      TicketsRevenue
    },
    methods: {
      deleteTicketRevenue() {
        TicketRevenues
          .delete({
            id: this.TicketRevenue.id
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.$emit('refresh')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 403:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/tickets')

                break
            }
          })
      }
    }
  }
</script>
