<template>
  <div>
    <label>
      {{ label }}
    </label>

    <vue-editor
        class="editor"
        v-bind="$attrs"
        v-on="$listeners"
        :customModules="customModules"
        :editorOptions="editorOptions"
        :useCustomImageHandler="true"
        @image-added="readData"
    />
  </div>
</template>

<script>
  import { VueEditor } from 'vue2-editor'

  import ImageResize from 'quill-image-resize-vue'

  import Files from '@/services/files'

  export default {
    name: 'Editor',
    props: [
      'label'
    ],
    components: {
      VueEditor
    },
    data() {
      return {
        loading: false,
        errors: {},
        customModules: [
          {
            alias: 'imageResize',
            module: ImageResize
          }
        ],
        editorOptions: {
          modules: {
            imageResize: {}
          }
        }
      }
    },
    methods: {
      readData(image, Quill, position) {
        if (image) {
          const fileReader = new FileReader()

          fileReader.onload = (event) => {
            const ImageForm = {
              name: image.name,
              size: image.size,
              type: image.type,
              content: event.target.result
            }

            this.uploadImage(ImageForm, Quill, position)
          }

          fileReader.readAsDataURL(image)
        }
      },
      uploadImage(ImageForm, Quill, position) {
        this.loading = true

        Files
          .uploadImage({
            data: ImageForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.errors = {}

            Quill.insertEmbed(position, 'image', this.loadLinkByPath('web/files/generate/original/' + response.data.data.token))
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      errorMessages() {
        let errorMessages = []

        if (this.errors.name) {
          errorMessages = errorMessages.concat(this.errors.name)
        }

        if (this.errors.size) {
          errorMessages = errorMessages.concat(this.errors.size)
        }

        if (this.errors.type) {
          errorMessages = errorMessages.concat(this.errors.type)
        }

        if (this.errors.content) {
          errorMessages = errorMessages.concat(this.errors.content)
        }

        return errorMessages
      },
      loadLinkByPath(path) {
        return process.env.VUE_APP_AXIOS_URL + path
      }
    }
  }
</script>
