<template>
  <v-btn
      :icon="true"
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="primary"
      @click="init"
  >
    <v-icon>
      mdi-logout
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          Odhlásiť sa z účtu

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre odhlásenie sa z účtu je potrebné potvrdiť odhlásenie.
          </p>

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="primary"
              @click="submit"
          >
            <v-icon>
              mdi-logout
            </v-icon>

            Odhlásiť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Auth from '@/services/auth'

  export default {
    name: 'Logout',
    data() {
      return {
        loading: false,
        dialog: false
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = true
      },
      submit() {
        this.loading = true

        Auth
          .logout()
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            window.location.reload()
          })
          .catch((error) => {
            switch (error.response.status) {
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
