import Vue from 'vue'

class UserStatistics {
  async allReportedAtYearAuth({reportedAtYear}) {
    return await Vue.axios
      .get('admin/user-statistics/all-by-reported-at-year-and-auth/' + reportedAtYear)
  }

  async allUserReportedAtYear({userId, reportedAtYear}) {
    return await Vue.axios
      .get('admin/user-statistics/all-by-user-and-reported-at-year/' + userId + '/' + reportedAtYear)
  }
}

const userStatistics = new UserStatistics()

export default userStatistics
