<template>
  <v-btn
      :icon="true"
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="warning"
      @click="init"
  >
    <v-icon>
      mdi-pencil
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          Upraviť províziu

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre upravenie provízie je potrebné vyplniť všetky povinné polia.
          </p>

          <v-text-field
              v-model="ProvisionForm.reportedAt"
              :error-messages="errors.reportedAt"
              :outlined="true"
              :dense="true"
              label="Dátum uzávierky"
          />

          <v-select
              v-model="ProvisionForm.status"
              :error-messages="errors.status"
              :outlined="true"
              :dense="true"
              label="Stav"
              :items="$store.getters['register/getProvisionStatus']"
          />

          <v-select
              v-model="ProvisionForm.pay"
              :error-messages="errors.pay"
              :outlined="true"
              :dense="true"
              label="Platba"
              :items="$store.getters['register/getProvisionPay']"
          />

          <SuggestAccounts
              v-if="ProvisionForm.pay !== $store.state.register.Provision.pay['in-queue'].value"
              v-model="ProvisionForm.Account"
              :error-messages="errors.Account"
              :outlined="true"
              :dense="true"
              label="Účet"
              :clearable="true"
              :createButton="true"
          />

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="warning"
              @click="submit"
          >
            <v-icon>
              mdi-pencil
            </v-icon>

            Upraviť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import { dateFormat } from '@vuejs-community/vue-filter-date-format'

  import Provisions from '@/services/provisions'

  import SuggestAccounts from '@/components/Accounts/Suggest'

  export default {
    name: 'ProvisionUpdateStatistic',
    props: [
      'id'
    ],
    components: {
      SuggestAccounts
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Provision: {},
        ProvisionForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Provisions
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Provision = response.data.data.Provision

            this.ProvisionForm = {
              Account: (
                response.data.data.Provision.Account
                  ? response.data.data.Provision.Account.id
                  : ''
              ),
              reportedAt: (
                response.data.data.Provision.reportedAt
                  ? dateFormat(new Date(response.data.data.Provision.reportedAt), 'DD.MM.YYYY')
                  : ''
              ),
              status: response.data.data.Provision.status,
              pay: response.data.data.Provision.pay
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.dialog = true

            this.loading = false
          })
      },
      submit() {
        this.loading = true

        Provisions
          .updateStatistic({
            id: this.id,
            data: this.ProvisionForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('refresh')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
