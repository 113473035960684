<template>
  <v-card>
    <v-card-title>
      Výplaty

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-progress-linear
              v-if="loading"
              :indeterminate="true"
          />

          <v-row>
            <v-col
                cols="12"
                lg="10"
            />

            <v-col
                cols="12"
                lg="2"
            >
              <v-text-field
                  v-model="reportedAtYear"
                  :outlined="true"
                  :dense="true"
                  label="Rok uzávierky"
                  @change="setReportedAtYear"
              />
            </v-col>
          </v-row>

          <v-card
              v-if="reportedAtYear"
          >
            <v-card-text>
              <v-simple-table>
                <template
                    v-slot:default
                >
                  <thead>
                    <tr>
                      <th
                          class="fixed"
                      />

                      <th
                          :colspan="2"
                      >
                        Vykonané práce (€)
                      </th>

                      <th
                          :colspan="2"
                      >
                        Bonusy (€)
                      </th>

                      <th
                          :colspan="2"
                      >
                        Provízie (€)
                      </th>

                      <th
                          :colspan="2"
                      >
                        Celkovo (€)
                      </th>
                    </tr>

                    <tr>
                      <th
                          class="fixed"
                      >
                        Mesiac uzávierky
                      </th>

                      <th
                          class="grey lighten-4"
                      >
                        <small>Celkovo</small>
                      </th>

                      <th>
                        <small>Kumulatívne</small>
                      </th>

                      <th
                          class="grey lighten-4"
                      >
                        <small>Celkovo</small>
                      </th>

                      <th>
                        <small>Kumulatívne</small>
                      </th>

                      <th
                          class="grey lighten-4"
                      >
                        <small>Celkovo</small>
                      </th>

                      <th>
                        <small>Kumulatívne</small>
                      </th>

                      <th
                          class="grey lighten-4"
                      >
                        <small>Celkovo</small>
                      </th>

                      <th>
                        <small>Kumulatívne</small>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <template
                        v-if="userStatistics.length > 0"
                    >
                      <template
                          v-for="UserStatistic in userStatistics"
                      >
                        <tr
                            :key="UserStatistic.id + 'a'"
                            @click="toggleRecord(UserStatistic.id)"
                        >
                          <td
                              class="fixed"
                          >
                            <template
                                v-if="UserStatistic.reportedAtMonth"
                            >
                              {{ UserStatistic.reportedAtMonth }}
                            </template>

                            <template
                                v-else
                            >
                              Nevyplnené
                            </template>
                          </td>

                          <td
                              class="grey lighten-4"
                          >
                            <b>{{ UserStatistic.priceSpend }}</b>
                          </td>

                          <td>
                            <b>{{ UserStatistic.priceSpendCumulative }}</b>
                          </td>

                          <td
                              class="grey lighten-4"
                          >
                            <b>{{ UserStatistic.priceBonus }}</b>
                          </td>

                          <td>
                            <b>{{ UserStatistic.priceBonusCumulative }}</b>
                          </td>

                          <td
                              class="grey lighten-4"
                          >
                            <b>{{ UserStatistic.priceProvision }}</b>
                          </td>

                          <td>
                            <b>{{ UserStatistic.priceProvisionCumulative }}</b>
                          </td>

                          <td
                              class="grey lighten-4"
                          >
                            <b>{{ UserStatistic.priceTotal }}</b>
                          </td>

                          <td>
                            <b>{{ UserStatistic.priceTotalCumulative }}</b>
                          </td>
                        </tr>

                        <tr
                            v-if="records.includes(UserStatistic.id)"
                            :key="UserStatistic.id + 'b'"
                        >
                          <td
                              :colspan="9"
                          >
                            <h3
                                class="mt-4"
                            >
                              Vykonané práce
                            </h3>

                            <v-simple-table
                                class="my-4"
                            >
                              <template
                                  v-slot:default
                              >
                                <thead>
                                  <tr>
                                    <th
                                        class="fixed"
                                    >
                                      Dátum vykonania
                                    </th>

                                    <th>
                                      Projekt
                                    </th>

                                    <th>
                                      Čas (h)
                                    </th>

                                    <th>
                                      Plat (€ / h)
                                    </th>

                                    <th
                                        class="grey lighten-4"
                                    >
                                      Celkovo (€)
                                    </th>

                                    <th>
                                      Stav
                                    </th>

                                    <th>
                                      Platba
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <template
                                      v-if="UserStatistic.userStatisticReports.length > 0"
                                  >
                                    <tr
                                        v-for="UserStatisticReport in UserStatistic.userStatisticReports"
                                        :key="UserStatisticReport.id"
                                        :class="$store.state.register.TicketItem.status[UserStatisticReport.statusItem].class"
                                    >
                                      <td
                                          class="fixed"
                                      >
                                        {{ new Date(UserStatisticReport.workedAt)|dateFormat('DD.MM.YYYY') }}
                                      </td>

                                      <td>
                                        <span
                                            class="text-truncate"
                                            style="max-width: 200px"
                                        >
                                          <template
                                              v-if="UserStatisticReport.projectName"
                                          >
                                            {{ UserStatisticReport.projectName }}
                                          </template>

                                          <template
                                              v-else
                                          >
                                            Nevyplnené
                                          </template>
                                        </span>
                                      </td>

                                      <td>
                                        {{ UserStatisticReport.timeSpend }}
                                      </td>

                                      <td>
                                        {{ UserStatisticReport.priceHour }}
                                      </td>

                                      <td
                                          class="grey lighten-4"
                                      >
                                        <b>{{ UserStatisticReport.priceSpend }}</b>
                                      </td>

                                      <td>
                                        {{ $store.state.register.TicketItemReport.status[UserStatisticReport.status].text }}
                                      </td>

                                      <td>
                                        {{ $store.state.register.TicketItemReport.pay[UserStatisticReport.pay].text }}
                                      </td>
                                    </tr>
                                  </template>

                                  <template
                                      v-else
                                  >
                                    <tr>
                                      <td
                                          :colspan="7"
                                          class="text-center"
                                      >
                                        Nenašli sa žiadne vykonané práce.
                                      </td>
                                    </tr>
                                  </template>
                                </tbody>
                              </template>
                            </v-simple-table>

                            <h3
                                class="mt-4"
                            >
                              Bonusy
                            </h3>

                            <v-simple-table
                                class="my-4"
                            >
                              <template
                                  v-slot:default
                              >
                                <thead>
                                  <tr>
                                    <th
                                        class="fixed"
                                    >
                                      Dátum vykonania
                                    </th>

                                    <th>
                                      Názov
                                    </th>

                                    <th
                                        class="grey lighten-4"
                                    >
                                      Suma (€)
                                    </th>

                                    <th>
                                      Stav
                                    </th>

                                    <th>
                                      Platba
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <template
                                      v-if="UserStatistic.userStatisticBonuses.length > 0"
                                  >
                                    <tr
                                        v-for="UserStatisticBonus in UserStatistic.userStatisticBonuses"
                                        :key="UserStatisticBonus.id"
                                    >
                                      <td
                                          class="fixed"
                                      >
                                        {{ new Date(UserStatisticBonus.workedAt)|dateFormat('DD.MM.YYYY') }}
                                      </td>

                                      <td>
                                        {{ UserStatisticBonus.title }}
                                      </td>

                                      <td
                                          class="grey lighten-4"
                                      >
                                        <b>{{ UserStatisticBonus.priceBonus }}</b>
                                      </td>

                                      <td>
                                        {{ $store.state.register.Bonus.status[UserStatisticBonus.status].text }}
                                      </td>

                                      <td>
                                        {{ $store.state.register.Bonus.pay[UserStatisticBonus.pay].text }}
                                      </td>
                                    </tr>
                                  </template>

                                  <template
                                      v-else
                                  >
                                    <tr>
                                      <td
                                          :colspan="5"
                                          class="text-center"
                                      >
                                        Nenašli sa žiadne bonusy.
                                      </td>
                                    </tr>
                                  </template>
                                </tbody>
                              </template>
                            </v-simple-table>

                            <h3
                                class="mt-4"
                            >
                              Provízie
                            </h3>

                            <v-simple-table
                                class="my-4"
                            >
                              <template
                                  v-slot:default
                              >
                                <thead>
                                <tr>
                                  <th
                                      class="fixed"
                                  >
                                    Dátum vykonania
                                  </th>

                                  <th>
                                    Názov
                                  </th>

                                  <th
                                      class="grey lighten-4"
                                  >
                                    Suma (€)
                                  </th>

                                  <th>
                                    Stav
                                  </th>

                                  <th>
                                    Platba
                                  </th>
                                </tr>
                                </thead>

                                <tbody>
                                <template
                                    v-if="UserStatistic.userStatisticProvisions.length > 0"
                                >
                                  <tr
                                      v-for="UserStatisticProvision in UserStatistic.userStatisticProvisions"
                                      :key="UserStatisticProvision.id"
                                  >
                                    <td
                                        class="fixed"
                                    >
                                      {{ new Date(UserStatisticProvision.workedAt)|dateFormat('DD.MM.YYYY') }}
                                    </td>

                                    <td>
                                      {{ UserStatisticProvision.title }}
                                    </td>

                                    <td
                                        class="grey lighten-4"
                                    >
                                      <b>{{ UserStatisticProvision.priceProvision }}</b>
                                    </td>

                                    <td>
                                      {{ $store.state.register.Provision.status[UserStatisticProvision.status].text }}
                                    </td>

                                    <td>
                                      {{ $store.state.register.Provision.pay[UserStatisticProvision.pay].text }}
                                    </td>
                                  </tr>
                                </template>

                                <template
                                    v-else
                                >
                                  <tr>
                                    <td
                                        :colspan="5"
                                        class="text-center"
                                    >
                                      Nenašli sa žiadne provízie.
                                    </td>
                                  </tr>
                                </template>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </td>
                        </tr>
                      </template>
                    </template>

                    <template
                        v-else
                    >
                      <tr>
                        <td
                            :colspan="9"
                            class="text-center"
                        >
                          Nenašli sa žiadne výplaty.
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { dateFormat } from '@vuejs-community/vue-filter-date-format'

  import UserStatistics from '@/services/user-statistics'

  export default {
    name: 'UserStatistics',
    data() {
      return {
        loading: false,
        reportedAtYear: dateFormat(new Date(), 'YYYY'),
        userStatistics: [],
        records: []
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.loadData()
      },
      setReportedAtYear() {
        this.init()
      },
      toggleRecord(record) {
        const index = this.records.indexOf(record)

        if (index === -1) {
          this.records.push(record)
        } else {
          this.records.splice(index, 1)
        }
      },
      loadData() {
        if (this.reportedAtYear) {
          this.loading = true

          UserStatistics
            .allReportedAtYearAuth({
              reportedAtYear: this.reportedAtYear
            })
            .then((response) => {
              this.userStatistics = response.data.data.userStatistics
            })
            .catch((error) => {
              switch (error.response.status) {
                case 401:
                case 500:
                  this.$store.dispatch('layout/openSnackbar', {
                    message: error.response.data.message
                  })

                  break
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
