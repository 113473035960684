<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="warning"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-pencil
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          Upraviť účet

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre upravenie účtu je potrebné vyplniť všetky povinné polia.
          </p>

          <v-checkbox
              v-model="AccountForm.billingOnCompany"
              :error-messages="errors.keyOffice"
              class="ma-0 pa-0"
              label="Fakturovať na firmu"
          />

          <v-text-field
              v-model="AccountForm.billingName"
              :error-messages="errors.billingName"
              :outlined="true"
              :dense="true"
              label="Spoločnosť"
          />

          <template
              v-if="AccountForm.billingOnCompany"
          >
            <v-text-field
                v-model="AccountForm.billingBusinessId"
                :error-messages="errors.billingBusinessId"
                :outlined="true"
                :dense="true"
                label="IČO"
            />

            <v-text-field
                v-model="AccountForm.billingTaxId"
                :error-messages="errors.billingTaxId"
                :outlined="true"
                :dense="true"
                label="DIČ"
            />

            <v-text-field
                v-model="AccountForm.billingVatId"
                :error-messages="errors.billingVatId"
                :outlined="true"
                :dense="true"
                label="IČ DPH"
            />
          </template>

          <v-text-field
              v-model="AccountForm.billingStreet"
              :error-messages="errors.billingStreet"
              :outlined="true"
              :dense="true"
              label="Ulica"
              :persistent-hint="true"
              hint="V prípade, že nevieš tak: -"
          />

          <v-text-field
              v-model="AccountForm.billingCity"
              :error-messages="errors.billingCity"
              :outlined="true"
              :dense="true"
              label="Mesto"
              :persistent-hint="true"
              hint="V prípade, že nevieš tak: -"
          />

          <v-text-field
              v-model="AccountForm.billingZip"
              :error-messages="errors.billingZip"
              :outlined="true"
              :dense="true"
              label="PSČ"
              :persistent-hint="true"
              hint="V prípade, že nevieš tak: -"
          />

          <v-select
              v-model="AccountForm.billingCountry"
              :error-messages="errors.billingCountry"
              :outlined="true"
              :dense="true"
              label="Krajina"
              :items="$store.getters['register/getAccountBillingCountry']"
          />

          <v-text-field
              v-model="AccountForm.billingSign"
              :error-messages="errors.billingSign"
              :outlined="true"
              :dense="true"
              label="Zastúpenie"
              :persistent-hint="true"
              hint="V prípade, že viac tak: ,"
          />

          <v-text-field
              v-model="AccountForm.iban"
              :error-messages="errors.iban"
              :outlined="true"
              :dense="true"
              label="IBAN"
          />

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="warning"
              @click="submit"
          >
            <v-icon>
              mdi-pencil
            </v-icon>

            Upraviť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Accounts from '@/services/accounts'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    data() {
      return {
        loading: false,
        dialog: false,
        Account: {},
        AccountForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Accounts
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Account - response.data.data.Account

            this.AccountForm = {
              billingOnCompany: response.data.data.Account.billingOnCompany,
              billingName: response.data.data.Account.billingName,
              billingBusinessId: response.data.data.Account.billingBusinessId,
              billingTaxId: response.data.data.Account.billingTaxId,
              billingVatId: response.data.data.Account.billingVatId,
              billingStreet: response.data.data.Account.billingStreet,
              billingCity: response.data.data.Account.billingCity,
              billingZip: response.data.data.Account.billingZip,
              billingCountry: response.data.data.Account.billingCountry,
              billingSign: response.data.data.Account.billingSign,
              iban: response.data.data.Account.iban
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.dialog = true

            this.loading = false
          })
      },
      submit() {
        this.loading = true

        Accounts
          .update({
            id: this.id,
            data: this.AccountForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
