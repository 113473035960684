var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',_vm._g(_vm._b({attrs:{"outlined":true,"loading":_vm.loading,"disabled":_vm.loading,"color":"success"},on:{"click":_vm.init}},'v-btn',_vm.$attrs,false),_vm.$listeners),[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._t("default"),_c('v-dialog',{attrs:{"persistent":true,"scrollable":true,"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Vytvoriť recenziu "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":true,"color":"default"},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',[_c('p',[_vm._v(" Pre vytvorenie recenzie je potrebné vyplniť všetky povinné polia. ")]),_c('ImageFiles',{attrs:{"outlined":true,"dense":true,"label":"Obrázok","File":_vm.ReviewForm.File,"errorsFile":_vm.errors.File},on:{"setFile":_vm.setFile}}),_c('v-text-field',{attrs:{"error-messages":_vm.errors.author,"outlined":true,"dense":true,"label":"Autor"},model:{value:(_vm.ReviewForm.author),callback:function ($$v) {_vm.$set(_vm.ReviewForm, "author", $$v)},expression:"ReviewForm.author"}}),_c('SuggestClients',{attrs:{"error-messages":_vm.errors.Client,"outlined":true,"dense":true,"label":"Klient","clearable":true,"createButton":['ROLE_MASTER'].includes(_vm.$store.state.auth.User.role)},on:{"change":() => {
              _vm.ReviewForm.Project = ''
            }},model:{value:(_vm.ReviewForm.Client),callback:function ($$v) {_vm.$set(_vm.ReviewForm, "Client", $$v)},expression:"ReviewForm.Client"}}),_c('SuggestProjects',{attrs:{"error-messages":_vm.errors.Project,"outlined":true,"dense":true,"label":"Projekt","clearable":true,"createButton":['ROLE_MASTER'].includes(_vm.$store.state.auth.User.role),"support":(projects) => projects.filter((Project) => (
              _vm.ReviewForm.Client
                ? Project.Client.id === _vm.ReviewForm.Client
                : false
            ))},model:{value:(_vm.ReviewForm.Project),callback:function ($$v) {_vm.$set(_vm.ReviewForm, "Project", $$v)},expression:"ReviewForm.Project"}}),_c('v-textarea',{attrs:{"error-messages":_vm.errors.description,"outlined":true,"dense":true,"label":"Popis","rows":6},model:{value:(_vm.ReviewForm.description),callback:function ($$v) {_vm.$set(_vm.ReviewForm, "description", $$v)},expression:"ReviewForm.description"}}),_c('v-btn',{attrs:{"rounded":true,"outlined":true,"loading":_vm.loading,"disabled":_vm.loading,"color":"success"},on:{"click":_vm.submit}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" Vytvoriť ")],1)],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }