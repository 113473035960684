<template>
  <v-card
      v-if="content"
  >
    <v-card-title>
      <template
          v-if="Ticket.subject"
      >
        <span
            class="text-truncate"
            style="max-width: 900px"
        >
          {{ Ticket.subject }}
        </span>
      </template>

      <template
          v-else
      >
        Nevyplnené
      </template>

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          color="primary"
          to="/tickets/all"
      >
        <v-icon>
          mdi-reply
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle>
      {{ new Date(Ticket.deliveredAt)|dateFormat('DD.MM.YYYY HH:mm') }}
      od
      {{ Ticket.email }}
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-tabs
              v-model="tabsStep"
              color="secondary"
              :hide-slider="true"
          >
            <template
                v-if="Ticket.step >= 0 && Ticket.step <= 5"
            >
              <v-tab
                  v-if="!Ticket.start"
                  class="white lighten-3"
                  @click="makeStart"
              >
                <v-icon
                    color="black lighten-3"
                >
                  mdi-flag
                </v-icon>

                &nbsp;

                Začaté
              </v-tab>

              <v-tab
                  v-else
                  :disabled="true"
                  class="white lighten-3"
              >
                <v-icon
                    color="black lighten-3"
                >
                  mdi-flag
                </v-icon>

                &nbsp;

                Začaté
              </v-tab>
            </template>

            <template
                v-if="Ticket.step >= 1 && Ticket.step <= 5"
            >
              <template
                  v-if="Ticket.updatable && Ticket.updatableFirstStep"
              >
                <v-tab
                    class="grey lighten-3"
                    @click="setType($store.state.register.Ticket.type['unlimited'].value)"
                >
                  <v-icon
                      :color="$store.state.register.Ticket.type['unlimited'].color"
                  >
                    {{ $store.state.register.Ticket.type['unlimited'].icon }}
                  </v-icon>

                  &nbsp;

                  1a. {{ $store.state.register.Ticket.type['unlimited'].text }}
                </v-tab>

                <v-tab
                    class="grey lighten-3"
                    @click="setType($store.state.register.Ticket.type['pricelist'].value)"
                >
                  <v-icon
                      :color="$store.state.register.Ticket.type['pricelist'].color"
                  >
                    {{ $store.state.register.Ticket.type['pricelist'].icon }}
                  </v-icon>

                  &nbsp;

                  1b. {{ $store.state.register.Ticket.type['pricelist'].text }}
                </v-tab>
              </template>

              <template
                  v-else
              >
                <v-tab
                    v-if="Ticket.type === $store.state.register.Ticket.type['unlimited'].value"
                    :disabled="true"
                    class="grey lighten-3"
                >
                  <v-icon
                      :color="$store.state.register.Ticket.type['unlimited'].color"
                  >
                    {{ $store.state.register.Ticket.type['unlimited'].icon }}
                  </v-icon>

                  &nbsp;

                  1. {{ $store.state.register.Ticket.type['unlimited'].text }}
                </v-tab>

                <v-tab
                    v-if="Ticket.type === $store.state.register.Ticket.type['pricelist'].value"
                    :disabled="true"
                    class="grey lighten-3"
                >
                  <v-icon
                      :color="$store.state.register.Ticket.type['pricelist'].color"
                  >
                    {{ $store.state.register.Ticket.type['pricelist'].icon }}
                  </v-icon>

                  &nbsp;

                  1. {{ $store.state.register.Ticket.type['pricelist'].text }}
                </v-tab>
              </template>
            </template>

            <template
                v-if="Ticket.step >= 2 && Ticket.step <= 5"
            >
              <template
                  v-if="Ticket.type === $store.state.register.Ticket.type['pricelist'].value"
              >
                <template
                    v-if="Ticket.updatable && Ticket.updatableSecondStep"
                >
                  <v-tab
                      class="grey lighten-3"
                      @click="setPricelistStatus($store.state.register.Ticket.pricelistStatus['accepted'].value)"
                  >
                    <v-icon
                        :color="$store.state.register.Ticket.pricelistStatus['accepted'].color"
                    >
                      {{ $store.state.register.Ticket.pricelistStatus['accepted'].icon }}
                    </v-icon>

                    {{ $store.state.register.Ticket.pricelistStatus['accepted'].text }}
                  </v-tab>

                  <v-tab
                      class="grey lighten-3"
                      @click="setPricelistStatus($store.state.register.Ticket.pricelistStatus['rejected'].value)"
                  >
                    <v-icon
                        :color="$store.state.register.Ticket.pricelistStatus['rejected'].color"
                    >
                      {{ $store.state.register.Ticket.pricelistStatus['rejected'].icon }}
                    </v-icon>

                    {{ $store.state.register.Ticket.pricelistStatus['rejected'].text }}
                  </v-tab>
                </template>

                <template
                    v-else
                >
                  <v-tab
                      v-if="Ticket.pricelistStatus === $store.state.register.Ticket.pricelistStatus['accepted'].value"
                      :disabled="true"
                      class="grey lighten-3"
                  >
                    <v-icon
                        :color="$store.state.register.Ticket.pricelistStatus['accepted'].color"
                    >
                      {{ $store.state.register.Ticket.pricelistStatus['accepted'].icon }}
                    </v-icon>

                    {{ $store.state.register.Ticket.pricelistStatus['accepted'].text }}
                  </v-tab>

                  <v-tab
                      v-if="Ticket.pricelistStatus === $store.state.register.Ticket.pricelistStatus['rejected'].value"
                      :disabled="true"
                      class="grey lighten-3"
                  >
                    <v-icon
                        :color="$store.state.register.Ticket.pricelistStatus['rejected'].color"
                    >
                      {{ $store.state.register.Ticket.pricelistStatus['rejected'].icon }}
                    </v-icon>

                    {{ $store.state.register.Ticket.pricelistStatus['rejected'].text }}
                  </v-tab>
                </template>
              </template>
            </template>

            <template
                v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
            >
              <template
                  v-if="Ticket.step >= 3 && Ticket.step <= 5"
              >
                <v-tab
                    v-if="!Ticket.finish"
                    class="white lighten-3"
                    @click="makeFinish"
                >
                  <v-icon
                      color="black lighten-3"
                  >
                    mdi-flag-checkered
                  </v-icon>

                  &nbsp;

                  Ukončené
                </v-tab>


                <v-tab
                    v-else
                    :disabled="true"
                    class="white lighten-3"
                >
                  <v-icon
                      color="black lighten-3"
                  >
                    mdi-flag-checkered
                  </v-icon>

                  &nbsp;

                  Ukončené
                </v-tab>

                <v-tab
                    :disabled="true"
                    class="grey lighten-3"
                >
                  <v-icon
                      v-if="Ticket.balance === 0"
                      color="blue lighten-3"
                  >
                    mdi-equal
                  </v-icon>

                  <template
                      v-else
                  >
                    <v-icon
                        v-if="Ticket.balance > 0"
                        color="green lighten-3"
                    >
                      mdi-plus
                    </v-icon>

                    <v-icon
                        v-else
                        color="red lighten-3"
                    >
                      mdi-minus
                    </v-icon>
                  </template>

                  &nbsp;

                  {{ Ticket.balance }}
                </v-tab>
              </template>
            </template>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-progress-linear
              v-if="loading"
              :indeterminate="true"
          />

          <v-row>
            <v-col
                cols="12"
                lg="4"
            >
              <v-tabs
                  v-model="tabsFormat"
                  color="secondary"
              >
                <v-tab
                    class="grey lighten-3"
                >
                  HTML text
                </v-tab>

                <v-tab
                    class="grey lighten-3"
                >
                  Čistý text
                </v-tab>
              </v-tabs>

              <v-card>
                <v-card-title>
                  Zadanie
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <v-tabs-items
                          v-model="tabsFormat"
                      >
                        <v-tab-item>
                          <div
                              v-html="Ticket.textHtml"
                              class="ql-editor"
                          />
                        </v-tab-item>

                        <v-tab-item>
                          <div
                              v-html="Ticket.textPlain"
                              class="ql-editor"
                          />
                        </v-tab-item>
                      </v-tabs-items>
                    </v-col>
                  </v-row>

                  <v-row
                      v-if="Ticket.files.length > 0"
                  >
                    <v-col
                        v-for="(File) in Ticket.files"
                        :key="File.id"
                        cols="auto"
                    >
                      <a
                          @click="downloadFileByLink(loadLinkByPath('web/files/generate/original/' + File.token))"
                      >
                        <v-icon>
                          mdi-download
                        </v-icon>

                        {{ File.name }}
                      </a>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
                cols="12"
                lg="4"
            >
              <v-card>
                <v-card-title>
                  Pripomienky

                  <v-spacer/>

                  <v-btn
                      v-if="Ticket.ticketRemindCreatable"
                      :small="true"
                      :icon="true"
                      :outlined="true"
                      color="success"
                      @click="createTicketRemind"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <template
                      v-if="sortedTicketRemindsNotArchive.length > 0"
                  >
                    <TicketRemind
                        v-for="TicketRemind in sortedTicketRemindsNotArchive"
                        :key="TicketRemind.id"
                        :TicketRemind="TicketRemind"
                        @refresh="refresh"
                    />
                  </template>

                  <p
                      v-else
                  >
                    Žiadne
                  </p>

                  <v-expansion-panels
                      v-if="sortedTicketRemindsArchive.length > 0"
                      v-model="expansionPanelsArchive"
                      :multiple="true"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <span>
                          <v-icon
                              color="secondary"
                          >
                            mdi-archive
                          </v-icon>

                          Archív
                        </span>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <p
                            v-for="TicketRemind in sortedTicketRemindsArchive"
                            :key="TicketRemind.id"
                            :class="
                              TicketRemind.strikeOut
                                ? 'text-decoration-line-through'
                                : ''
                            "
                        >
                          {{ new Date(TicketRemind.createdAt)|dateFormat('DD.MM.YYYY') }} - {{ TicketRemind.content }}
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
                cols="12"
                lg="4"
            >
              <v-expansion-panels
                  v-model="expansionPanelsSetting"
                  :multiple="true"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span>
                      <v-icon
                          color="secondary"
                      >
                        mdi-link
                      </v-icon>

                      Priradenie
                    </span>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <template
                        v-if="Ticket.updatable"
                    >
                      <SuggestUsers
                          v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                          v-model="TicketForm.Owner"
                          :error-messages="errors.Owner"
                          :outlined="true"
                          :dense="true"
                          label="Vlastník"
                          :clearable="true"
                          :createButton="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                          @change="submit"
                      />

                      <SuggestClients
                          v-model="TicketForm.Client"
                          :error-messages="errors.Client"
                          :outlined="true"
                          :dense="true"
                          label="Klient"
                          :clearable="true"
                          :createButton="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                          @change="() => {
                            TicketForm.Project = ''

                            submit()
                          }"
                      />

                      <SuggestProjects
                          v-model="TicketForm.Project"
                          :error-messages="errors.Project"
                          :outlined="true"
                          :dense="true"
                          label="Projekt"
                          :clearable="true"
                          :createButton="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                          :support="(projects) => projects.filter((Project) => (
                            TicketForm.Client
                              ? Project.Client.id === TicketForm.Client
                              : false
                          ))"
                          @change="submit"
                      />

                      <SuggestUsers
                          v-model="TicketForm.Seller"
                          :error-messages="errors.Seller"
                          :outlined="true"
                          :dense="true"
                          label="Obchodník"
                          :clearable="true"
                          :createButton="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                          @change="submit"
                      />

                      <SuggestAccounts
                          v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                          v-model="TicketForm.Account"
                          :error-messages="errors.Account"
                          :outlined="true"
                          :dense="true"
                          label="Účet"
                          :clearable="true"
                          :createButton="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                          @change="submit"
                      />

                      <v-text-field
                          v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                          v-model="TicketForm.shareAmount"
                          :error-messages="errors.shareAmount"
                          :outlined="true"
                          :dense="true"
                          label="Výška podielu (%)"
                          @change="submit"
                      />
                    </template>

                    <template
                        v-else
                    >
                      <v-row
                          v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                      >
                        <v-col
                            cols="12"
                        >
                          <p>
                            Vlastník
                          </p>

                          <h3>
                            <template
                                v-if="Ticket.Owner"
                            >
                              {{ Ticket.Owner.name }}
                            </template>

                            <template
                                v-else
                            >
                              <template
                                  v-if="Ticket.ownerName"
                              >
                                {{ Ticket.ownerName }}
                              </template>

                              <template
                                  v-else
                              >
                                Nevyplnené
                              </template>
                            </template>
                          </h3>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                            cols="12"
                        >
                          <p>
                            Klient
                          </p>

                          <h3>
                            <template
                                v-if="Ticket.Client"
                            >
                              {{ Ticket.Client.billingName }}
                            </template>

                            <template
                                v-else
                            >
                              <template
                                  v-if="Ticket.clientBillingName"
                              >
                                {{ Ticket.clientBillingName }}
                              </template>

                              <template
                                  v-else
                              >
                                Nevyplnené
                              </template>
                            </template>
                          </h3>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                            cols="12"
                        >
                          <p>
                            Projekt
                          </p>

                          <h3>
                            <template
                                v-if="Ticket.Project"
                            >
                              {{ Ticket.Project.name }}
                            </template>

                            <template
                                v-else
                            >
                              <template
                                  v-if="Ticket.projectName"
                              >
                                {{ Ticket.projectName }}
                              </template>

                              <template
                                  v-else
                              >
                                Nevyplnené
                              </template>
                            </template>
                          </h3>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                            cols="12"
                        >
                          <p>
                            Obchodník
                          </p>

                          <h3>
                            <template
                                v-if="Ticket.Seller"
                            >
                              {{ Ticket.Seller.name }}
                            </template>

                            <template
                                v-else
                            >
                              <template
                                  v-if="Ticket.sellerName"
                              >
                                {{ Ticket.sellerName }}
                              </template>

                              <template
                                  v-else
                              >
                                Nevyplnené
                              </template>
                            </template>
                          </h3>
                        </v-col>
                      </v-row>

                      <v-row
                          v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                      >
                        <v-col
                            cols="12"
                        >
                          <p>
                            Účet
                          </p>

                          <h3>
                            <template
                                v-if="Ticket.Account"
                            >
                              {{ Ticket.Account.billingName }}
                            </template>

                            <template
                                v-else
                            >
                              <template
                                  v-if="Ticket.accountBillingName"
                              >
                                {{ Ticket.accountBillingName }}
                              </template>

                              <template
                                  v-else
                              >
                                Nevyplnené
                              </template>
                            </template>
                          </h3>
                        </v-col>
                      </v-row>

                      <v-row
                          v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                      >
                        <v-col
                            cols="12"
                        >
                          <p>
                            Výška podielu (%)
                          </p>

                          <h3>
                            {{ Ticket.shareAmount }}
                          </h3>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span>
                      <v-icon
                          color="secondary"
                      >
                        mdi-cog
                      </v-icon>

                      Nastavenia
                    </span>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-row>
                      <v-col
                          cols="6"
                      >
                        <p>
                          Stav
                        </p>

                        <h3>
                          {{ $store.state.register.Ticket.statusItems[Ticket.statusItems].text }}
                        </h3>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <p>
                          Dátum dokončenia
                        </p>

                        <h3>
                          <template
                              v-if="Ticket.deadlineAt"
                          >
                            {{ new Date(Ticket.deadlineAt)|dateFormat('DD.MM.YYYY') }}
                          </template>

                          <template
                              v-else
                          >
                            Nevyplnené
                          </template>
                        </h3>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <template
                    v-if="Ticket.Project"
                >
                  <v-expansion-panel
                      v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                  >
                    <v-expansion-panel-header>
                      <span>
                        <v-icon
                            color="secondary"
                        >
                          mdi-shield-lock
                        </v-icon>

                        Prístupy - Produkčné

                        &nbsp;

                        <ProjectUpdateTicket
                            :icon="true"
                            :ticketId="Ticket.id"
                            :x-small="true"
                            @init="refresh"
                        />
                      </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <div
                          v-if="Ticket.Project.noteProduction"
                          v-html="Ticket.Project.noteProduction"
                          class="ql-editor"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span>
                        <v-icon
                            color="secondary"
                        >
                          mdi-shield-account
                        </v-icon>

                        Prístupy - Vývojové

                        &nbsp;

                        <ProjectUpdateTicket
                            :icon="true"
                            :ticketId="Ticket.id"
                            :x-small="true"
                            @init="refresh"
                        />
                      </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <div
                          v-if="Ticket.Project.noteDevelopment"
                          v-html="Ticket.Project.noteDevelopment"
                          class="ql-editor"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </template>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row
          v-if="Ticket.step >= 2 && Ticket.step <= 5"
      >
        <v-col
            cols="12"
        >
          <v-tabs
              v-model="tabsWindow"
              color="secondary"
          >
            <template
                v-if="Ticket.step >= 2 && Ticket.step <= 5"
            >
              <template
                  v-if="Ticket.type === $store.state.register.Ticket.type['pricelist'].value"
              >
                <v-tab
                    class="orange lighten-3"
                >
                  Nacenené práce ({{ Ticket.priceEstimationItems }} €)
                  <br/>
                  Cenová ponuka ({{ Ticket.pricelistTotal }} €)
                </v-tab>
              </template>
            </template>

            <template
                v-if="Ticket.step >= 3 && Ticket.step <= 5"
            >
              <template
                  v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
              >
                <v-tab
                    class="red lighten-3"
                >
                  Výdavky ({{ Ticket.pricePartExpenses }} €)
                </v-tab>

                <v-tab
                    class="purple lighten-3"
                >
                  Bonusy ({{ Ticket.pricePartBonuses }} €)
                </v-tab>
              </template>

              <v-tab
                  class="yellow lighten-3"
              >
                Vykonané práce ({{ Ticket.priceSpendItems }} €)
                <template
                    v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                >
                  <br/>
                  Fakturácia ({{ Ticket.invoiceTotal }} €)
                  <br/>
                  Podiel ({{ Ticket.shareTotal }} €)
                </template>
              </v-tab>

              <template
                  v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
              >
                <v-tab
                    class="green lighten-3"
                >
                  Príjmy ({{ Ticket.pricePartRevenues }} €)
                </v-tab>

                <v-tab
                    class="blue lighten-3"
                >
                  Provízie ({{ Ticket.pricePartProvisions }} €)
                </v-tab>
              </template>
            </template>
          </v-tabs>

          <v-card>
            <v-card-text>
              <v-tabs-items
                  v-model="tabsWindow"
              >
                <template
                    v-if="Ticket.step >= 2 && Ticket.step <= 5"
                >
                  <template
                      v-if="Ticket.type === $store.state.register.Ticket.type['pricelist'].value"
                  >
                    <v-tab-item>
                      <v-row>
                        <v-col
                            cols="12"
                        >
                          <TicketItemsOffer
                              :Ticket="Ticket"
                              @refresh="refresh"
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                            cols="12"
                        >
                          <v-alert
                              v-if="['ROLE_MASTER'].includes($store.state.auth.User.role) && Ticket.pricelistDownloable"
                              type="error"
                          >
                            Po vygenerovaní cenovej ponuky <b>VYTLAČIŤ</b>, <b>PODPÍSAŤ</b> s klientom a <b>ZALOŽIŤ</b> do šanónu.
                          </v-alert>

                          <v-card
                              class="orange lighten-3"
                          >
                            <v-card-title>
                              Sumár

                              <v-spacer/>

                              <v-btn
                                  v-if="['ROLE_MASTER'].includes($store.state.auth.User.role) && Ticket.pricelistDownloable"
                                  :icon="true"
                                  :outlined="true"
                                  color="default"
                                  @click="downloadFileByLink(loadLinkByPath('admin/tickets/' + Ticket.id + '/generate/pricelist'))"
                              >
                                <v-icon>
                                  mdi-download
                                </v-icon>
                              </v-btn>
                            </v-card-title>

                            <v-card-text>
                              <v-row>
                                <v-col
                                    cols="6"
                                >
                                  <p>
                                    Nacenené práce (h)
                                  </p>

                                  <h3>
                                    {{ Ticket.timeEstimationItems }}
                                  </h3>
                                </v-col>

                                <v-col
                                    cols="6"
                                >
                                  <p>
                                    Nacenené práce (€)
                                  </p>

                                  <h3>
                                    {{ Ticket.priceEstimationItems }}
                                  </h3>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col
                                    cols="12"
                                >
                                  <p>
                                    Cenová ponuka (€)
                                  </p>

                                  <h3>
                                    {{ Ticket.pricelistTotal }}
                                  </h3>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </template>
                </template>

                <template
                    v-if="Ticket.step >= 3 && Ticket.step <= 5"
                >
                  <template
                      v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                  >
                    <v-tab-item>
                      <v-card
                          class="grey lighten-3"
                      >
                        <v-card-title>
                          Zoznam

                          <v-spacer/>

                          <CreateExpenseTicket
                              v-if="Ticket.ticketExpenseCreatable"
                              :icon="true"
                              :Ticket="Ticket"
                              @init="refresh"
                          />
                        </v-card-title>

                        <v-card-text>
                          <v-simple-table>
                            <template
                                v-slot:default
                            >
                              <thead>
                                <tr>
                                  <th
                                      class="fixed"
                                  >
                                    Dátum vykonania
                                  </th>

                                  <th>
                                    Názov
                                  </th>

                                  <th
                                      class="red lighten-3"
                                  >
                                    Časť (€)
                                  </th>

                                  <th/>
                                </tr>
                              </thead>

                              <tbody>
                                <template
                                    v-if="Ticket.ticketExpenses.length > 0"
                                >
                                  <TicketExpense
                                      v-for="(TicketExpense, i) in sortedTicketExpenses"
                                      :key="i"
                                      :TicketExpense="TicketExpense"
                                      @refresh="refresh"
                                  />
                                </template>

                                <template
                                    v-else
                                >
                                  <tr>
                                    <td
                                        :colspan="4"
                                        class="text-center"
                                    >
                                      Nenašli sa žiadne výdavky.
                                    </td>
                                  </tr>
                                </template>
                              </tbody>

                              <tfoot>
                                <tr>
                                  <th
                                      :colspan="2"
                                  >
                                    Medzisúčet (€)
                                  </th>

                                  <th
                                      class="red lighten-3"
                                  >
                                    {{ Ticket.pricePartExpenses }}
                                  </th>

                                  <th/>
                                </tr>
                              </tfoot>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>

                    <v-tab-item>
                      <v-card
                          class="grey lighten-3"
                      >
                        <v-card-title>
                          Zoznam

                          <v-spacer/>

                          <CreateBonusTicket
                              v-if="Ticket.ticketBonusCreatable"
                              :icon="true"
                              :Ticket="Ticket"
                              @init="refresh"
                          />
                        </v-card-title>

                        <v-card-text>
                          <v-simple-table>
                            <template
                                v-slot:default
                            >
                              <thead>
                                <tr>
                                  <th
                                      class="fixed"
                                  >
                                    Dátum vykonania
                                  </th>

                                  <th>
                                    Pracovník
                                  </th>

                                  <th>
                                    Názov
                                  </th>

                                  <th
                                      class="purple lighten-3"
                                  >
                                    Časť (€)
                                  </th>

                                  <th/>
                                </tr>
                              </thead>

                              <tbody>
                                <template
                                    v-if="Ticket.ticketBonuses.length > 0"
                                >
                                  <TicketBonus
                                      v-for="(TicketBonus, i) in sortedTicketBonuses"
                                      :key="i"
                                      :TicketBonus="TicketBonus"
                                      @refresh="refresh"
                                  />
                                </template>

                                <template
                                    v-else
                                >
                                  <tr>
                                    <td
                                        :colspan="5"
                                        class="text-center"
                                    >
                                      Nenašli sa žiadne bonusy.
                                    </td>
                                  </tr>
                                </template>
                              </tbody>

                              <tfoot>
                                <tr>
                                  <th
                                      :colspan="3"
                                  >
                                    Medzisúčet (€)
                                  </th>

                                  <th
                                      class="purple lighten-3"
                                  >
                                    {{ Ticket.pricePartBonuses }}
                                  </th>

                                  <th/>
                                </tr>
                              </tfoot>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </template>

                  <v-tab-item>
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <TicketItemsReport
                            :Ticket="Ticket"
                            @refresh="refresh"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <v-alert
                            v-if="['ROLE_MASTER'].includes($store.state.auth.User.role) && Ticket.protocolDownloable"
                            type="error"
                        >
                          Po vygenerovaní protokolu <b>VYTLAČIŤ</b>, <b>PODPÍSAŤ</b> s klientom a <b>ZALOŽIŤ</b> do šanónu.
                        </v-alert>

                        <v-card
                            class="yellow lighten-3"
                        >
                          <v-card-title>
                            Sumár

                            <v-spacer/>

                            <v-btn
                                v-if="['ROLE_MASTER'].includes($store.state.auth.User.role) && Ticket.protocolDownloable"
                                :icon="true"
                                :outlined="true"
                                color="default"
                                @click="downloadFileByLink(loadLinkByPath('admin/tickets/' + Ticket.id + '/generate/protocol'))"
                            >
                              <v-icon>
                                mdi-download
                              </v-icon>
                            </v-btn>
                          </v-card-title>

                          <v-card-text>
                            <v-row>
                              <v-col
                                  cols="6"
                              >
                                <p>
                                  Vykonané práce (h)
                                </p>

                                <h3>
                                  {{ Ticket.timeSpendItems }}
                                </h3>
                              </v-col>

                              <v-col
                                  cols="6"
                              >
                                <p>
                                  Vykonané práce (€)
                                </p>

                                <h3>
                                  {{ Ticket.priceSpendItems }}
                                </h3>
                              </v-col>
                            </v-row>

                            <v-row
                                v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                            >
                              <v-col
                                  cols="6"
                              >
                                <p>
                                  Fakturácia (€)
                                </p>

                                <h3>
                                  {{ Ticket.invoiceTotal }}
                                </h3>
                              </v-col>

                              <v-col
                                  cols="6"
                              >
                                <p>
                                  Podiel (€)
                                </p>

                                <h3>
                                  {{ Ticket.shareTotal }}
                                </h3>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <template
                      v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                  >
                    <v-tab-item>
                      <v-card
                          class="grey lighten-3"
                      >
                        <v-card-title>
                          Zoznam

                          <v-spacer/>

                          <CreateRevenueTicket
                              v-if="Ticket.ticketRevenueCreatable"
                              :icon="true"
                              :Ticket="Ticket"
                              @init="refresh"
                          />
                        </v-card-title>

                        <v-card-text>
                          <v-simple-table>
                            <template
                                v-slot:default
                            >
                              <thead>
                                <tr>
                                  <th
                                      class="fixed"
                                  >
                                    Dátum vykonania
                                  </th>

                                  <th>
                                    Názov
                                  </th>

                                  <th
                                      class="green lighten-3"
                                  >
                                    Časť (€)
                                  </th>

                                  <th/>
                                </tr>
                              </thead>

                              <tbody>
                                <template
                                    v-if="Ticket.ticketRevenues.length > 0"
                                >
                                  <TicketRevenue
                                      v-for="(TicketRevenue, i) in sortedTicketRevenues"
                                      :key="i"
                                      :TicketRevenue="TicketRevenue"
                                      @refresh="refresh"
                                  />
                                </template>

                                <template
                                    v-else
                                >
                                  <tr>
                                    <td
                                        :colspan="4"
                                        class="text-center"
                                    >
                                      Nenašli sa žiadne príjmy.
                                    </td>
                                  </tr>
                                </template>
                              </tbody>

                              <tfoot>
                                <tr>
                                  <th
                                      :colspan="2"
                                  >
                                    Medzisúčet (€)
                                  </th>

                                  <th
                                      class="green lighten-3"
                                  >
                                    {{ Ticket.pricePartRevenues }}
                                  </th>

                                  <th/>
                                </tr>
                              </tfoot>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>

                    <v-tab-item>
                      <v-card
                          class="grey lighten-3"
                      >
                        <v-card-title>
                          Zoznam

                          <v-spacer/>

                          <CreateProvisionTicket
                              v-if="Ticket.ticketProvisionCreatable"
                              :icon="true"
                              :Ticket="Ticket"
                              @init="refresh"
                          />
                        </v-card-title>

                        <v-card-text>
                          <v-simple-table>
                            <template
                                v-slot:default
                            >
                              <thead>
                                <tr>
                                  <th
                                      class="fixed"
                                  >
                                    Dátum vykonania
                                  </th>

                                  <th>
                                    Pracovník
                                  </th>

                                  <th>
                                    Názov
                                  </th>

                                  <th
                                      class="blue lighten-3"
                                  >
                                    Časť (€)
                                  </th>

                                  <th/>
                                </tr>
                              </thead>

                              <tbody>
                                <template
                                    v-if="Ticket.ticketProvisions.length > 0"
                                >
                                  <TicketProvision
                                      v-for="(TicketProvision, i) in sortedTicketProvisions"
                                      :key="i"
                                      :TicketProvision="TicketProvision"
                                      @refresh="refresh"
                                  />
                                </template>

                                <template
                                    v-else
                                >
                                  <tr>
                                    <td
                                        :colspan="5"
                                        class="text-center"
                                    >
                                      Nenašli sa žiadne provízie.
                                    </td>
                                  </tr>
                                </template>
                              </tbody>

                              <tfoot>
                                <tr>
                                  <th
                                      :colspan="3"
                                  >
                                    Medzisúčet (€)
                                  </th>

                                  <th
                                      class="blue lighten-3"
                                  >
                                    {{ Ticket.pricePartProvisions }}
                                  </th>

                                  <th/>
                                </tr>
                              </tfoot>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </template>
                </template>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-progress-circular
      v-else
      :indeterminate="true"
  />
</template>

<script>
  import Tickets from '@/services/tickets'
  import TicketReminds from '@/services/ticket-reminds'

  import TicketRemind from '@/components/Ticket/TicketRemind'
  import ProjectUpdateTicket from '@/components/Ticket/ProjectUpdateTicket'
  import TicketItemsOffer from '@/components/Ticket/TicketItemsOffer'
  import TicketItemsReport from '@/components/Ticket/TicketItemsReport'
  import CreateExpenseTicket from '@/components/Ticket/CreateExpenseTicket'
  import TicketExpense from '@/components/Ticket/TicketExpense'
  import CreateBonusTicket from '@/components/Ticket/CreateBonusTicket'
  import TicketBonus from '@/components/Ticket/TicketBonus'
  import CreateRevenueTicket from '@/components/Ticket/CreateRevenueTicket'
  import TicketRevenue from '@/components/Ticket/TicketRevenue'
  import CreateProvisionTicket from '@/components/Ticket/CreateProvisionTicket'
  import TicketProvision from '@/components/Ticket/TicketProvision'

  import SuggestAccounts from '@/components/Accounts/Suggest'
  import SuggestClients from '@/components/Clients/Suggest'
  import SuggestProjects from '@/components/Projects/Suggest'
  import SuggestUsers from '@/components/Users/Suggest'

  export default {
    name: 'Ticket',
    components: {
      TicketRemind,
      ProjectUpdateTicket,
      TicketItemsOffer,
      TicketItemsReport,
      CreateExpenseTicket,
      TicketExpense,
      CreateBonusTicket,
      TicketBonus,
      CreateRevenueTicket,
      TicketRevenue,
      CreateProvisionTicket,
      TicketProvision,
      SuggestAccounts,
      SuggestClients,
      SuggestProjects,
      SuggestUsers
    },
    data() {
      return {
        content: false,
        loading: false,
        Ticket: {},
        TicketForm: {},
        errors: {},
        tabsFormat: 0,
        tabsStep: 0,
        tabsWindow: 0,
        expansionPanelsArchive: [],
        expansionPanelsSetting: [
          0,
          1
        ]
      }
    },
    metaInfo() {
      return {
        title: (
          this.Ticket.subject
            ? this.Ticket.subject
            : 'Nevyplnené'
        )
      }
    },
    mounted() {
      this.init()
    },
    watch: {
      $route(to, from) {
        if (to.path !== from.path) {
          this.init()
        }
      }
    },
    computed: {
      sortedTicketRemindsNotArchive() {
        const sortedTicketRemindsNotArchive = [...this.Ticket.ticketReminds]

        sortedTicketRemindsNotArchive.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

        return sortedTicketRemindsNotArchive.filter((TicketRemind) => !TicketRemind.archive)
      },
      sortedTicketRemindsArchive() {
        const sortedTicketRemindsArchive = [...this.Ticket.ticketReminds]

        sortedTicketRemindsArchive.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

        return sortedTicketRemindsArchive.filter((TicketRemind) => TicketRemind.archive)
      },
      sortedTicketExpenses() {
        const sortedTicketExpenses = this.Ticket.ticketExpenses

        sortedTicketExpenses.sort((a, b) => new Date(b.Expense.workedAt) - new Date(a.Expense.workedAt))

        return sortedTicketExpenses;
      },
      sortedTicketBonuses() {
        const sortedTicketBonuses = this.Ticket.ticketBonuses

        sortedTicketBonuses.sort((a, b) => new Date(b.Bonus.workedAt) - new Date(a.Bonus.workedAt))

        return sortedTicketBonuses;
      },
      sortedTicketRevenues() {
        const sortedTicketRevenues = this.Ticket.ticketRevenues

        sortedTicketRevenues.sort((a, b) => new Date(b.Revenue.workedAt) - new Date(a.Revenue.workedAt))

        return sortedTicketRevenues;
      },
      sortedTicketProvisions() {
        const sortedTicketProvisions = this.Ticket.ticketProvisions

        sortedTicketProvisions.sort((a, b) => new Date(b.Provision.workedAt) - new Date(a.Provision.workedAt))

        return sortedTicketProvisions;
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      refresh() {
        this.loadData('loading', true)
      },
      makeStart() {
        this.TicketForm.start = true

        this.submit()
      },
      setType(type) {
        this.TicketForm.type = type

        this.submit()
      },
      setPricelistStatus(pricelistStatus) {
        this.TicketForm.pricelistStatus = pricelistStatus

        this.submit()
      },
      makeFinish() {
        this.TicketForm.finish = true

        this.submit()
      },
      createTicketRemind() {
        TicketReminds
          .createTicket({
            ticketId: this.$route.params.id,
            data: {}
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.errors = {}

            this.refresh()
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 403:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/tickets')

                break
            }
          })
      },
      loadData(key = 'content', value = false) {
        this[key] = value

        Tickets
          .read({
            id: this.$route.params.id
          })
          .then((response) => {
            this.Ticket = response.data.data.Ticket

            this.TicketForm = {}

            if (response.data.data.Ticket.updatable) {
              this.TicketForm = {
                ...this.TicketForm,
                Client: (
                  response.data.data.Ticket.Client
                    ? response.data.data.Ticket.Client.id
                    : ''
                ),
                Project: (
                  response.data.data.Ticket.Project
                    ? response.data.data.Ticket.Project.id
                    : ''
                ),
                Seller: (
                  response.data.data.Ticket.Seller
                    ? response.data.data.Ticket.Seller.id
                    : ''
                ),
                start: response.data.data.Ticket.start
              }

              if (response.data.data.Ticket.updatableFirstStep) {
                this.TicketForm = {
                  ...this.TicketForm,
                  type: response.data.data.Ticket.type
                }
              }

              if (response.data.data.Ticket.updatableSecondStep) {
                this.TicketForm = {
                  ...this.TicketForm,
                  pricelistStatus: response.data.data.Ticket.pricelistStatus
                }
              }

              if (['ROLE_MASTER'].includes(this.$store.state.auth.User.role)) {
                this.TicketForm = {
                  ...this.TicketForm,
                  Owner: (
                    response.data.data.Ticket.Owner
                      ? response.data.data.Ticket.Owner.id
                      : ''
                  ),
                  Account: (
                    response.data.data.Ticket.Account
                      ? response.data.data.Ticket.Account.id
                      : ''
                  ),
                  shareAmount: response.data.data.Ticket.shareAmount
                }

                if (response.data.data.Ticket.updatableThirdStep) {
                  this.TicketForm = {
                    ...this.TicketForm,
                    finish: response.data.data.Ticket.finish
                  }
                }
              }
            }
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/tickets')

                break
            }
          })
          .finally(() => {
            this[key] = !value
          })
      },
      submit() {
        this.loading = true

        Tickets
          .update({
            id: this.$route.params.id,
            data: this.TicketForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.errors = {}

            this.refresh()
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 403:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/tickets')

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      loadLinkByPath(path) {
        return process.env.VUE_APP_AXIOS_URL + path
      },
      downloadFileByLink(link) {
        window.open(link)
      }
    }
  }
</script>
