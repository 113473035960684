<template>
  <tr>
    <td
        class="fixed"
    >
      {{ new Date(TicketProvision.Provision.workedAt)|dateFormat('DD.MM.YYYY') }}
    </td>

    <td>
      <span
          class="text-truncate"
          style="max-width: 200px"
      >
        {{ TicketProvision.Provision.currentUserName }}
      </span>
    </td>

    <td>
      <span
          class="text-truncate"
          style="max-width: 300px"
      >
        {{ TicketProvision.Provision.title }}
      </span>
    </td>

    <td
        class="blue lighten-3"
    >
      {{ TicketProvision.pricePart }}
    </td>

    <td
        class="text-right fixed"
    >
      <v-btn
          v-if="TicketProvision.deletable"
          :icon="true"
          :outlined="true"
          color="error"
          @click="deleteTicketProvision"
      >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>

      &nbsp;

      <TicketsProvision
          :icon="true"
          :id="TicketProvision.Provision.id"
      />
    </td>
  </tr>
</template>

<script>
  import TicketProvisions from '@/services/ticket-provisions'

  import TicketsProvision from '@/components/Provisions/Tickets'

  export default {
    name: 'TicketProvision',
    props: [
      'TicketProvision'
    ],
    components: {
      TicketsProvision
    },
    methods: {
      deleteTicketProvision() {
        TicketProvisions
          .delete({
            id: this.TicketProvision.id
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.$emit('refresh')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 403:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/tickets')

                break
            }
          })
      }
    }
  }
</script>
