import Vue from 'vue'

class UserReminds {
  async createUser({userId, data}) {
    return await Vue.axios
      .post('admin/user-reminds-by-user/' + userId, data)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('admin/user-reminds/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('admin/user-reminds/' + id)
  }
}

const userReminds = new UserReminds()

export default userReminds
