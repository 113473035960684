<template>
  <v-card>
    <v-card-title>
      Položky ({{ count }})

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-progress-linear
          v-if="loading"
          :indeterminate="true"
      />

      <v-row>
        <v-col
            cols="12"
        >
          <v-tabs
              v-model="tabsType"
              color="secondary"
          >
            <v-tab
                @click="setType('')"
            >
              Všetko
            </v-tab>

            <v-tab
                v-for="(type, i) in $store.getters['register/getTicketItemType']"
                :key="i"
                @click="setType(type.value)"
            >
              <v-icon
                  :color="type.color"
              >
                {{ type.icon }}
              </v-icon>

              &nbsp;

              {{ type.text }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-expansion-panels
              v-model="expansionPanelsFilter"
              :multiple="true"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>
                  <v-icon
                      color="info"
                  >
                    mdi-filter
                  </v-icon>

                  Filtre
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.createdAtFrom"
                        :error-messages="errors.Filters && errors.Filters.createdAtFrom"
                        :outlined="true"
                        :dense="true"
                        label="Dátum vytvorenia od"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.createdAtTo"
                        :error-messages="errors.Filters && errors.Filters.createdAtTo"
                        :outlined="true"
                        :dense="true"
                        label="Dátum vytvorenia do"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.title"
                        :error-messages="errors.Filters && errors.Filters.title"
                        :outlined="true"
                        :dense="true"
                        label="Položka"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <SuggestProjects
                        v-model="params.Filters.Project"
                        :error-messages="errors.Filters && errors.Filters.Project"
                        :outlined="true"
                        :dense="true"
                        label="Projekt"
                        :clearable="true"
                        :createButton="false"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.deadlineAtFrom"
                        :error-messages="errors.Filters && errors.Filters.deadlineAtFrom"
                        :outlined="true"
                        :dense="true"
                        label="Dátum dokončenia od"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.deadlineAtTo"
                        :error-messages="errors.Filters && errors.Filters.deadlineAtTo"
                        :outlined="true"
                        :dense="true"
                        label="Dátum dokončenia do"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-row
              v-if="ticketItems.length > 0"
          >
            <v-col
                v-for="(TicketItem) in ticketItems"
                :key="TicketItem.id"
                cols="12"
                lg="4"
            >
              <v-card
                  :class="$store.state.register.TicketItem.status[TicketItem.status].class"
                  :style="{
                    opacity: (
                      TicketItem.User && TicketItem.User.id === $store.state.auth.User.id
                        ? 1.0
                        : 0.5
                    )
                  }"
              >
                <v-card-title>
                  <v-icon
                      :color="$store.state.register.TicketItem.type[TicketItem.type].color"
                  >
                    {{ $store.state.register.TicketItem.type[TicketItem.type].icon }}
                  </v-icon>

                  &nbsp;

                  <span
                      class="text-truncate"
                      style="max-width: 300px"
                  >
                    {{ TicketItem.title }}
                  </span>

                  <v-spacer/>

                  <template
                      v-if="TicketItem.User && TicketItem.User.id === $store.state.auth.User.id"
                  >
                    <v-tooltip
                        :left="true"
                    >
                      <template
                          v-slot:activator="{
                            on,
                            attrs
                          }"
                      >
                        <v-btn
                            :icon="true"
                            :outlined="true"
                            color="default"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon>
                            mdi-target
                          </v-icon>
                        </v-btn>
                      </template>

                      Je to priradené na mňa!
                    </v-tooltip>

                    &nbsp;
                  </template>

                  <template
                      v-if="TicketItem.deadlineAt && new Date(TicketItem.deadlineAt) <= new Date()"
                  >
                    <v-tooltip
                        :left="true"
                    >
                      <template
                          v-slot:activator="{
                            on,
                            attrs
                          }"
                      >
                        <v-btn
                            :icon="true"
                            :outlined="true"
                            color="warning"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon>
                            mdi-alert
                          </v-icon>
                        </v-btn>
                      </template>

                      Blíži sa dátum dokončenia!
                    </v-tooltip>

                    &nbsp;
                  </template>

                  <v-btn
                      :icon="true"
                      :outlined="true"
                      color="primary"
                      :to="'/ticket-item-auth/' + TicketItem.id"
                  >
                    <v-icon>
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-subtitle>
                  {{ new Date(TicketItem.createdAt)|dateFormat('DD.MM.YYYY') }}
                  pre
                  <template
                      v-if="TicketItem.Ticket.Project"
                  >
                    {{ TicketItem.Ticket.Project.name }}
                  </template>

                  <template
                      v-else
                  >
                    <template
                        v-if="TicketItem.Ticket.projectName"
                    >
                      {{ TicketItem.Ticket.projectName }}
                    </template>

                    <template
                        v-else
                    >
                      Nevyplnené
                    </template>
                  </template>
                </v-card-subtitle>

                <v-card-text>
                  <v-row
                      align="center"
                  >
                    <v-col
                        cols="6"
                    >
                      <p>
                        Stav
                      </p>

                      <h3>
                        {{ $store.state.register.TicketItem.status[TicketItem.status].text }}
                      </h3>
                    </v-col>

                    <v-col
                        cols="6"
                    >
                      <p>
                        Dátum dokončenia
                      </p>

                      <h3>
                        <template
                            v-if="TicketItem.deadlineAt"
                        >
                          {{ new Date(TicketItem.deadlineAt)|dateFormat('DD.MM.YYYY') }}
                        </template>

                        <template
                            v-else
                        >
                          Nevyplnené
                        </template>
                      </h3>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-alert
              v-else
              type="error"
          >
            Nenašli sa žiadne položky.
          </v-alert>
        </v-col>
      </v-row>

      <v-row
          v-if="pages !== 0"
          align="center"
      >
        <v-col
            cols="4"
        >
          <a
              v-for="(limit, i) in $store.getters['register/getTicketItemFilterLimit']"
              :key="i"
              @click="setLimit(limit.value)"
          >
            {{ limit.text }}
          </a>
        </v-col>

        <v-spacer/>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === 1 || loading"
              color="primary"
              @click="previousPage"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-col>

        <v-col
            cols="auto"
        >
          {{ params.page }} / {{ pages }}
        </v-col>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === pages || loading"
              color="primary"
              @click="nextPage"
          >
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import Qs from 'qs'
  import QueryTypes from 'query-types'

  import TicketItems from '@/services/ticket-items'

  import SuggestProjects from '@/components/Projects/Suggest'

  export default {
    name: 'TicketItemsAuth',
    components: {
      SuggestProjects
    },
    data() {
      return {
        tabsType: 0,
        expansionPanelsFilter: [],
        loading: false,
        count: 0,
        pages: 1,
        ticketItems: [],
        params: {
          Filters: {
            createdAtFrom: '',
            createdAtTo: '',
            title: '',
            Project: '',
            deadlineAtFrom: '',
            deadlineAtTo: '',
            type: this.$store.state.register.TicketItem.type['to-work'].value
          },
          order: this.$store.state.register.TicketItem.Filter.order['deadlineAt'].value,
          by: this.$store.state.register.TicketItem.Filter.by['DESC'].value,
          limit: this.$store.state.register.TicketItem.Filter.limit[25].value,
          page: 1
        },
        errors: {}
      }
    },
    beforeMount() {
      this.beforeInit()
    },
    mounted() {
      this.init()
    },
    methods: {
      beforeInit() {
        this.params = {
          ...this.params,
          ...Qs.parse(QueryTypes.parseObject(this.$route.query))
        }

        this.tabsType = (this.$store.getters['register/getTicketItemType'].map((type) => type.value).indexOf(this.params.Filters.type) + 1)
      },
      init() {
        this.loadData()
      },
      setType(type) {
        this.params.Filters.type = type

        this.params.page = 1

        this.init()
      },
      setFilter() {
        this.params.page = 1

        this.init()
      },
      setOrder(order) {
        this.params.order = order

        switch (this.params.by) {
          case this.$store.state.register.TicketItem.Filter.by['ASC'].value:
            this.params.by = this.$store.state.register.TicketItem.Filter.by['DESC'].value

            break

          case this.$store.state.register.TicketItem.Filter.by['DESC'].value:
            this.params.by = this.$store.state.register.TicketItem.Filter.by['ASC'].value

            break
        }

        this.params.page = 1

        this.init()
      },
      setLimit(limit) {
        this.params.limit = limit

        this.params.page = 1

        this.init()
      },
      previousPage() {
        this.params.page -= 1

        window.scrollTo(0, 0)

        this.init()
      },
      nextPage() {
        this.params.page += 1

        window.scrollTo(0, 0)

        this.init()
      },
      loadData() {
        this.loading = true

        this.$router.replace('?' + Qs.stringify(this.params))

        TicketItems
          .indexAuth({
            params: this.params
          })
          .then((response) => {
            this.count = response.data.data.count

            this.pages = response.data.data.pages

            this.ticketItems = response.data.data.ticketItems

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
