import Vue from 'vue'

import Qs from 'qs'

class Provisions {
  async suggest() {
    return await Vue.axios
      .get('admin/provisions/suggest')
  }

  async index({params}) {
    return await Vue.axios
      .get('admin/provisions', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('admin/provisions', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('admin/provisions/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('admin/provisions/' + id, data)
  }

  async updateStatistic({id, data}) {
    return await Vue.axios
      .put('admin/provisions-by-statistic/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('admin/provisions/' + id)
  }
}

const provisions = new Provisions()

export default provisions
