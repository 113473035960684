<template>
  <v-card
      class="grey lighten-3"
  >
    <v-card-title>
      Zoznam ({{ count }})

      <v-spacer/>

      <template
          v-if="TicketItem.ticketItemOfferCreatable"
      >
        <Create
            :icon="true"
            :TicketItem="TicketItem"
            @refresh="$emit('refresh')"
            @init="init"
        />

        &nbsp;
      </template>

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-expansion-panels
              v-model="expansionPanelsFilter"
              :multiple="true"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>
                  <v-icon
                      color="info"
                  >
                    mdi-filter
                  </v-icon>

                  Filtre
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.createdAtFrom"
                        :error-messages="errors.Filters && errors.Filters.createdAtFrom"
                        :outlined="true"
                        :dense="true"
                        label="Dátum vytvorenia od"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.createdAtTo"
                        :error-messages="errors.Filters && errors.Filters.createdAtTo"
                        :outlined="true"
                        :dense="true"
                        label="Dátum vytvorenia do"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <SuggestUsers
                        v-model="params.Filters.User"
                        :error-messages="errors.Filters && errors.Filters.User"
                        :outlined="true"
                        :dense="true"
                        label="Pracovník"
                        :clearable="true"
                        :createButton="false"
                        @change="setFilter"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-card>
            <v-card-text>
              <v-progress-linear
                  v-if="loading"
                  :indeterminate="true"
              />

              <v-simple-table>
                <template
                    v-slot:default
                >
                  <thead>
                    <tr>
                      <th
                          class="fixed"
                      >
                        <a
                            @click="setOrder($store.state.register.TicketItemOffer.Filter.order['createdAt'].value)"
                        >
                          <template
                              v-if="params.order === $store.state.register.TicketItemOffer.Filter.order['createdAt'].value"
                          >
                            <template
                                v-if="params.by === $store.state.register.TicketItemOffer.Filter.by['ASC'].value"
                            >
                              <v-icon>
                                mdi-chevron-up
                              </v-icon>
                            </template>

                            <template
                                v-else
                            >
                              <v-icon>
                                mdi-chevron-down
                              </v-icon>
                            </template>
                          </template>

                          Dátum vytvorenia
                        </a>
                      </th>

                      <th>
                        Pracovník
                      </th>

                      <th>
                        Čas (h)
                      </th>

                      <th>
                        Plat (€ / h)
                      </th>

                      <th
                          class="orange lighten-3"
                      >
                        Celkovo (€)
                      </th>

                      <th
                          class="text-right fixed"
                      />
                    </tr>
                  </thead>

                  <tbody>
                    <template
                        v-if="ticketItemOffers.length > 0"
                    >
                      <tr
                          v-for="TicketItemOffer in ticketItemOffers"
                          :key="TicketItemOffer.id"
                      >
                        <td
                            class="fixed"
                        >
                          {{ new Date(TicketItemOffer.createdAt)|dateFormat('DD.MM.YYYY') }}
                        </td>

                        <td>
                          <span
                              class="text-truncate"
                              style="max-width: 200px"
                          >
                            {{ TicketItemOffer.currentUserName }}
                          </span>
                        </td>

                        <td>
                          {{ TicketItemOffer.timeEstimation }}
                        </td>

                        <td>
                          {{ TicketItemOffer.priceHour }}
                        </td>

                        <td
                            class="orange lighten-3"
                        >
                          {{ TicketItemOffer.priceEstimation }}
                        </td>

                        <td
                            class="text-right fixed"
                        >
                          <v-tooltip
                              v-if="TicketItemOffer.content"
                              :left="true"
                          >
                            <template
                                v-slot:activator="{
                                  on,
                                  attrs
                                }"
                            >
                              <v-btn
                                  :icon="true"
                                  :outlined="true"
                                  color="default"
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <v-icon>
                                  mdi-information-variant
                                </v-icon>
                              </v-btn>
                            </template>

                            {{ TicketItemOffer.content }}
                          </v-tooltip>

                          <template
                              v-if="TicketItemOffer.updatable || TicketItemOffer.deletable"
                          >
                            &nbsp;
                          </template>

                          <template
                              v-if="TicketItemOffer.updatable"
                          >
                            <Update
                                :icon="true"
                                :TicketItem="TicketItem"
                                :id="TicketItemOffer.id"
                                @refresh="$emit('refresh')"
                                @init="init"
                            />

                            <template
                                v-if="TicketItemOffer.deletable"
                            >
                              &nbsp;
                            </template>
                          </template>

                          <template
                              v-if="TicketItemOffer.deletable"
                          >
                            <Delete
                                :icon="true"
                                :TicketItem="TicketItem"
                                :id="TicketItemOffer.id"
                                @refresh="$emit('refresh')"
                                @init="init"
                            />
                          </template>
                        </td>
                      </tr>
                    </template>

                    <template
                        v-else
                    >
                      <tr>
                        <td
                            :colspan="6"
                            class="text-center"
                        >
                          Nenašli sa žiadne cenové ponuky.
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
          v-if="pages !== 0"
          align="center"
      >
        <v-col
            cols="4"
        >
          <a
              v-for="(limit, i) in $store.getters['register/getTicketItemOfferFilterLimit']"
              :key="i"
              @click="setLimit(limit.value)"
          >
            {{ limit.text }}
          </a>
        </v-col>

        <v-spacer/>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === 1 || loading"
              color="primary"
              @click="previousPage"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-col>

        <v-col
            cols="auto"
        >
          {{ params.page }} / {{ pages }}
        </v-col>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === pages || loading"
              color="primary"
              @click="nextPage"
          >
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import TicketItemOffers from '@/services/ticket-item-offers'

  import Create from '@/components/TicketItem/TicketItemOffers/Create'
  import Update from '@/components/TicketItem/TicketItemOffers/Update'
  import Delete from '@/components/TicketItem/TicketItemOffers/Delete'

  import SuggestUsers from '@/components/Users/Suggest'

  export default {
    name: 'TicketItemOffers',
    props: [
      'TicketItem'
    ],
    components: {
      Create,
      Update,
      Delete,
      SuggestUsers
    },
    data() {
      return {
        expansionPanelsFilter: [],
        loading: false,
        count: 0,
        pages: 1,
        ticketItemOffers: [],
        params: {
          Filters: {
            createdAtFrom: '',
            createdAtTo: '',
            User: ''
          },
          order: this.$store.state.register.TicketItemOffer.Filter.order['createdAt'].value,
          by: this.$store.state.register.TicketItemOffer.Filter.by['DESC'].value,
          limit: this.$store.state.register.TicketItemOffer.Filter.limit[25].value,
          page: 1
        },
        errors: {}
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.loadData()
      },
      setFilter() {
        this.params.page = 1

        this.init()
      },
      setOrder(order) {
        this.params.order = order

        switch (this.params.by) {
          case this.$store.state.register.TicketItemOffer.Filter.by['ASC'].value:
            this.params.by = this.$store.state.register.TicketItemOffer.Filter.by['DESC'].value

            break

          case this.$store.state.register.TicketItemOffer.Filter.by['DESC'].value:
            this.params.by = this.$store.state.register.TicketItemOffer.Filter.by['ASC'].value

            break
        }

        this.params.page = 1

        this.init()
      },
      setLimit(limit) {
        this.params.limit = limit

        this.params.page = 1

        this.init()
      },
      previousPage() {
        this.params.page -= 1

        window.scrollTo(0, 0)

        this.init()
      },
      nextPage() {
        this.params.page += 1

        window.scrollTo(0, 0)

        this.init()
      },
      loadData() {
        this.loading = true

        TicketItemOffers
          .indexTicketItem({
            ticketItemId: this.TicketItem.id,
            params: this.params
          })
          .then((response) => {
            this.count = response.data.data.count

            this.pages = response.data.data.pages

            this.ticketItemOffers = response.data.data.ticketItemOffers

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
