<template>
  <v-card
      v-if="content"
  >
    <v-card-title>
      <span
          class="text-truncate"
          style="max-width: 900px"
      >
        {{ TicketItem.title }}
      </span>

      <v-spacer/>

      <v-btn
          v-if="TicketItem.users.map((User) => User.id).includes($store.state.auth.User.id)"
          :icon="true"
          :outlined="true"
          color="secondary"
          :to="'/ticket-item-auth/' + TicketItem.id"
      >
        <v-icon>
          mdi-account
        </v-icon>
      </v-btn>

      &nbsp;

      <v-btn
          :icon="true"
          :outlined="true"
          color="primary"
          :to="'/ticket/' + TicketItem.Ticket.id"
      >
        <v-icon>
          mdi-reply
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle>
      {{ new Date(TicketItem.createdAt)|dateFormat('DD.MM.YYYY') }}
      pre
      <template
          v-if="TicketItem.Ticket.Project"
      >
        {{ TicketItem.Ticket.Project.name }}
      </template>

      <template
          v-else
      >
        <template
            v-if="TicketItem.Ticket.projectName"
        >
          {{ TicketItem.Ticket.projectName }}
        </template>

        <template
            v-else
        >
          Nevyplnené
        </template>
      </template>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-progress-linear
              v-if="loading"
              :indeterminate="true"
          />

          <v-row>
            <v-col
                cols="12"
                lg="4"
            >
              <v-card>
                <v-card-title>
                  Zadanie
                </v-card-title>

                <v-card-text>
                  <template
                      v-if="TicketItem.updatable"
                  >
                    <v-textarea
                        v-model="TicketItemForm.content"
                        :error-messages="errors.content"
                        :outlined="true"
                        :dense="true"
                        label="Podrobný popis"
                        :rows="6"
                        @change="submit"
                    />
                  </template>

                  <p
                      v-else
                  >
                    <template
                        v-if="TicketItem.content"
                    >
                      {{ TicketItem.content }}
                    </template>

                    <template
                        v-else
                    >
                      Nevyplnené
                    </template>
                  </p>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
                cols="12"
                lg="4"
            >
              <v-card>
                <v-card-title>
                  Pripomienky

                  <v-spacer/>

                  <v-btn
                      v-if="TicketItem.ticketItemRemindCreatable"
                      :small="true"
                      :icon="true"
                      :outlined="true"
                      color="success"
                      @click="createTicketItemRemind"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <template
                      v-if="sortedTicketItemRemindsNotArchive.length > 0"
                  >
                    <TicketItemRemind
                        v-for="TicketItemRemind in sortedTicketItemRemindsNotArchive"
                        :key="TicketItemRemind.id"
                        :TicketItemRemind="TicketItemRemind"
                        @refresh="refresh"
                    />
                  </template>

                  <p
                      v-else
                  >
                    Žiadne
                  </p>

                  <v-expansion-panels
                      v-if="sortedTicketItemRemindsArchive.length > 0"
                      v-model="expansionPanelsArchive"
                      :multiple="true"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <span>
                          <v-icon
                              color="secondary"
                          >
                            mdi-archive
                          </v-icon>

                          Archív
                        </span>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <p
                            v-for="TicketItemRemind in sortedTicketItemRemindsArchive"
                            :key="TicketItemRemind.id"
                            :class="
                              TicketItemRemind.strikeOut
                                ? 'text-decoration-line-through'
                                : ''
                            "
                        >
                          {{ new Date(TicketItemRemind.createdAt)|dateFormat('DD.MM.YYYY') }} - {{ TicketItemRemind.content }}
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
                cols="12"
                lg="4"
            >
              <v-expansion-panels
                  v-model="expansionPanelsSetting"
                  :multiple="true"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span>
                      <v-icon
                          color="secondary"
                      >
                        mdi-link
                      </v-icon>

                      Priradenie
                    </span>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <template
                        v-if="TicketItem.updatable &&TicketItem.updatableThirdStep"
                    >
                      <SuggestUsers
                          v-model="TicketItemForm.users"
                          :error-messages="errors.users"
                          :outlined="true"
                          :dense="true"
                          label="Pracovníci"
                          :clearable="true"
                          :multiple="true"
                          :createButton="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                          @change="() => {
                            TicketItemForm.User = ''

                            submit()
                          }"
                      />

                      <SuggestUsers
                          v-model="TicketItemForm.User"
                          :error-messages="errors.User"
                          :outlined="true"
                          :dense="true"
                          label="Pracovník"
                          :clearable="true"
                          :createButton="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                          :support="(users) => users.filter((User) => TicketItemForm.users.includes(User.id))"
                          @change="submit"
                      />

                      <v-row>
                        <v-col
                            cols="6"
                        >
                          <v-select
                              v-model="TicketItemForm.status"
                              :error-messages="errors.status"
                              :outlined="true"
                              :dense="true"
                              label="Stav"
                              :items="$store.getters['register/getTicketItemStatus']"
                              @change="submit"
                          />
                        </v-col>

                        <v-col
                            cols="6"
                        >
                          <v-text-field
                              v-model="TicketItemForm.deadlineAt"
                              :error-messages="errors.deadlineAt"
                              :outlined="true"
                              :dense="true"
                              label="Dátum dokončenia"
                              :clearable="true"
                              @change="submit"
                          />
                        </v-col>
                      </v-row>
                    </template>

                    <template
                        v-else
                    >
                      <v-row>
                        <v-col
                            cols="12"
                        >
                          <p>
                            Pracovníci
                          </p>

                          <h3>
                            <template
                                v-if="TicketItem.users.length > 0"
                            >
                              {{ TicketItem.users.map((User) => User.name).join(', ') }}
                            </template>

                            <template
                                v-else
                            >
                              Žiadni
                            </template>
                          </h3>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                            cols="12"
                        >
                          <p>
                            Pracovník
                          </p>

                          <h3>
                            <template
                                v-if="TicketItem.User"
                            >
                              {{ TicketItem.User.name }}
                            </template>

                            <template
                                v-else
                            >
                              Nevyplnené
                            </template>
                          </h3>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                            cols="6"
                        >
                          <p>
                            Stav
                          </p>

                          <h3>
                            {{ $store.state.register.TicketItem.status[TicketItem.status].text }}
                          </h3>
                        </v-col>

                        <v-col
                            cols="6"
                        >
                          <p>
                            Dátum dokončenia
                          </p>

                          <h3>
                            <template
                                v-if="TicketItem.deadlineAt"
                            >
                              {{ new Date(TicketItem.deadlineAt)|dateFormat('DD.MM.YYYY') }}
                            </template>

                            <template
                                v-else
                            >
                              Nevyplnené
                            </template>
                          </h3>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span>
                      <v-icon
                          color="secondary"
                      >
                        mdi-cog
                      </v-icon>

                      Nastavenia
                    </span>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <p>
                          Tarifa
                        </p>

                        <h3>
                          {{ TicketItem.currentTariffTitle }}
                        </h3>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <template
                    v-if="TicketItem.Ticket.Project"
                >
                  <v-expansion-panel
                      v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                  >
                    <v-expansion-panel-header>
                      <span>
                        <v-icon
                            color="secondary"
                        >
                          mdi-shield-lock
                        </v-icon>

                        Prístupy - Produkčné

                        &nbsp;

                        <ProjectUpdateTicketItem
                            :icon="true"
                            :ticketItemId="TicketItem.id"
                            :x-small="true"
                            @init="refresh"
                        />
                      </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <p
                          v-if="TicketItem.Ticket.Project.noteProduction"
                          v-html="TicketItem.Ticket.Project.noteProduction"
                          class="ql-editor"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span>
                        <v-icon
                            color="secondary"
                        >
                          mdi-shield-account
                        </v-icon>

                        Prístupy - Vývojové

                        &nbsp;

                        <ProjectUpdateTicketItem
                            :icon="true"
                            :ticketItemId="TicketItem.id"
                            :x-small="true"
                            @init="refresh"
                        />
                      </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <p
                          v-if="TicketItem.Ticket.Project.noteDevelopment"
                          v-html="TicketItem.Ticket.Project.noteDevelopment"
                          class="ql-editor"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </template>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-tabs
              v-model="tabsWindow"
              color="secondary"
          >
            <v-tab
                v-if="TicketItem.Ticket.type === $store.state.register.Ticket.type['pricelist'].value"
                class="orange lighten-3"
            >
              Nacenené práce ({{ TicketItem.priceEstimationOffers }} €)
              <br/>
              Cenová ponuka ({{ TicketItem.pricelistSubtotal }} €)
            </v-tab>

            <template
                v-if="TicketItem.Ticket.step >= 3 && TicketItem.Ticket.step <= 5"
            >
              <v-tab
                  class="yellow lighten-3"
              >
                Vykonané práce ({{ TicketItem.priceSpendReports }} €)
                <template
                    v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                >
                  <br/>
                  Fakturácia ({{ TicketItem.invoiceSubtotal }} €)
                </template>
              </v-tab>
            </template>
          </v-tabs>

          <v-card>
            <v-card-text>
              <v-tabs-items
                  v-model="tabsWindow"
              >
                <v-tab-item
                    v-if="TicketItem.Ticket.type === $store.state.register.Ticket.type['pricelist'].value"
                >
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <v-alert
                          v-if="TicketItem.tariffType === $store.state.register.TicketItem.tariffType['group'].value"
                          type="info"
                          class="mb-0"
                      >
                        Položka nie je jednoduchý tarifný typ.
                      </v-alert>

                      <TicketItemOffers
                          v-else
                          :TicketItem="TicketItem"
                          @refresh="refresh"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <v-card
                          class="orange lighten-3"
                      >
                        <v-card-title>
                          Sumár
                        </v-card-title>

                        <v-card-text>
                          <v-row>
                            <v-col
                                cols="6"
                            >
                              <p>
                                Nacenené práce (h)
                              </p>

                              <h3>
                                {{ TicketItem.timeEstimationOffers }}
                              </h3>
                            </v-col>

                            <v-col
                                cols="6"
                            >
                              <p>
                                Nacenené práce (€)
                              </p>

                              <h3>
                                {{ TicketItem.priceEstimationOffers }}
                              </h3>
                            </v-col>
                          </v-row>

                          <template
                              v-if="TicketItem.updatable && TicketItem.updatableSecondStep"
                          >
                            <v-row>
                              <v-col
                                  cols="6"
                              >
                                <v-text-field
                                    v-model="TicketItemForm.pricelistQuantity"
                                    :error-messages="errors.pricelistQuantity"
                                    :outlined="true"
                                    :dense="true"
                                    label="Množstvo (x)"
                                    @change="submit"
                                />

                                <v-row>
                                  <v-col
                                      cols="12"
                                  >
                                    <p>
                                      Sadzba (€ / {{ TicketItem.tariffUnit }})
                                    </p>

                                    <h3>
                                      {{ TicketItem.tariffPrice }}
                                    </h3>
                                  </v-col>
                                </v-row>
                              </v-col>

                              <v-col
                                  cols="6"
                              >
                                <v-text-field
                                    v-model="TicketItemForm.pricelistIncrease"
                                    :error-messages="errors.pricelistIncrease"
                                    :outlined="true"
                                    :dense="true"
                                    label="Prirážka (€)"
                                    @change="submit"
                                />

                                <v-row>
                                  <v-col
                                      cols="12"
                                  >
                                    <p>
                                      Cenová ponuka (€)
                                    </p>

                                    <h3>
                                      {{ TicketItem.pricelistSubtotal }}
                                    </h3>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </template>

                          <template
                              v-else
                          >
                            <v-row>
                              <v-col
                                  cols="6"
                              >
                                <v-row>
                                  <v-col
                                      cols="12"
                                  >
                                    <p>
                                      Množstvo (x)
                                    </p>

                                    <h3>
                                      {{ TicketItem.pricelistQuantity }}
                                    </h3>
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col
                                      cols="12"
                                  >
                                    <p>
                                      Sadzba (€ / {{ TicketItem.tariffUnit }})
                                    </p>

                                    <h3>
                                      {{ TicketItem.tariffPrice }}
                                    </h3>
                                  </v-col>
                                </v-row>
                              </v-col>

                              <v-col
                                  cols="6"
                              >
                                <v-row>
                                  <v-col
                                      cols="12"
                                  >
                                    <p>
                                      Prirážka (€)
                                    </p>

                                    <h3>
                                      {{ TicketItem.pricelistIncrease }}
                                    </h3>
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col
                                      cols="12"
                                  >
                                    <p>
                                      Cenová ponuka (€)
                                    </p>

                                    <h3>
                                      {{ TicketItem.pricelistSubtotal }}
                                    </h3>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </template>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <template
                    v-if="TicketItem.Ticket.step >= 3 && TicketItem.Ticket.step <= 5"
                >
                  <v-tab-item>
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <TicketItemReports
                            :TicketItem="TicketItem"
                            @refresh="refresh"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <v-card
                            class="yellow lighten-3"
                        >
                          <v-card-title>
                            Sumár
                          </v-card-title>

                          <v-card-text>
                            <v-row>
                              <v-col
                                  cols="6"
                              >
                                <p>
                                  Vykonané práce (h)
                                </p>

                                <h3>
                                  {{ TicketItem.timeSpendReports }}
                                </h3>
                              </v-col>

                              <v-col
                                  cols="6"
                              >
                                <p>
                                  Vykonané práce (€)
                                </p>

                                <h3>
                                  {{ TicketItem.priceSpendReports }}
                                </h3>
                              </v-col>
                            </v-row>

                            <v-row
                                v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                            >
                              <v-col
                                  cols="6"
                              >
                                <template
                                    v-if="TicketItem.updatable && TicketItem.updatableThirdStep"
                                >
                                  <v-text-field
                                      v-model="TicketItemForm.invoiceIncrease"
                                      :error-messages="errors.invoiceIncrease"
                                      :outlined="true"
                                      :dense="true"
                                      label="Prirážka (€)"
                                      @change="submit"
                                  />
                                </template>

                                <template
                                    v-else
                                >
                                  <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                      <p>
                                        Prirážka (€)
                                      </p>

                                      <h3>
                                        {{ TicketItem.invoiceIncrease }}
                                      </h3>
                                    </v-col>
                                  </v-row>
                                </template>

                                <v-row>
                                  <v-col
                                      cols="12"
                                  >
                                    <p>
                                      Fakturácia (€)
                                    </p>

                                    <h3>
                                      {{ TicketItem.invoiceSubtotal }}
                                    </h3>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </template>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-progress-circular
      v-else
      :indeterminate="true"
  />
</template>

<script>
  import { dateFormat } from '@vuejs-community/vue-filter-date-format'

  import TicketItems from '@/services/ticket-items'
  import TicketItemReminds from '@/services/ticket-item-reminds'

  import TicketItemOffers from '@/components/TicketItem/TicketItemOffers'
  import TicketItemReports from '@/components/TicketItem/TicketItemReports'
  import TicketItemRemind from '@/components/TicketItem/TicketItemRemind'
  import ProjectUpdateTicketItem from '@/components/TicketItem/ProjectUpdateTicketItem'

  import SuggestUsers from '@/components/Users/Suggest'

  export default {
    name: 'TicketItem',
    components: {
      TicketItemOffers,
      TicketItemReports,
      TicketItemRemind,
      ProjectUpdateTicketItem,
      SuggestUsers
    },
    data() {
      return {
        content: false,
        loading: false,
        TicketItem: {},
        TicketItemForm: {},
        errors: {},
        tabsWindow: 0,
        expansionPanelsArchive: [],
        expansionPanelsSetting: [
          0,
          1
        ]
      }
    },
    metaInfo() {
      return {
        title: this.TicketItem.title
      }
    },
    mounted() {
      this.init()
    },
    watch: {
      $route(to, from) {
        if (to.path !== from.path) {
          this.init()
        }
      }
    },
    computed: {
      sortedTicketItemRemindsNotArchive() {
        const sortedTicketItemRemindsNotArchive = [...this.TicketItem.ticketItemReminds]

        sortedTicketItemRemindsNotArchive.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

        return sortedTicketItemRemindsNotArchive.filter((TicketItemRemind) => !TicketItemRemind.archive)
      },
      sortedTicketItemRemindsArchive() {
        const sortedTicketItemRemindsArchive = [...this.TicketItem.ticketItemReminds]

        sortedTicketItemRemindsArchive.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

        return sortedTicketItemRemindsArchive.filter((TicketItemRemind) => TicketItemRemind.archive)
      },
    },
    methods: {
      init() {
        this.loadData()
      },
      refresh() {
        this.loadData('loading', true)
      },
      createTicketItemRemind() {
        TicketItemReminds
          .createTicketItem({
            ticketItemId: this.$route.params.id,
            data: {}
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.errors = {}

            this.refresh()
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 403:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/tickets')

                break
            }
          })
      },
      loadData(key = 'content', value = false) {
        this[key] = value

        TicketItems
          .read({
            id: this.$route.params.id
          })
          .then((response) => {
            this.TicketItem = response.data.data.TicketItem

            if (response.data.data.TicketItem.updatable) {
              this.TicketItemForm = {
                ...this.TicketItemForm,
                content: response.data.data.TicketItem.content
              }

              if (response.data.data.TicketItem.updatableSecondStep) {
                this.TicketItemForm = {
                  ...this.TicketItemForm,
                  pricelistIncrease: response.data.data.TicketItem.pricelistIncrease,
                  pricelistQuantity: response.data.data.TicketItem.pricelistQuantity
                }
              }

              if (response.data.data.TicketItem.updatableThirdStep) {
                this.TicketItemForm = {
                  ...this.TicketItemForm,
                  users: response.data.data.TicketItem.users.map((User) => User.id),
                  User: (
                    response.data.data.TicketItem.User
                      ? response.data.data.TicketItem.User.id
                      : ''
                  ),
                  status: response.data.data.TicketItem.status,
                  deadlineAt: (
                    response.data.data.TicketItem.deadlineAt
                      ? dateFormat(new Date(response.data.data.TicketItem.deadlineAt), 'DD.MM.YYYY')
                      : ''
                  )
                }

                if (['ROLE_MASTER'].includes(this.$store.state.auth.User.role)) {
                  this.TicketItemForm = {
                    ...this.TicketItemForm,
                    invoiceIncrease: response.data.data.TicketItem.invoiceIncrease
                  }
                }
              }
            }
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/tickets')

                break
            }
          })
          .finally(() => {
            this[key] = !value
          })
      },
      submit() {
        this.loading = true

        TicketItems
          .update({
            id: this.$route.params.id,
            data: this.TicketItemForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.errors = {}

            this.refresh()
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 403:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/tickets')

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
