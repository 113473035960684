import Vue from 'vue'

import Qs from 'qs'

class Bonuses {
  async suggest() {
    return await Vue.axios
      .get('admin/bonuses/suggest')
  }

  async index({params}) {
    return await Vue.axios
      .get('admin/bonuses', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('admin/bonuses', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('admin/bonuses/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('admin/bonuses/' + id, data)
  }

  async updateStatistic({id, data}) {
    return await Vue.axios
      .put('admin/bonuses-by-statistic/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('admin/bonuses/' + id)
  }
}

const bonuses = new Bonuses()

export default bonuses
