<template>
  <v-card>
    <v-card-title>
      {{ title }} ({{ count }})

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-tabs
              v-model="tabsStatusItem"
              color="secondary"
          >
            <v-tab
                @click="setStatusItems('')"
            >
              Všetko
            </v-tab>

            <v-tab
                v-for="(statusItem, i) in $store.getters['register/getTicketStatusItems']"
                :key="i"
                :class="statusItem.class"
                @click="setStatusItems(statusItem.value)"
            >
              {{ statusItem.text }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-expansion-panels
              v-model="expansionPanelsFilter"
              :multiple="true"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>
                  <v-icon
                      color="info"
                  >
                    mdi-filter
                  </v-icon>

                  Filtre
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.deliveredAtFrom"
                        :error-messages="errors.Filters && errors.Filters.deliveredAtFrom"
                        :outlined="true"
                        :dense="true"
                        label="Dátum doručenia od"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.deliveredAtTo"
                        :error-messages="errors.Filters && errors.Filters.deliveredAtTo"
                        :outlined="true"
                        :dense="true"
                        label="Dátum doručenia do"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.subject"
                        :error-messages="errors.Filters && errors.Filters.subject"
                        :outlined="true"
                        :dense="true"
                        label="Predmet"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <SuggestClients
                        v-model="params.Filters.Client"
                        :error-messages="errors.Filters && errors.Filters.Client"
                        :outlined="true"
                        :dense="true"
                        label="Klient"
                        :clearable="true"
                        :createButton="false"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <SuggestProjects
                        v-model="params.Filters.Project"
                        :error-messages="errors.Filters && errors.Filters.Project"
                        :outlined="true"
                        :dense="true"
                        label="Projekt"
                        :clearable="true"
                        :createButton="false"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <SuggestUsers
                        v-model="params.Filters.Seller"
                        :error-messages="errors.Filters && errors.Filters.Seller"
                        :outlined="true"
                        :dense="true"
                        label="Obchodník"
                        :clearable="true"
                        :createButton="false"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.deadlineAtItemsFrom"
                        :error-messages="errors.Filters && errors.Filters.deadlineAtItemsFrom"
                        :outlined="true"
                        :dense="true"
                        label="Dátum dokončenia od"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.deadlineAtItemsTo"
                        :error-messages="errors.Filters && errors.Filters.deadlineAtItemsTo"
                        :outlined="true"
                        :dense="true"
                        label="Dátum dokončenia do"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-card>
            <v-card-text>
              <v-progress-linear
                  v-if="loading"
                  :indeterminate="true"
              />

              <v-simple-table>
                <template
                    v-slot:default
                >
                  <thead>
                    <tr>
                      <th>
                        <a
                            @click="setOrder($store.state.register.Ticket.Filter.order['deliveredAt'].value)"
                        >
                          <template
                              v-if="params.order === $store.state.register.Ticket.Filter.order['deliveredAt'].value"
                          >
                            <template
                                v-if="params.by === $store.state.register.Ticket.Filter.by['ASC'].value"
                            >
                              <v-icon>
                                mdi-chevron-up
                              </v-icon>
                            </template>

                            <template
                                v-else
                            >
                              <v-icon>
                                mdi-chevron-down
                              </v-icon>
                            </template>
                          </template>

                          Dátum doručenia
                        </a>
                      </th>

                      <th
                          class="fixed"
                      >
                        Predmet
                      </th>

                      <template
                          v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                      >
                        <th
                            class="grey lighten-3"
                        >
                          Finálny zostatok (€)
                        </th>

                        <th
                            class="grey lighten-3"
                        >
                          Aktuálny zostatok (€)
                        </th>
                      </template>

                      <th>
                        Klient
                      </th>

                      <th>
                        Projekt
                      </th>

                      <th>
                        Obchodník
                      </th>

                      <th>
                        Stav
                      </th>

                      <th>
                        <a
                            @click="setOrder($store.state.register.Ticket.Filter.order['deadlineAtItems'].value)"
                        >
                          <template
                              v-if="params.order === $store.state.register.Ticket.Filter.order['deadlineAtItems'].value"
                          >
                            <template
                                v-if="params.by === $store.state.register.Ticket.Filter.by['ASC'].value"
                            >
                              <v-icon>
                                mdi-chevron-up
                              </v-icon>
                            </template>

                            <template
                                v-else
                            >
                              <v-icon>
                                mdi-chevron-down
                              </v-icon>
                            </template>
                          </template>

                          Dátum dokončenia
                        </a>
                      </th>

                      <th
                          class="orange lighten-3"
                      >
                        Nacenené práce (h)
                      </th>

                      <th
                          class="orange lighten-3"
                      >
                        Nacenené práce (€)
                      </th>

                      <th
                          class="orange lighten-3"
                      >
                        Cenová ponuka (€)
                      </th>

                      <template
                          v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                      >
                        <th
                            class="red lighten-3"
                        >
                          Výdavky (€)
                        </th>

                        <th
                            class="purple lighten-3"
                        >
                          Bonusy (€)
                        </th>
                      </template>

                      <th
                          class="yellow lighten-3"
                      >
                        Vykonané práce (h)
                      </th>

                      <th
                          class="yellow lighten-3"
                      >
                        Vykonané práce (€)
                      </th>

                      <template
                          v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                      >
                        <th
                            class="yellow lighten-3"
                        >
                          Fakturácia (€)
                        </th>

                        <th
                            class="yellow lighten-3"
                        >
                          Podiel (€)
                        </th>

                        <th
                            class="green lighten-3"
                        >
                          Príjmy (€)
                        </th>

                        <th
                            class="blue lighten-3"
                        >
                          Provízie (€)
                        </th>
                      </template>

                      <th
                          class="text-right fixed"
                      />
                    </tr>
                  </thead>

                  <tbody>
                    <template
                        v-if="tickets.length > 0"
                    >
                      <tr
                          v-for="Ticket in tickets"
                          :key="Ticket.id"
                          :class="$store.state.register.Ticket.statusItems[Ticket.statusItems].class"
                      >
                        <td>
                          {{ new Date(Ticket.deliveredAt)|dateFormat('DD.MM.YYYY HH:mm') }}
                        </td>

                        <td
                            class="fixed"
                        >
                          <v-icon
                              v-if="Ticket.start"
                              color="black lighten-3"
                          >
                            mdi-flag
                          </v-icon>

                          <span
                              class="text-truncate"
                              style="max-width: 300px"
                          >
                            <template
                                v-if="Ticket.subject"
                            >
                              {{ Ticket.subject }}
                            </template>

                            <template
                                v-else
                            >
                              Nevyplnené
                            </template>
                          </span>

                          <v-icon
                              v-if="Ticket.finish"
                              color="black lighten-3"
                          >
                            mdi-flag-checkered
                          </v-icon>
                        </td>

                        <template
                            v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                        >
                          <td
                              class="grey lighten-3"
                          >
                            <template
                                v-if="Ticket.step >= 3 && Ticket.step <= 5"
                            >
                              <v-icon
                                  v-if="Ticket.balance === 0"
                                  color="blue lighten-3"
                              >
                                mdi-equal
                              </v-icon>

                              <template
                                  v-else
                              >
                                <v-icon
                                    v-if="Ticket.balance > 0"
                                    color="green lighten-3"
                                >
                                  mdi-plus
                                </v-icon>

                                <v-icon
                                    v-else
                                    color="red lighten-3"
                                >
                                  mdi-minus
                                </v-icon>
                              </template>

                              &nbsp;

                              {{ Ticket.balance }}
                            </template>

                            <template
                                v-else
                            >
                              -
                            </template>
                          </td>

                          <td
                              class="grey lighten-3"
                          >
                            <template
                                v-if="Ticket.step >= 3 && Ticket.step <= 5"
                            >
                              {{ Ticket.balancePaid }}
                            </template>

                            <template
                                v-else
                            >
                              -
                            </template>
                          </td>
                        </template>

                        <td>
                          <span
                              class="text-truncate"
                              style="max-width: 200px"
                          >
                            <template
                                v-if="Ticket.Client"
                            >
                              {{ Ticket.Client.billingName }}
                            </template>

                            <template
                                v-else
                            >
                              <template
                                  v-if="Ticket.clientBillingName"
                              >
                                {{ Ticket.clientBillingName }}
                              </template>

                              <template
                                  v-else
                              >
                                Nevyplnené
                              </template>
                            </template>
                          </span>
                        </td>

                        <td>
                          <span
                              class="text-truncate"
                              style="max-width: 200px"
                          >
                            <template
                                v-if="Ticket.Project"
                            >
                              {{ Ticket.Project.name }}
                            </template>

                            <template
                                v-else
                            >
                              <template
                                  v-if="Ticket.projectName"
                              >
                                {{ Ticket.projectName }}
                              </template>

                              <template
                                  v-else
                              >
                                Nevyplnené
                              </template>
                            </template>
                          </span>
                        </td>

                        <td>
                          <span
                              class="text-truncate"
                              style="max-width: 200px"
                          >
                            <template
                                v-if="Ticket.Seller"
                            >
                              {{ Ticket.Seller.name }}
                            </template>

                            <template
                                v-else
                            >
                              <template
                                  v-if="Ticket.sellerName"
                              >
                                {{ Ticket.sellerName }}
                              </template>

                              <template
                                  v-else
                              >
                                Nevyplnené
                              </template>
                            </template>
                          </span>
                        </td>

                        <td>
                          {{ $store.state.register.Ticket.statusItems[Ticket.statusItems].text }}
                        </td>

                        <td>
                          <template
                              v-if="Ticket.deadlineAtItems"
                          >
                            {{ new Date(Ticket.deadlineAtItems)|dateFormat('DD.MM.YYYY') }}
                          </template>

                          <template
                              v-else
                          >
                            Nevyplnené
                          </template>
                        </td>

                        <td
                            class="orange lighten-3"
                        >
                          <template
                              v-if="Ticket.step >= 2 && Ticket.step <= 5 && Ticket.type === $store.state.register.Ticket.type['pricelist'].value"
                          >
                            {{ Ticket.timeEstimationItems }}
                          </template>

                          <template
                              v-else
                          >
                            -
                          </template>
                        </td>

                        <td
                            class="orange lighten-3"
                        >
                          <template
                              v-if="Ticket.step >= 2 && Ticket.step <= 5 && Ticket.type === $store.state.register.Ticket.type['pricelist'].value"
                          >
                            {{ Ticket.priceEstimationItems }}
                          </template>

                          <template
                              v-else
                          >
                            -
                          </template>
                        </td>

                        <td
                            class="orange lighten-3"
                        >
                          <template
                              v-if="Ticket.step >= 2 && Ticket.step <= 5 && Ticket.type === $store.state.register.Ticket.type['pricelist'].value"
                          >
                            {{ Ticket.pricelistTotal }}
                          </template>

                          <template
                              v-else
                          >
                            -
                          </template>
                        </td>

                        <template
                            v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                        >
                          <td
                              class="red lighten-3"
                          >
                            <template
                                v-if="Ticket.step >= 3 && Ticket.step <= 5"
                            >
                              {{ Ticket.pricePartExpenses }}
                            </template>

                            <template
                                v-else
                            >
                              -
                            </template>
                          </td>

                          <td
                              class="purple lighten-3"
                          >
                            <template
                                v-if="Ticket.step >= 3 && Ticket.step <= 5"
                            >
                              {{ Ticket.pricePartBonuses }}
                            </template>

                            <template
                                v-else
                            >
                              -
                            </template>
                          </td>
                        </template>

                        <td
                            class="yellow lighten-3"
                        >
                            <template
                                v-if="Ticket.step >= 3 && Ticket.step <= 5"
                            >
                              {{ Ticket.timeSpendItems }}
                            </template>

                            <template
                                v-else
                            >
                              -
                            </template>
                        </td>

                        <td
                            class="yellow lighten-3"
                        >
                            <template
                                v-if="Ticket.step >= 3 && Ticket.step <= 5"
                            >
                              {{ Ticket.priceSpendItems }}
                            </template>

                            <template
                                v-else
                            >
                              -
                            </template>
                        </td>

                        <template
                            v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                        >
                          <td
                              class="yellow lighten-3"
                          >
                            <template
                                v-if="Ticket.step >= 3 && Ticket.step <= 5"
                            >
                              {{ Ticket.invoiceTotal }}
                            </template>

                            <template
                                v-else
                            >
                              -
                            </template>
                          </td>

                          <td
                              class="yellow lighten-3"
                          >
                            <template
                                v-if="Ticket.step >= 3 && Ticket.step <= 5"
                            >
                              {{ Ticket.shareTotal }}
                            </template>

                            <template
                                v-else
                            >
                              -
                            </template>
                          </td>

                          <td
                              class="green lighten-3"
                          >
                            <template
                                v-if="Ticket.step >= 3 && Ticket.step <= 5"
                            >
                              {{ Ticket.pricePartRevenues }}
                            </template>

                            <template
                                v-else
                            >
                              -
                            </template>
                          </td>

                          <td
                              class="blue lighten-3"
                          >
                            <template
                                v-if="Ticket.step >= 3 && Ticket.step <= 5"
                            >
                              {{ Ticket.pricePartProvisions }}
                            </template>

                            <template
                                v-else
                            >
                              -
                            </template>
                          </td>
                        </template>

                        <td
                            class="text-right fixed"
                        >
                          <v-btn
                              :icon="true"
                              :outlined="true"
                              color="primary"
                              :to="'/ticket/' + Ticket.id"
                          >
                            <v-icon>
                              mdi-magnify
                            </v-icon>
                          </v-btn>

                          <template
                              v-if="Ticket.deletable"
                          >
                            &nbsp;

                            <Delete
                                :icon="true"
                                :id="Ticket.id"
                                @init="init"
                            />
                          </template>
                        </td>
                      </tr>
                    </template>

                    <template
                        v-else
                    >
                      <tr>
                        <td
                            :colspan="
                              ['ROLE_MASTER'].includes($store.state.auth.User.role)
                                ? 21
                                : 13
                            "
                            class="text-center"
                        >
                          Nenašli sa žiadne tikety.
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
          v-if="pages !== 0"
          align="center"
      >
        <v-col
            cols="4"
        >
          <a
              v-for="(limit, i) in $store.getters['register/getTicketFilterLimit']"
              :key="i"
              @click="setLimit(limit.value)"
          >
            {{ limit.text }}
          </a>
        </v-col>

        <v-spacer/>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === 1 || loading"
              color="primary"
              @click="previousPage"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-col>

        <v-col
            cols="auto"
        >
          {{ params.page }} / {{ pages }}
        </v-col>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === pages || loading"
              color="primary"
              @click="nextPage"
          >
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import Qs from 'qs'
  import QueryTypes from 'query-types'

  import Tickets from '@/services/tickets'

  import Delete from '@/components/Tickets/Delete'

  import SuggestClients from '@/components/Clients/Suggest'
  import SuggestProjects from '@/components/Projects/Suggest'
  import SuggestUsers from '@/components/Users/Suggest'

  export default {
    name: 'Tickets',
    props: [
      'type',
      'title'
    ],
    components: {
      Delete,
      SuggestClients,
      SuggestProjects,
      SuggestUsers
    },
    data() {
      return {
        tabsStatusItem: 0,
        expansionPanelsFilter: [],
        loading: false,
        count: 0,
        pages: 1,
        tickets: [],
        params: {
          type: this.type,
          Filters: {
            deliveredAtFrom: '',
            deliveredAtTo: '',
            subject: '',
            Client: '',
            Project: '',
            Seller: '',
            statusItems: '',
            deadlineAtItemsFrom: '',
            deadlineAtItemsTo: ''
          },
          order: this.$store.state.register.Ticket.Filter.order['deliveredAt'].value,
          by: this.$store.state.register.Ticket.Filter.by['DESC'].value,
          limit: this.$store.state.register.Ticket.Filter.limit[25].value,
          page: 1
        },
        errors: {}
      }
    },
    metaInfo() {
      return {
        title: this.title
      }
    },
    beforeMount() {
      this.beforeInit()
    },
    mounted() {
      this.init()
    },
    methods: {
      beforeInit() {
        this.params = {
          ...this.params,
          ...Qs.parse(QueryTypes.parseObject(this.$route.query))
        }

        this.tabsStatusItem = (this.$store.getters['register/getTicketStatusItems'].map((statusItems) => statusItems.value).indexOf(this.params.Filters.statusItems) + 1)
      },
      init() {
        this.loadData()
      },
      setStatusItems(statusItems) {
        this.params.Filters.statusItems = statusItems

        this.params.page = 1

        this.init()
      },
      setFilter() {
        this.params.page = 1

        this.init()
      },
      setOrder(order) {
        this.params.order = order

        switch (this.params.by) {
          case this.$store.state.register.Ticket.Filter.by['ASC'].value:
            this.params.by = this.$store.state.register.Ticket.Filter.by['DESC'].value

            break

          case this.$store.state.register.Ticket.Filter.by['DESC'].value:
            this.params.by = this.$store.state.register.Ticket.Filter.by['ASC'].value

            break
        }

        this.params.page = 1

        this.init()
      },
      setLimit(limit) {
        this.params.limit = limit

        this.params.page = 1

        this.init()
      },
      previousPage() {
        this.params.page -= 1

        window.scrollTo(0, 0)

        this.init()
      },
      nextPage() {
        this.params.page += 1

        window.scrollTo(0, 0)

        this.init()
      },
      loadData() {
        this.loading = true

        this.$router.replace('?' + Qs.stringify(this.params))

        Tickets
          .index({
            params: this.params
          })
          .then((response) => {
            this.count = response.data.data.count

            this.pages = response.data.data.pages

            this.tickets = response.data.data.tickets

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
