const master = [
  {
    to: '/home',
    title: 'Nástenka',
    icon: 'mdi-home'
  },
  {
    to: '/health',
    title: 'Zdravie',
    icon: 'mdi-heart'
  },
  {
    to: '/statutes',
    title: 'Stanovy',
    icon: 'mdi-frequently-asked-questions'
  },
  {
    to: '/accounts',
    title: 'Účty',
    icon: 'mdi-bank'
  },
  {
    to: '/tariffs',
    title: 'Tarify',
    icon: 'mdi-tag'
  },
  {
    to: '/users',
    title: 'Užívatelia',
    icon: 'mdi-account'
  },
  {
    to: '/clients',
    title: 'Klienti',
    icon: 'mdi-domain'
  },
  {
    to: '/projects',
    title: 'Projekty',
    icon: 'mdi-web'
  },
  {
    title: 'Obsah',
    icon: 'mdi-content-copy',
    value: false,
    subitems: [
      {
        to: '/reviews',
        title: 'Recenzie'
      },
      {
        to: '/contacts',
        title: 'Kontakty'
      },
      {
        to: '/services',
        title: 'Služby'
      },
      {
        to: '/sites',
        title: 'Stránky'
      },
      {
        to: '/templates',
        title: 'Šablóny'
      },
      {
        to: '/articles',
        title: 'Články'
      },
      {
        to: '/jobs',
        title: 'Pracovné miesta'
      }
    ]
  },
  {
    title: 'Tikety',
    icon: 'mdi-file',
    value: false,
    subitems: [
      {
        to: '/tickets/all',
        title: 'Všetky'
      },
      {
        to: '/tickets/new',
        title: 'Nové'
      },
      {
        to: '/tickets/in-waiting',
        title: 'Čakajúce'
      },
      {
        to: '/tickets/in-progress',
        title: 'Rozpracované'
      },
      {
        to: '/tickets/rejected',
        title: 'Zamietnuté'
      },
      {
        to: '/tickets/closed',
        title: 'Uzavreté'
      }
    ],
  },
  {
    title: 'Firma',
    icon: 'mdi-finance',
    value: false,
    subitems: [
      {
        to: '/statistics',
        title: 'Sumár'
      },
      {
        to: '/report-statistics',
        title: 'Financie'
      },
      {
        to: '/revenues',
        title: 'Príjmy'
      },
      {
        to: '/expenses',
        title: 'Výdavky'
      },
      {
        to: '/bonuses',
        title: 'Bonusy'
      },
      {
        to: '/provisions',
        title: 'Provízie'
      },
      {
        to: '/ticket-item-reports',
        title: 'Vykonané práce'
      },
      {
        to: '/ticket-items',
        title: 'Položky'
      },
      {
        to: '/user-statistics',
        title: 'Výplaty'
      }
    ]
  }
]

const editor = [
  {
    to: '/home',
    title: 'Nástenka',
    icon: 'mdi-home'
  },
  {
    to: '/statutes',
    title: 'Stanovy',
    icon: 'mdi-frequently-asked-questions'
  },
  {
    title: 'Obsah',
    icon: 'mdi-content-copy',
    value: false,
    subitems: [
      {
        to: '/reviews',
        title: 'Recenzie'
      },
      {
        to: '/contacts',
        title: 'Kontakty'
      },
      {
        to: '/services',
        title: 'Služby'
      },
      {
        to: '/sites',
        title: 'Stránky'
      },
      {
        to: '/templates',
        title: 'Šablóny'
      },
      {
        to: '/articles',
        title: 'Články'
      },
      {
        to: '/jobs',
        title: 'Pracovné miesta'
      }
    ]
  },
  {
    title: 'Tikety',
    icon: 'mdi-file',
    value: false,
    subitems: [
      {
        to: '/tickets/all',
        title: 'Všetky'
      },
      {
        to: '/tickets/new',
        title: 'Nové'
      },
      {
        to: '/tickets/in-waiting',
        title: 'Čakajúce'
      },
      {
        to: '/tickets/in-progress',
        title: 'Rozpracované'
      },
      {
        to: '/tickets/rejected',
        title: 'Zamietnuté'
      },
      {
        to: '/tickets/closed',
        title: 'Uzavreté'
      }
    ],
  }
]

const manager = [
  {
    to: '/home',
    title: 'Nástenka',
    icon: 'mdi-home'
  },
  {
    to: '/statutes',
    title: 'Stanovy',
    icon: 'mdi-frequently-asked-questions'
  },
  {
    title: 'Tikety',
    icon: 'mdi-file',
    value: false,
    subitems: [
      {
        to: '/tickets/all',
        title: 'Všetky'
      },
      {
        to: '/tickets/new',
        title: 'Nové'
      },
      {
        to: '/tickets/in-waiting',
        title: 'Čakajúce'
      },
      {
        to: '/tickets/in-progress',
        title: 'Rozpracované'
      },
      {
        to: '/tickets/rejected',
        title: 'Zamietnuté'
      },
      {
        to: '/tickets/closed',
        title: 'Uzavreté'
      }
    ]
  }
]

const worker = [
  {
    to: '/home',
    title: 'Nástenka',
    icon: 'mdi-home'
  },
  {
    to: '/statutes',
    title: 'Stanovy',
    icon: 'mdi-frequently-asked-questions'
  }
]

const items = {
  master,
  editor,
  manager,
  worker
}

const menu = {
  items
}

export default menu
