<template>
  <v-card
      class="grey lighten-3"
  >
    <v-card-title>
      Zoznam ({{ count }})

      <v-spacer/>

      <template
          v-if="TicketItem.ticketItemReportCreatable"
      >
        <CreateAuth
            :icon="true"
            :TicketItem="TicketItem"
            @refresh="refresh"
            @init="init"
        />

        &nbsp;
      </template>

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-expansion-panels
              v-model="expansionPanelsFilter"
              :multiple="true"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>
                  <v-icon
                      color="info"
                  >
                    mdi-filter
                  </v-icon>

                  Filtre
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.workedAtFrom"
                        :error-messages="errors.Filters && errors.Filters.workedAtFrom"
                        :outlined="true"
                        :dense="true"
                        label="Dátum vykonania od"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.workedAtTo"
                        :error-messages="errors.Filters && errors.Filters.workedAtTo"
                        :outlined="true"
                        :dense="true"
                        label="Dátum vykonania do"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-card>
            <v-card-text>
              <v-progress-linear
                  v-if="loading"
                  :indeterminate="true"
              />

              <v-simple-table>
                <template
                    v-slot:default
                >
                  <thead>
                    <tr>
                      <th
                          class="fixed"
                      >
                        <a
                            @click="setOrder($store.state.register.TicketItemReport.Filter.order['workedAt'].value)"
                        >
                          <template
                              v-if="params.order === $store.state.register.TicketItemReport.Filter.order['workedAt'].value"
                          >
                            <template
                                v-if="params.by === $store.state.register.TicketItemReport.Filter.by['ASC'].value"
                            >
                              <v-icon>
                                mdi-chevron-up
                              </v-icon>
                            </template>

                            <template
                                v-else
                            >
                              <v-icon>
                                mdi-chevron-down
                              </v-icon>
                            </template>
                          </template>

                          Dátum vykonania
                        </a>
                      </th>

                      <th>
                        Čas (h)
                      </th>

                      <th>
                        Plat (€ / h)
                      </th>

                      <th
                          class="yellow lighten-3"
                      >
                        Celkovo (€)
                      </th>

                      <th
                          class="text-right fixed"
                      />
                    </tr>
                  </thead>

                  <tbody>
                    <template
                        v-if="ticketItemReports.length > 0"
                    >
                      <tr
                          v-for="TicketItemReport in ticketItemReports"
                          :key="TicketItemReport.id"
                      >
                        <td
                            class="fixed"
                        >
                          {{ new Date(TicketItemReport.workedAt)|dateFormat('DD.MM.YYYY') }}
                        </td>

                        <td>
                          {{ TicketItemReport.timeSpend }}
                        </td>

                        <td>
                          {{ TicketItemReport.priceHour }}
                        </td>

                        <td
                            class="yellow lighten-3"
                        >
                          {{ TicketItemReport.priceSpend }}
                        </td>

                        <td
                            class="text-right fixed"
                        >
                          <v-tooltip
                              v-if="TicketItemReport.content"
                              :left="true"
                          >
                            <template
                                v-slot:activator="{
                                  on,
                                  attrs
                                }"
                            >
                              <v-btn
                                  :icon="true"
                                  :outlined="true"
                                  color="default"
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <v-icon>
                                  mdi-information-variant
                                </v-icon>
                              </v-btn>
                            </template>

                            {{ TicketItemReport.content }}
                          </v-tooltip>

                          <template
                              v-if="TicketItemReport.updatable || TicketItemReport.deletable"
                          >
                            &nbsp;
                          </template>

                          <template
                              v-if="TicketItemReport.updatable"
                          >
                            <UpdateAuth
                                :icon="true"
                                :TicketItem="TicketItem"
                                :id="TicketItemReport.id"
                                @refresh="refresh"
                                @init="init"
                            />

                            <template
                                v-if="TicketItemReport.deletable"
                            >
                              &nbsp;
                            </template>
                          </template>

                          <template
                              v-if="TicketItemReport.deletable"
                          >
                            <DeleteAuth
                                v-if="TicketItemReport.deletable"
                                :icon="true"
                                :TicketItem="TicketItem"
                                :id="TicketItemReport.id"
                                @refresh="refresh"
                                @init="init"
                            />
                          </template>
                        </td>
                      </tr>
                    </template>

                    <template
                        v-else
                    >
                      <tr>
                        <td
                            :colspan="5"
                            class="text-center"
                        >
                          Nenašli sa žiadne vykonané práce.
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
          v-if="pages !== 0"
          align="center"
      >
        <v-col
            cols="4"
        >
          <a
              v-for="(limit, i) in $store.getters['register/getTicketItemReportFilterLimit']"
              :key="i"
              @click="setLimit(limit.value)"
          >
            {{ limit.text }}
          </a>
        </v-col>

        <v-spacer/>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === 1 || loading"
              color="primary"
              @click="previousPage"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-col>

        <v-col
            cols="auto"
        >
          {{ params.page }} / {{ pages }}
        </v-col>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === pages || loading"
              color="primary"
              @click="nextPage"
          >
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import TicketItemReports from '@/services/ticket-item-reports'

  import CreateAuth from '@/components/TicketItemAuth/TicketItemReportsAuth/CreateAuth'
  import UpdateAuth from '@/components/TicketItemAuth/TicketItemReportsAuth/UpdateAuth'
  import DeleteAuth from '@/components/TicketItemAuth/TicketItemReportsAuth/DeleteAuth'

  export default {
    name: 'TicketItemReportsAuth',
    props: [
      'TicketItem'
    ],
    components: {
      CreateAuth,
      UpdateAuth,
      DeleteAuth
    },
    data() {
      return {
        expansionPanelsFilter: [],
        loading: false,
        count: 0,
        pages: 1,
        ticketItemReports: [],
        params: {
          Filters: {
            workedAtFrom: '',
            workedAtTo: ''
          },
          order: this.$store.state.register.TicketItemReport.Filter.order['workedAt'].value,
          by: this.$store.state.register.TicketItemReport.Filter.by['DESC'].value,
          limit: this.$store.state.register.TicketItemReport.Filter.limit[25].value,
          page: 1
        },
        errors: {}
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.loadData()
      },
      refresh() {
        setTimeout(() => {
          this.$emit('refresh')
        }, 1000)
      },
      setFilter() {
        this.params.page = 1

        this.init()
      },
      setOrder(order) {
        this.params.order = order

        switch (this.params.by) {
          case this.$store.state.register.TicketItemReport.Filter.by['ASC'].value:
            this.params.by = this.$store.state.register.TicketItemReport.Filter.by['DESC'].value

            break

          case this.$store.state.register.TicketItemReport.Filter.by['DESC'].value:
            this.params.by = this.$store.state.register.TicketItemReport.Filter.by['ASC'].value

            break
        }

        this.params.page = 1

        this.init()
      },
      setLimit(limit) {
        this.params.limit = limit

        this.params.page = 1

        this.init()
      },
      previousPage() {
        this.params.page -= 1

        window.scrollTo(0, 0)

        this.init()
      },
      nextPage() {
        this.params.page += 1

        window.scrollTo(0, 0)

        this.init()
      },
      loadData() {
        this.loading = true

        TicketItemReports
          .indexTicketItemAuth({
            ticketItemId: this.TicketItem.id,
            params: this.params
          })
          .then((response) => {
            this.count = response.data.data.count

            this.pages = response.data.data.pages

            this.ticketItemReports = response.data.data.ticketItemReports

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
