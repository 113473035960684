import Vue from 'vue'

import Qs from 'qs'

class TicketItemReports {
  async index({params}) {
    return await Vue.axios
      .get('admin/ticket-item-reports', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async indexTicketItem({ticketItemId, params}) {
    return await Vue.axios
      .get('admin/ticket-item-reports-by-ticket-item/' + ticketItemId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async indexTicketItemAuth({ticketItemId, params}) {
    return await Vue.axios
      .get('admin/ticket-item-reports-by-ticket-item-and-auth/' + ticketItemId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async createTicketItemAuth({ticketItemId, data}) {
    return await Vue.axios
      .post('admin/ticket-item-reports-by-ticket-item-and-auth/' + ticketItemId, data)
  }

  async read({id}) {
    return await Vue.axios
      .get('admin/ticket-item-reports/' + id)
  }

  async readAuth({id}) {
    return await Vue.axios
      .get('admin/ticket-item-reports-by-auth/' + id)
  }

  async updateAuth({id, data}) {
    return await Vue.axios
      .put('admin/ticket-item-reports-by-auth/' + id, data)
  }

  async updateStatistic({id, data}) {
    return await Vue.axios
      .put('admin/ticket-item-reports-by-statistic/' + id, data)
  }

  async deleteAuth({id}) {
    return await Vue.axios
      .delete('admin/ticket-item-reports-by-auth/' + id)
  }
}

const ticketItemReports = new TicketItemReports()

export default ticketItemReports
