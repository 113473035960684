import Register from '@/services/register'

const state = {
  Account: {
    billingCountry: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Article: {
    type: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Bonus: {
    status: {},
    pay: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Client: {
    billingCountry: {},
    status: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Contact: {
    type: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Expense: {
    pay: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Image: {
    Resource: {
      type: {}
    }
  },
  Job: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Project: {
    type: {},
    healthResult: {},
    status: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Provision: {
    status: {},
    pay: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Revenue: {
    pay: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Review: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Service: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Site: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Tariff: {
    unit: {},
    frequency: {},
    type: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Template: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Ticket: {
    type: {},
    pricelistStatus: {},
    statusItems: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  TicketItem: {
    type: {},
    status: {},
    tariffUnit: {},
    tariffFrequency: {},
    tariffType: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  TicketItemOffer: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  TicketItemReport: {
    status: {},
    pay: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  User: {
    type: {},
    division: {},
    status: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  UserStatistic: {
    status: {}
  }
}

const mutations = {
  SET_ACCOUNT(state, Account) {
    state.Account = Account
  },
  SET_ARTICLE(state, Article) {
    state.Article = Article
  },
  SET_BONUS(state, Bonus) {
    state.Bonus = Bonus
  },
  SET_CLIENT(state, Client) {
    state.Client = Client
  },
  SET_CONTACT(state, Contact) {
    state.Contact = Contact
  },
  SET_EXPENSE(state, Expense) {
    state.Expense = Expense
  },
  SET_IMAGE(state, Image) {
    state.Image = Image
  },
  SET_JOB(state, Job) {
    state.Job = Job
  },
  SET_PROJECT(state, Project) {
    state.Project = Project
  },
  SET_PROVISION(state, Provision) {
    state.Provision = Provision
  },
  SET_REVENUE(state, Revenue) {
    state.Revenue = Revenue
  },
  SET_REVIEW(state, Review) {
    state.Review = Review
  },
  SET_SERVICE(state, Service) {
    state.Service = Service
  },
  SET_SITE(state, Site) {
    state.Site = Site
  },
  SET_TARIFF(state, Tariff) {
    state.Tariff = Tariff
  },
  SET_TEMPLATE(state, Template) {
    state.Template = Template
  },
  SET_TICKET(state, Ticket) {
    state.Ticket = Ticket
  },
  SET_TICKET_ITEM(state, TicketItem) {
    state.TicketItem = TicketItem
  },
  SET_TICKET_ITEM_OFFER(state, TicketItemOffer) {
    state.TicketItemOffer = TicketItemOffer
  },
  SET_TICKET_ITEM_REPORT(state, TicketItemReport) {
    state.TicketItemReport = TicketItemReport
  },
  SET_USER(state, User) {
    state.User = User
  },
  SET_USER_STATISTIC(state, UserStatistic) {
    state.UserStatistic = UserStatistic
  }
}

const actions = {
  async callIndex({commit}) {
    return await Register
      .index()
      .then((response) => {
        commit('SET_ACCOUNT', response.data.data.Account)

        commit('SET_ARTICLE', response.data.data.Article)

        commit('SET_BONUS', response.data.data.Bonus)

        commit('SET_CLIENT', response.data.data.Client)

        commit('SET_CONTACT', response.data.data.Contact)

        commit('SET_EXPENSE', response.data.data.Expense)

        commit('SET_IMAGE', response.data.data.Image)

        commit('SET_JOB', response.data.data.Job)

        commit('SET_PROJECT', response.data.data.Project)

        commit('SET_PROVISION', response.data.data.Provision)

        commit('SET_REVENUE', response.data.data.Revenue)

        commit('SET_REVIEW', response.data.data.Review)

        commit('SET_SERVICE', response.data.data.Service)

        commit('SET_SITE', response.data.data.Site)

        commit('SET_TARIFF', response.data.data.Tariff)

        commit('SET_TEMPLATE', response.data.data.Template)

        commit('SET_TICKET', response.data.data.Ticket)

        commit('SET_TICKET_ITEM', response.data.data.TicketItem)

        commit('SET_TICKET_ITEM_OFFER', response.data.data.TicketItemOffer)

        commit('SET_TICKET_ITEM_REPORT', response.data.data.TicketItemReport)

        commit('SET_USER', response.data.data.User)

        commit('SET_USER_STATISTIC', response.data.data.UserStatistic)
      })
      .catch(() => {
        commit('SET_ACCOUNT', {
          billingCountry: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_ARTICLE', {
          type: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_BONUS', {
          status: {},
          pay: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_CLIENT', {
          billingCountry: {},
          status: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_CONTACT', {
          type: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_EXPENSE', {
          pay: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_IMAGE', {
          Resource: {
            type: {}
          }
        })

        commit('SET_JOB', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_PROJECT', {
          type: {},
          healthResult: {},
          status: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_PROVISION', {
          status: {},
          pay: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_REVENUE', {
          pay: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_REVIEW', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_SERVICE', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_SITE', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_TARIFF', {
          unit: {},
          frequency: {},
          type: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_TEMPLATE', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_TICKET', {
          type: {},
          pricelistStatus: {},
          statusItems: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_TICKET_ITEM', {
          type: {},
          status: {},
          tariffUnit: {},
          tariffFrequency: {},
          tariffType: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_TICKET_ITEM_OFFER', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_TICKET_ITEM_REPORT', {
          status: {},
          pay: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_USER', {
          type: {},
          division: {},
          status: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_USER_STATISTIC', {
          status: {}
        })
      })
  }
}

const getters = {
  getAccountBillingCountry(state) {
    return Object.values(state.Account.billingCountry)
  },
  getAccountFilterOrder(state) {
    return Object.values(state.Account.Filter.order)
  },
  getAccountFilterBy(state) {
    return Object.values(state.Account.Filter.by)
  },
  getAccountFilterLimit(state) {
    return Object.values(state.Account.Filter.limit)
  },
  getArticleType(state) {
    return Object.values(state.Article.type)
  },
  getArticleFilterOrder(state) {
    return Object.values(state.Article.Filter.order)
  },
  getArticleFilterBy(state) {
    return Object.values(state.Article.Filter.by)
  },
  getArticleFilterLimit(state) {
    return Object.values(state.Article.Filter.limit)
  },
  getBonusStatus(state) {
    return Object.values(state.Bonus.status)
  },
  getBonusPay(state) {
    return Object.values(state.Bonus.pay)
  },
  getBonusFilterOrder(state) {
    return Object.values(state.Bonus.Filter.order)
  },
  getBonusFilterBy(state) {
    return Object.values(state.Bonus.Filter.by)
  },
  getBonusFilterLimit(state) {
    return Object.values(state.Bonus.Filter.limit)
  },
  getClientBillingCountry(state) {
    return Object.values(state.Client.billingCountry)
  },
  getClientStatus(state) {
    return Object.values(state.Client.status)
  },
  getClientFilterOrder(state) {
    return Object.values(state.Client.Filter.order)
  },
  getClientFilterBy(state) {
    return Object.values(state.Client.Filter.by)
  },
  getClientFilterLimit(state) {
    return Object.values(state.Client.Filter.limit)
  },
  getContactType(state) {
    return Object.values(state.Contact.type)
  },
  getContactFilterOrder(state) {
    return Object.values(state.Contact.Filter.order)
  },
  getContactFilterBy(state) {
    return Object.values(state.Contact.Filter.by)
  },
  getContactFilterLimit(state) {
    return Object.values(state.Contact.Filter.limit)
  },
  getExpensePay(state) {
    return Object.values(state.Expense.pay)
  },
  getExpenseFilterOrder(state) {
    return Object.values(state.Expense.Filter.order)
  },
  getExpenseFilterBy(state) {
    return Object.values(state.Expense.Filter.by)
  },
  getExpenseFilterLimit(state) {
    return Object.values(state.Expense.Filter.limit)
  },
  getImageResourceType(state) {
    return Object.values(state.Image.Resource.type)
  },
  getJobFilterOrder(state) {
    return Object.values(state.Job.Filter.order)
  },
  getJobFilterBy(state) {
    return Object.values(state.Job.Filter.by)
  },
  getJobFilterLimit(state) {
    return Object.values(state.Job.Filter.limit)
  },
  getProjectType(state) {
    return Object.values(state.Project.type)
  },
  getProjectHealthResult(state) {
    return Object.values(state.Project.healthResult)
  },
  getProjectStatus(state) {
    return Object.values(state.Project.status)
  },
  getProjectFilterOrder(state) {
    return Object.values(state.Project.Filter.order)
  },
  getProjectFilterBy(state) {
    return Object.values(state.Project.Filter.by)
  },
  getProjectFilterLimit(state) {
    return Object.values(state.Project.Filter.limit)
  },
  getProvisionStatus(state) {
    return Object.values(state.Provision.status)
  },
  getProvisionPay(state) {
    return Object.values(state.Provision.pay)
  },
  getProvisionFilterOrder(state) {
    return Object.values(state.Provision.Filter.order)
  },
  getProvisionFilterBy(state) {
    return Object.values(state.Provision.Filter.by)
  },
  getProvisionFilterLimit(state) {
    return Object.values(state.Provision.Filter.limit)
  },
  getRevenuePay(state) {
    return Object.values(state.Revenue.pay)
  },
  getRevenueFilterOrder(state) {
    return Object.values(state.Revenue.Filter.order)
  },
  getRevenueFilterBy(state) {
    return Object.values(state.Revenue.Filter.by)
  },
  getRevenueFilterLimit(state) {
    return Object.values(state.Revenue.Filter.limit)
  },
  getReviewFilterOrder(state) {
    return Object.values(state.Review.Filter.order)
  },
  getReviewFilterBy(state) {
    return Object.values(state.Review.Filter.by)
  },
  getReviewFilterLimit(state) {
    return Object.values(state.Review.Filter.limit)
  },
  getServiceFilterOrder(state) {
    return Object.values(state.Service.Filter.order)
  },
  getServiceFilterBy(state) {
    return Object.values(state.Service.Filter.by)
  },
  getServiceFilterLimit(state) {
    return Object.values(state.Service.Filter.limit)
  },
  getSiteFilterOrder(state) {
    return Object.values(state.Site.Filter.order)
  },
  getSiteFilterBy(state) {
    return Object.values(state.Site.Filter.by)
  },
  getSiteFilterLimit(state) {
    return Object.values(state.Site.Filter.limit)
  },
  getTariffUnit(state) {
    return Object.values(state.Tariff.unit)
  },
  getTariffFrequency(state) {
    return Object.values(state.Tariff.frequency)
  },
  getTariffType(state) {
    return Object.values(state.Tariff.type)
  },
  getTariffFilterOrder(state) {
    return Object.values(state.Tariff.Filter.order)
  },
  getTariffFilterBy(state) {
    return Object.values(state.Tariff.Filter.by)
  },
  getTariffFilterLimit(state) {
    return Object.values(state.Tariff.Filter.limit)
  },
  getTemplateFilterOrder(state) {
    return Object.values(state.Template.Filter.order)
  },
  getTemplateFilterBy(state) {
    return Object.values(state.Template.Filter.by)
  },
  getTemplateFilterLimit(state) {
    return Object.values(state.Template.Filter.limit)
  },
  getTicketType(state) {
    return Object.values(state.Ticket.type)
  },
  getTicketPricelistStatus(state) {
    return Object.values(state.Ticket.pricelistStatus)
  },
  getTicketStatusItems(state) {
    return Object.values(state.Ticket.statusItems)
  },
  getTicketFilterOrder(state) {
    return Object.values(state.Ticket.Filter.order)
  },
  getTicketFilterBy(state) {
    return Object.values(state.Ticket.Filter.by)
  },
  getTicketFilterLimit(state) {
    return Object.values(state.Ticket.Filter.limit)
  },
  getTicketItemType(state) {
    return Object.values(state.TicketItem.type)
  },
  getTicketItemStatus(state) {
    return Object.values(state.TicketItem.status)
  },
  getTicketItemTariffUnit(state) {
    return Object.values(state.TicketItem.tariffUnit)
  },
  getTicketItemTariffFrequency(state) {
    return Object.values(state.TicketItem.tariffFrequency)
  },
  getTicketItemTariffType(state) {
    return Object.values(state.TicketItem.tariffType)
  },
  getTicketItemFilterOrder(state) {
    return Object.values(state.TicketItem.Filter.order)
  },
  getTicketItemFilterBy(state) {
    return Object.values(state.TicketItem.Filter.by)
  },
  getTicketItemFilterLimit(state) {
    return Object.values(state.TicketItem.Filter.limit)
  },
  getTicketItemOfferFilterOrder(state) {
    return Object.values(state.TicketItemOffer.Filter.order)
  },
  getTicketItemOfferFilterBy(state) {
    return Object.values(state.TicketItemOffer.Filter.by)
  },
  getTicketItemOfferFilterLimit(state) {
    return Object.values(state.TicketItemOffer.Filter.limit)
  },
  getTicketItemReportStatus(state) {
    return Object.values(state.TicketItemReport.status)
  },
  getTicketItemReportPay(state) {
    return Object.values(state.TicketItemReport.pay)
  },
  getTicketItemReportFilterOrder(state) {
    return Object.values(state.TicketItemReport.Filter.order)
  },
  getTicketItemReportFilterBy(state) {
    return Object.values(state.TicketItemReport.Filter.by)
  },
  getTicketItemReportFilterLimit(state) {
    return Object.values(state.TicketItemReport.Filter.limit)
  },
  getUserType(state) {
    return Object.values(state.User.type)
  },
  getUserDivision(state) {
    return Object.values(state.User.division)
  },
  getUserStatus(state) {
    return Object.values(state.User.status)
  },
  getUserFilterOrder(state) {
    return Object.values(state.User.Filter.order)
  },
  getUserFilterBy(state) {
    return Object.values(state.User.Filter.by)
  },
  getUserFilterLimit(state) {
    return Object.values(state.User.Filter.limit)
  },
  getUserStatisticStatus(state) {
    return Object.values(state.UserStatistic.status)
  }
}

const register = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export default register
