import Vue from 'vue'

class Statutes {
  async all() {
    return await Vue.axios
      .get('admin/statutes/all')
  }

  async create({data}) {
    return await Vue.axios
      .post('admin/statutes', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('admin/statutes/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('admin/statutes/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('admin/statutes/' + id)
  }
}

const statutes = new Statutes()

export default statutes
