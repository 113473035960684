<template>
  <v-card
      class="grey lighten-3"
  >
    <v-card-title>
      Zoznam ({{ count }})

      <v-spacer/>

      <template
          v-if="Ticket.ticketItemCreatable"
      >
        <Create
            :icon="true"
            :Ticket="Ticket"
            @refresh="refresh"
            @init="init"
        />

        &nbsp;
      </template>

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-tabs
              v-model="tabsStatus"
              color="secondary"
          >
            <v-tab
                @click="setStatus('')"
            >
              Všetko
            </v-tab>

            <v-tab
                v-for="(status, i) in $store.getters['register/getTicketItemStatus']"
                :key="i"
                :class="status.class"
                @click="setStatus(status.value)"
            >
              {{ status.text }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-expansion-panels
              v-model="expansionPanelsFilter"
              :multiple="true"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>
                  <v-icon
                      color="info"
                  >
                    mdi-filter
                  </v-icon>

                  Filtre
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.createdAtFrom"
                        :error-messages="errors.Filters && errors.Filters.createdAtFrom"
                        :outlined="true"
                        :dense="true"
                        label="Dátum vytvorenia od"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.createdAtTo"
                        :error-messages="errors.Filters && errors.Filters.createdAtTo"
                        :outlined="true"
                        :dense="true"
                        label="Dátum vytvorenia do"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.title"
                        :error-messages="errors.Filters && errors.Filters.title"
                        :outlined="true"
                        :dense="true"
                        label="Názov"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <SuggestUsers
                        v-model="params.Filters.User"
                        :error-messages="errors.Filters && errors.Filters.User"
                        :outlined="true"
                        :dense="true"
                        label="Pracovník"
                        :clearable="true"
                        :createButton="false"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <SuggestTariffs
                        v-model="params.Filters.Tariff"
                        :error-messages="errors.Filters && errors.Filters.Tariff"
                        :outlined="true"
                        :dense="true"
                        label="Tarifa"
                        :clearable="true"
                        :createButton="false"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.deadlineAtFrom"
                        :error-messages="errors.Filters && errors.Filters.deadlineAtFrom"
                        :outlined="true"
                        :dense="true"
                        label="Dátum dokončenia od"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.deadlineAtTo"
                        :error-messages="errors.Filters && errors.Filters.deadlineAtTo"
                        :outlined="true"
                        :dense="true"
                        label="Dátum dokončenia do"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-card>
            <v-card-text>
              <v-progress-linear
                  v-if="loading"
                  :indeterminate="true"
              />

              <v-simple-table>
                <template
                    v-slot:default
                >
                  <thead>
                    <tr>
                      <th>
                        <a
                            @click="setOrder($store.state.register.TicketItem.Filter.order['createdAt'].value)"
                        >
                          <template
                              v-if="params.order === $store.state.register.TicketItem.Filter.order['createdAt'].value"
                          >
                            <template
                                v-if="params.by === $store.state.register.TicketItem.Filter.by['ASC'].value"
                            >
                              <v-icon>
                                mdi-chevron-up
                              </v-icon>
                            </template>

                            <template
                                v-else
                            >
                              <v-icon>
                                mdi-chevron-down
                              </v-icon>
                            </template>
                          </template>

                          Dátum vytvorenia
                        </a>
                      </th>

                      <th
                          class="fixed"
                      >
                        Názov
                      </th>

                      <th>
                        Pracovník
                      </th>

                      <th>
                        Tarifa
                      </th>

                      <th>
                        Stav
                      </th>

                      <th>
                        <a
                            @click="setOrder($store.state.register.TicketItem.Filter.order['deadlineAt'].value)"
                        >
                          <template
                              v-if="params.order === $store.state.register.TicketItem.Filter.order['deadlineAt'].value"
                          >
                            <template
                                v-if="params.by === $store.state.register.TicketItem.Filter.by['ASC'].value"
                            >
                              <v-icon>
                                mdi-chevron-up
                              </v-icon>
                            </template>

                            <template
                                v-else
                            >
                              <v-icon>
                                mdi-chevron-down
                              </v-icon>
                            </template>
                          </template>

                          Dátum dokončenia
                        </a>
                      </th>

                      <th
                          class="yellow lighten-3"
                      >
                        Vykonané práce (h)
                      </th>

                      <th
                          class="yellow lighten-3"
                      >
                        Vykonané práce (€)
                      </th>

                      <th
                          v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                          class="yellow lighten-3"
                      >
                        Fakturácia (€)
                      </th>

                      <th
                          class="text-right fixed"
                      />
                    </tr>
                  </thead>

                  <tbody>
                    <template
                        v-if="ticketItems.length > 0"
                    >
                      <tr
                          v-for="TicketItem in ticketItems"
                          :key="TicketItem.id"
                          :class="$store.state.register.TicketItem.status[TicketItem.status].class"
                      >
                        <td>
                          {{ new Date(TicketItem.createdAt)|dateFormat('DD.MM.YYYY') }}
                        </td>

                        <td
                            class="fixed"
                        >
                          <span
                              class="text-truncate"
                              style="max-width: 300px"
                          >
                            {{ TicketItem.title }}
                          </span>
                        </td>

                        <td>
                          <span
                              class="text-truncate"
                              style="max-width: 200px"
                          >
                            <template
                                v-if="TicketItem.User"
                            >
                              {{ TicketItem.User.name }}
                            </template>

                            <template
                                v-else
                            >
                              Nevyplnené
                            </template>
                          </span>
                        </td>

                        <td>
                          <span
                              class="text-truncate"
                              style="max-width: 200px"
                          >
                            {{ TicketItem.currentTariffTitle }}
                          </span>
                        </td>

                        <td>
                          {{ $store.state.register.TicketItem.status[TicketItem.status].text }}
                        </td>

                        <td>
                          <template
                              v-if="TicketItem.deadlineAt"
                          >
                            {{ new Date(TicketItem.deadlineAt)|dateFormat('DD.MM.YYYY') }}
                          </template>

                          <template
                              v-else
                          >
                            Nevyplnené
                          </template>
                        </td>

                        <td
                            class="yellow lighten-3"
                        >
                          {{ TicketItem.timeSpendReports }}
                        </td>

                        <td
                            class="yellow lighten-3"
                        >
                          {{ TicketItem.priceSpendReports }}
                        </td>

                        <td
                            v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                            class="yellow lighten-3"
                        >
                          {{ TicketItem.invoiceSubtotal }}
                        </td>

                        <td
                            class="text-right fixed"
                        >
                          <v-btn
                              :icon="true"
                              :outlined="true"
                              color="primary"
                              :to="'/ticket-item/' + TicketItem.id"
                          >
                            <v-icon>
                              mdi-magnify
                            </v-icon>
                          </v-btn>

                          <template
                              v-if="TicketItem.deletable"
                          >
                            &nbsp;

                            <Delete
                                :icon="true"
                                :Ticket="Ticket"
                                :id="TicketItem.id"
                                @refresh="refresh"
                                @init="init"
                            />
                          </template>
                        </td>
                      </tr>
                    </template>

                    <template
                        v-else
                    >
                      <tr>
                        <td
                            :colspan="
                              ['ROLE_MASTER'].includes($store.state.auth.User.role)
                                ? 10
                                : 9
                            "
                            class="text-center"
                        >
                          Nenašli sa žiadne položky.
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
          v-if="pages !== 0"
          align="center"
      >
        <v-col
            cols="4"
        >
          <a
              v-for="(limit, i) in $store.getters['register/getTicketItemFilterLimit']"
              :key="i"
              @click="setLimit(limit.value)"
          >
            {{ limit.text }}
          </a>
        </v-col>

        <v-spacer/>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === 1 || loading"
              color="primary"
              @click="previousPage"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-col>

        <v-col
            cols="auto"
        >
          {{ params.page }} / {{ pages }}
        </v-col>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === pages || loading"
              color="primary"
              @click="nextPage"
          >
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import TicketItems from '@/services/ticket-items'

  import Create from '@/components/Ticket/TicketItems/Create'
  import Delete from '@/components/Ticket/TicketItems/Delete'

  import SuggestTariffs from '@/components/Tariffs/Suggest'
  import SuggestUsers from '@/components/Users/Suggest'

  export default {
    name: 'TicketItemsReport',
    props: [
      'Ticket'
    ],
    components: {
      Create,
      Delete,
      SuggestUsers,
      SuggestTariffs
    },
    data() {
      return {
        tabsStatus: 0,
        expansionPanelsFilter: [],
        loading: false,
        count: 0,
        pages: 1,
        ticketItems: [],
        params: {
          Filters: {
            createdAtFrom: '',
            createdAtTo: '',
            title: '',
            User: '',
            Tariff: '',
            status: '',
            deadlineAtFrom: '',
            deadlineAtTo: ''
          },
          order: this.$store.state.register.TicketItem.Filter.order['createdAt'].value,
          by: this.$store.state.register.TicketItem.Filter.by['DESC'].value,
          limit: this.$store.state.register.TicketItem.Filter.limit[25].value,
          page: 1
        },
        errors: {}
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.loadData()
      },
      refresh() {
        setTimeout(() => {
          this.$emit('refresh')
        }, 1000)
      },
      setStatus(status) {
        this.params.Filters.status = status

        this.params.page = 1

        this.init()
      },
      setFilter() {
        this.params.page = 1

        this.init()
      },
      setOrder(order) {
        this.params.order = order

        switch (this.params.by) {
          case this.$store.state.register.TicketItem.Filter.by['ASC'].value:
            this.params.by = this.$store.state.register.TicketItem.Filter.by['DESC'].value

            break

          case this.$store.state.register.TicketItem.Filter.by['DESC'].value:
            this.params.by = this.$store.state.register.TicketItem.Filter.by['ASC'].value

            break
        }

        this.params.page = 1

        this.init()
      },
      setLimit(limit) {
        this.params.limit = limit

        this.params.page = 1

        this.init()
      },
      previousPage() {
        this.params.page -= 1

        window.scrollTo(0, 0)

        this.init()
      },
      nextPage() {
        this.params.page += 1

        window.scrollTo(0, 0)

        this.init()
      },
      loadData() {
        this.loading = true

        TicketItems
          .indexTicket({
            ticketId: this.Ticket.id,
            params: this.params
          })
          .then((response) => {
            this.count = response.data.data.count

            this.pages = response.data.data.pages

            this.ticketItems = response.data.data.ticketItems

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
