<template>
  <v-card>
    <v-card-title>
      Financie

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-progress-linear
              v-if="loading"
              :indeterminate="true"
          />

          <v-row>
            <v-col
                cols="12"
                lg="10"
            >
              <SuggestAccounts
                  v-model="Account"
                  :outlined="true"
                  :dense="true"
                  label="Účet"
                  :createButton="false"
                  @change="setAccount"
              />
            </v-col>

            <v-col
                cols="12"
                lg="2"
            >
              <v-text-field
                  v-model="reportedAtYear"
                  :outlined="true"
                  :dense="true"
                  label="Rok uzávierky"
                  @change="setReportedAtYear"
              />
            </v-col>
          </v-row>

          <template
              v-if="Account && reportedAtYear"
          >
            <v-row>
              <v-col
                  cols="12"
              >
                <highcharts
                    :options="totalSpline"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
              >
                <highcharts
                    :options="plusMinusColumn"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
              >
                <highcharts
                    :options="bilanceColumn"
                />
              </v-col>
            </v-row>
          </template>

          <v-alert
              v-else
              type="warning"
              class="mb-0"
          >
            Pre pokračovanie vo financiách je potrebné vybrať účet a rok uzávierky.
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { dateFormat } from '@vuejs-community/vue-filter-date-format'

  import ReportStatistics from '@/services/report-statistics'

  import SuggestAccounts from '@/components/Accounts/Suggest'

  export default {
    name: 'ReportStatistics',
    components: {
      SuggestAccounts
    },
    data() {
      return {
        loading: false,
        Account: '',
        reportedAtYear: dateFormat(new Date(), 'YYYY'),
        reportStatistics: []
      }
    },
    metaInfo() {
      return {
        title: 'Financie'
      }
    },
    mounted() {
      this.init()
    },
    computed: {
      totalSpline() {
        return {
          title: {
            text: 'Vyjadruje vývoj firmy'
          },
          credits: false,
          xAxis: {
            title: {
              text: 'Mesiac uzávierky'
            },
            categories: this.reportStatistics.filter((ReportStatistic) => ReportStatistic.reportedAtMonth).map((ReportStatistic) => ReportStatistic.reportedAtMonth)
          },
          yAxis: {
            title: {
              text: 'Suma (€)'
            }
          },
          plotOptions: {
            spline: {
              dataLabels: {
                enabled: true,
                format: '{point.y:.2f} €'
              },
              showInLegend: true
            }
          },
          series: [
            {
              type: 'spline',
              name: 'Plusy',
              color: '#1b7f0e',
              data: this.reportStatistics.filter((ReportStatistic) => ReportStatistic.reportedAtMonth).map((ReportStatistic) => ReportStatistic.pricePlusCumulative)
            },
            {
              type: 'spline',
              name: 'Mínusy',
              color: '#7f0e0e',
              data: this.reportStatistics.filter((ReportStatistic) => ReportStatistic.reportedAtMonth).map((ReportStatistic) => ReportStatistic.priceMinusCumulative)
            },
            {
              type: 'spline',
              name: 'Výsledok',
              color: '#0e217f',
              data: this.reportStatistics.filter((ReportStatistic) => ReportStatistic.reportedAtMonth).map((ReportStatistic) => ReportStatistic.priceBalanceCumulative)
            }
          ]
        }
      },
      plusMinusColumn() {
        return {
          title: {
            text: 'Vyjadruje koľko sme mali plusy a mínusy'
          },
          credits: false,
          xAxis: {
            title: {
              text: 'Mesiac uzávierky'
            },
            categories: this.reportStatistics.filter((ReportStatistic) => ReportStatistic.reportedAtMonth).map((ReportStatistic) => ReportStatistic.reportedAtMonth)
          },
          yAxis: {
            title: {
              text: 'Suma (€)'
            }
          },
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: true,
                format: '{point.y:.2f} €'
              },
              showInLegend: true
            }
          },
          series: [
            {
              type: 'column',
              name: 'Plusy - Prevod',
              color: '#4cb343',
              data: this.reportStatistics.filter((ReportStatistic) => ReportStatistic.reportedAtMonth).map((ReportStatistic) => ReportStatistic.pricePlusBank),
              stack: 'plus'
            },
            {
              type: 'column',
              name: 'Plusy - Hotovosť',
              color: '#98ef8f',
              data: this.reportStatistics.filter((ReportStatistic) => ReportStatistic.reportedAtMonth).map((ReportStatistic) => ReportStatistic.pricePlusCash),
              stack: 'plus'
            },
            {
              type: 'column',
              name: 'Mínusy - Prevod',
              color: '#b34343',
              data: this.reportStatistics.filter((ReportStatistic) => ReportStatistic.reportedAtMonth).map((ReportStatistic) => ReportStatistic.priceMinusBank),
              stack: 'minus'
            },
            {
              type: 'column',
              name: 'Mínusy - Hotovosť',
              color: '#ef8f8f',
              data: this.reportStatistics.filter((ReportStatistic) => ReportStatistic.reportedAtMonth).map((ReportStatistic) => ReportStatistic.priceMinusCash),
              stack: 'minus'
            }
          ]
        }
      },
      bilanceColumn() {
        return {
          title: {
            text: 'Vyjadruje koľko sme mali peňazí na účte a v pokladni'
          },
          credits: false,
          xAxis: {
            title: {
              text: 'Mesiac uzávierky'
            },
            categories: this.reportStatistics.filter((ReportStatistic) => ReportStatistic.reportedAtMonth).map((ReportStatistic) => ReportStatistic.reportedAtMonth)
          },
          yAxis: {
            title: {
              text: 'Suma (€)'
            }
          },
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: true,
                format: '{point.y:.2f} €'
              },
              showInLegend: true
            }
          },
          series: [
            {
              type: 'column',
              name: 'Prevod',
              color: '#4352b3',
              data: this.reportStatistics.filter((ReportStatistic) => ReportStatistic.reportedAtMonth).map((ReportStatistic) => ReportStatistic.priceBalanceBankCumulative),
              stack: 'balance'
            },
            {
              type: 'column',
              name: 'Hotovosť',
              color: '#8fa4ef',
              data: this.reportStatistics.filter((ReportStatistic) => ReportStatistic.reportedAtMonth).map((ReportStatistic) => ReportStatistic.priceBalanceCashCumulative),
              stack: 'balance'
            }
          ]
        }
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      setAccount() {
        this.init()
      },
      setReportedAtYear() {
        this.init()
      },
      loadData() {
        if (this.Account && this.reportedAtYear) {
          this.loading = true

          ReportStatistics
            .allAccountReportedAtYear({
              accountId: this.Account,
              reportedAtYear: this.reportedAtYear
            })
            .then((response) => {
              this.reportStatistics = response.data.data.reportStatistics
            })
            .catch((error) => {
              switch (error.response.status) {
                case 401:
                case 404:
                case 500:
                  this.$store.dispatch('layout/openSnackbar', {
                    message: error.response.data.message
                  })

                  break
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
