import Vue from 'vue'

class TicketItemReminds {
  async createTicketItem({ticketItemId, data}) {
    return await Vue.axios
      .post('admin/ticket-item-reminds-by-ticket-item/' + ticketItemId, data)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('admin/ticket-item-reminds/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('admin/ticket-item-reminds/' + id)
  }
}

const ticketItemReminds = new TicketItemReminds()

export default ticketItemReminds
