<template>
  <v-card
      v-if="content"
  >
    <v-card-title>
      <v-icon
          :color="$store.state.register.TicketItem.type[TicketItem.type].color"
      >
        {{ $store.state.register.TicketItem.type[TicketItem.type].icon }}
      </v-icon>

      &nbsp;

      <span
          class="text-truncate"
          style="max-width: 900px"
      >
        {{ TicketItem.title }}
      </span>

      <v-spacer/>

      <template
          v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
      >
        <v-btn
            :icon="true"
            :outlined="true"
            color="secondary"
            :to="'/ticket-item/' + TicketItem.id"
        >
          <v-icon>
            mdi-cogs
          </v-icon>
        </v-btn>

        &nbsp;
      </template>

      <v-btn
          :icon="true"
          :outlined="true"
          color="primary"
          to="/home"
      >
        <v-icon>
          mdi-reply
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle>
      {{ new Date(TicketItem.createdAt)|dateFormat('DD.MM.YYYY') }}
      pre
      <template
          v-if="TicketItem.Ticket.Project"
      >
        {{ TicketItem.Ticket.Project.name }}
      </template>

      <template
          v-else
      >
        <template
            v-if="TicketItem.Ticket.projectName"
        >
          {{ TicketItem.Ticket.projectName }}
        </template>

        <template
            v-else
        >
          Nevyplnené
        </template>
      </template>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-progress-linear
              v-if="loading"
              :indeterminate="true"
          />

          <v-row>
            <v-col
                cols="12"
                lg="4"
            >
              <v-card>
                <v-card-title>
                  Zadanie
                </v-card-title>

                <v-card-text>
                  <template
                      v-if="TicketItem.content"
                  >
                    {{ TicketItem.content }}
                  </template>

                  <template
                      v-else
                  >
                    Nevyplnené
                  </template>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
                cols="12"
                lg="4"
            >
              <v-card>
                <v-card-title>
                  Pripomienky
                </v-card-title>

                <v-card-text>
                  <template
                      v-if="TicketItem.ticketItemReminds.length > 0"
                  >
                    <p
                        v-for="TicketItemRemind in sortedTicketItemReminds"
                        :key="TicketItemRemind.id"
                        :class="
                          TicketItemRemind.strikeOut
                            ? 'text-decoration-line-through'
                            : ''
                        "
                    >
                      {{ new Date(TicketItemRemind.createdAt)|dateFormat('DD.MM.YYYY') }} - {{ TicketItemRemind.content }}
                    </p>
                  </template>

                  <p
                      v-else
                  >
                    Žiadne
                  </p>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
                cols="12"
                lg="4"
            >
              <v-expansion-panels
                  v-model="expansionPanelsSetting"
                  :multiple="true"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Priradenie
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <template
                        v-if="TicketItem.updatable &&TicketItem.updatableThirdStep"
                    >
                      <SuggestUsers
                          v-model="TicketItemForm.User"
                          :error-messages="errors.User"
                          :outlined="true"
                          :dense="true"
                          label="Pracovník"
                          :clearable="true"
                          :createButton="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                          :support="(users) => users.filter((User) => TicketItem.users.map((User) => User.id).includes(User.id))"
                          @change="submit"
                      />

                      <v-row>
                        <v-col
                            cols="6"
                        >
                          <v-select
                              v-model="TicketItemForm.status"
                              :error-messages="errors.status"
                              :outlined="true"
                              :dense="true"
                              label="Stav"
                              :items="$store.getters['register/getTicketItemStatus']"
                              @change="submit"
                          />
                        </v-col>

                        <v-col
                            cols="6"
                        >
                          <v-text-field
                              v-model="TicketItemForm.deadlineAt"
                              :error-messages="errors.deadlineAt"
                              :outlined="true"
                              :dense="true"
                              label="Dátum dokončenia"
                              :clearable="true"
                              @change="submit"
                          />
                        </v-col>
                      </v-row>
                    </template>

                    <template
                        v-else
                    >
                      <v-row>
                        <v-col
                            cols="12"
                        >
                          <p>
                            Pracovník
                          </p>

                          <h3>
                            <template
                                v-if="TicketItem.User"
                            >
                              {{ TicketItem.User.name }}
                            </template>

                            <template
                                v-else
                            >
                              Nevyplnené
                            </template>
                          </h3>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                            cols="6"
                        >
                          <p>
                            Stav
                          </p>

                          <h3>
                            {{ $store.state.register.TicketItem.status[TicketItem.status].text }}
                          </h3>
                        </v-col>

                        <v-col
                            cols="6"
                        >
                          <p>
                            Dátum dokončenia
                          </p>

                          <h3>
                            <template
                                v-if="TicketItem.deadlineAt"
                            >
                              {{ new Date(TicketItem.deadlineAt)|dateFormat('DD.MM.YYYY') }}
                            </template>

                            <template
                                v-else
                            >
                              Nevyplnené
                            </template>
                          </h3>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <template
                    v-if="TicketItem.Ticket.Project"
                >
                  <v-expansion-panel
                      v-if="['ROLE_MASTER'].includes($store.state.auth.User.role)"
                  >
                    <v-expansion-panel-header>
                      <span>
                        <v-icon
                            color="secondary"
                        >
                          mdi-shield-lock
                        </v-icon>

                        Prístupy - Produkčné

                        &nbsp;

                        <ProjectUpdateTicketItemAuth
                            :icon="true"
                            :ticketItemId="TicketItem.id"
                            :x-small="true"
                            @init="refresh"
                        />
                      </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <div
                          v-if="TicketItem.Ticket.Project.noteProduction"
                          v-html="TicketItem.Ticket.Project.noteProduction"
                          class="ql-editor"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span>
                        <v-icon
                            color="secondary"
                        >
                          mdi-shield-account
                        </v-icon>

                        Prístupy - Vývojové

                        &nbsp;

                        <ProjectUpdateTicketItemAuth
                            :icon="true"
                            :ticketItemId="TicketItem.id"
                            :x-small="true"
                            @init="refresh"
                        />
                      </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <div
                          v-if="TicketItem.Ticket.Project.noteDevelopment"
                          v-html="TicketItem.Ticket.Project.noteDevelopment"
                          class="ql-editor"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </template>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-tabs
              v-model="tabsWindow"
              color="secondary"
          >
            <v-tab
                class="yellow lighten-3"
            >
              Vykonané práce
            </v-tab>
          </v-tabs>

          <v-card>
            <v-card-text>
              <v-tabs-items
                  v-model="tabsWindow"
              >
                <v-tab-item>
                  <TicketItemReportsAuth
                      :TicketItem="TicketItem"
                      @refresh="refresh"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-progress-circular
      v-else
      :indeterminate="true"
  />
</template>

<script>
  import { dateFormat } from '@vuejs-community/vue-filter-date-format'

  import TicketItems from '@/services/ticket-items'

  import TicketItemReportsAuth from '@/components/TicketItemAuth/TicketItemReportsAuth'
  import ProjectUpdateTicketItemAuth from '@/components/TicketItemAuth/ProjectUpdateTicketItemAuth'

  import SuggestUsers from '@/components/Users/Suggest'

  export default {
    name: 'TicketItemAuth',
    components: {
      TicketItemReportsAuth,
      ProjectUpdateTicketItemAuth,
      SuggestUsers
    },
    data() {
      return {
        content: false,
        loading: false,
        TicketItem: {},
        TicketItemForm: {},
        errors: {},
        tabsWindow: 0,
        expansionPanelsSetting: [
          0
        ]
      }
    },
    metaInfo() {
      return {
        title: this.TicketItem.title
      }
    },
    mounted() {
      this.init()
    },
    watch: {
      $route(to, from) {
        if (to.path !== from.path) {
          this.init()
        }
      }
    },
    computed: {
      sortedTicketItemReminds() {
        const sortedTicketItemReminds = this.TicketItem.ticketItemReminds

        sortedTicketItemReminds.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

        return sortedTicketItemReminds;
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      refresh() {
        this.loadData('loading', true)
      },
      loadData(key = 'content', value = false) {
        this[key] = value

        TicketItems
          .readAuth({
            id: this.$route.params.id
          })
          .then((response) => {
            this.TicketItem = response.data.data.TicketItem

            if (response.data.data.TicketItem.updatable) {
              if (response.data.data.TicketItem.updatableThirdStep) {
                this.TicketItemForm = {
                  ...this.TicketItemForm,
                  User: (
                    response.data.data.TicketItem.User
                      ? response.data.data.TicketItem.User.id
                      : ''
                  ),
                  status: response.data.data.TicketItem.status,
                  deadlineAt: (
                    response.data.data.TicketItem.deadlineAt
                      ? dateFormat(new Date(response.data.data.TicketItem.deadlineAt), 'DD.MM.YYYY')
                      : ''
                  )
                }
              }
            }
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/home')

                break
            }
          })
          .finally(() => {
            this[key] = !value
          })
      },
      submit() {
        this.loading = true

        TicketItems
          .updateAuth({
            id: this.$route.params.id,
            data: this.TicketItemForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.errors = {}

            this.refresh()
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 403:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/home')

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
