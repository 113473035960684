<template>
  <v-textarea
      v-if="['ROLE_MASTER', 'ROLE_EDITOR', 'ROLE_MANAGER'].includes($store.state.auth.User.role) && UserRemind.updatable"
      v-model="UserRemindForm.content"
      :error-messages="errors.content"
      :outlined="true"
      :dense="true"
      :label="new Date(UserRemind.createdAt)|dateFormat('DD.MM.YYYY')"
      :rows="1"
      @change="submit"
  >
    <template
        v-slot:prepend
    >
      <v-checkbox
          v-model="UserRemindForm.strikeOut"
          :error-messages="errors.strikeOut"
          class="ma-0 pa-0"
          @change="submit"
      />
    </template>

    <template
        v-slot:append-outer
    >
      <v-btn
          v-if="UserRemind.archivable"
          :small="true"
          :icon="true"
          :outlined="true"
          color="secondary"
          @click="makeArchive"
      >
        <v-icon>
          mdi-archive
        </v-icon>
      </v-btn>

      <v-btn
          v-if="UserRemind.deletable"
          :small="true"
          :icon="true"
          :outlined="true"
          color="error"
          @click="deleteUserRemind"
      >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>
  </v-textarea>

  <p
      v-else
      :class="
        UserRemind.strikeOut
          ? 'text-decoration-line-through'
          : ''
      "
  >
    {{ new Date(UserRemind.createdAt)|dateFormat('DD.MM.YYYY') }} - {{ UserRemind.content }}
  </p>
</template>

<script>
  import UserReminds from '@/services/user-reminds'

  export default {
    name: 'UserRemind',
    props: [
      'UserRemind'
    ],
    data() {
      return {
        UserRemindForm: {},
        errors: {}
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.loadData()
      },
      deleteUserRemind() {
        UserReminds
          .delete({
            id: this.UserRemind.id
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.$emit('refresh')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 403:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/home')

                break
            }
          })
      },
      loadData() {
        if (['ROLE_MASTER', 'ROLE_EDITOR', 'ROLE_MANAGER'].includes(this.$store.state.auth.User.role) && this.UserRemind.updatable) {
          this.UserRemindForm = {
            content: this.UserRemind.content,
            strikeOut: this.UserRemind.strikeOut,
            archive: this.UserRemind.archive
          }
        }

        this.errors = {}
      },
      makeArchive() {
        this.UserRemindForm.archive = true

        this.submit()
      },
      submit() {
        UserReminds
          .update({
            id: this.UserRemind.id,
            data: this.UserRemindForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.$emit('refresh')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 403:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/home')

                break
            }
          })
      }
    }
  }
</script>
