import Vue from 'vue'

import Qs from 'qs'

class Tickets {
  async index({params}) {
    return await Vue.axios
      .get('admin/tickets', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async read({id}) {
    return await Vue.axios
      .get('admin/tickets/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('admin/tickets/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('admin/tickets/' + id)
  }
}

const tickets = new Tickets()

export default tickets
