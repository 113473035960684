import Vue from 'vue'

import Qs from 'qs'

class Services {
  async index({params}) {
    return await Vue.axios
      .get('admin/services', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('admin/services', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('admin/services/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('admin/services/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('admin/services/' + id)
  }
}

const services = new Services()

export default services
