<template>
  <v-card>
    <v-card-title>
      Sumár

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-progress-linear
              v-if="loading"
              :indeterminate="true"
          />

          <v-row>
            <v-col
                cols="12"
                lg="4"
                class="text-center"
            >
              <p>
                Celkové plusy
              </p>

              <h1>
                {{ Statistic.pricePlus }} €
              </h1>
            </v-col>

            <v-col
                cols="12"
                lg="4"
                class="text-center"
            >
              <p>
                Celkové mínusy
              </p>

              <h1>
                {{ Statistic.priceMinus }} €
              </h1>
            </v-col>

            <v-col
                cols="12"
                lg="4"
                class="text-center"
            >
              <p>
                Celkový výsledok
              </p>

              <h1>
                {{ Statistic.priceBalance }} €
              </h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                lg="6"
                class="text-center"
            >
              <highcharts
                  :options="plusMinusPie"
              />
            </v-col>

            <v-col
                cols="12"
                lg="6"
                class="text-center"
            >
              <highcharts
                  :options="minusPie"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                lg="6"
                class="text-center"
            >
              <p>
                Výsledok pred budúcim zaplatením
              </p>

              <h1>
                {{ Statistic.priceBalanceBeforePaid }} €
              </h1>
            </v-col>

            <v-col
                cols="12"
                lg="6"
                class="text-center"
            >
              <p>
                Výsledok po budúcom zaplatení
              </p>

              <h1>
                {{ Statistic.priceBalanceAfterPaid }} €
              </h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                lg="4"
                class="text-center"
            >
              <v-row>
                <v-col
                    cols="12"
                    class="text-center"
                >
                  <highcharts
                      :options="spendPie"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    class="text-center"
                >
                  <highcharts
                      :options="spendWillPie"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col
                cols="12"
                lg="4"
                class="text-center"
            >
              <v-row>
                <v-col
                    cols="12"
                    class="text-center"
                >
                  <highcharts
                      :options="bonusPie"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    class="text-center"
                >
                  <highcharts
                      :options="bonusWillPie"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col
                cols="12"
                lg="4"
                class="text-center"
            >
              <v-row>
                <v-col
                    cols="12"
                    class="text-center"
                >
                  <highcharts
                      :options="provisionPie"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    class="text-center"
                >
                  <highcharts
                      :options="provisionWillPie"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                lg="4"
                class="text-center"
            >
              <p>
                Potencionálne cenové ponuky
              </p>

              <h1>
                {{ Statistic.pricelistTotalInQueue }} €
              </h1>
            </v-col>

            <v-col
                cols="12"
                lg="4"
                class="text-center"
            >
              <p>
                Potencionálne fakturácie
              </p>

              <h1>
                {{ Statistic.invoiceBalance }} €
              </h1>
            </v-col>

            <v-col
                cols="12"
                lg="4"
                class="text-center"
            >
              <p>
                Potencionálne podiely
              </p>

              <h1>
                {{ Statistic.shareBalance }} €
              </h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                lg="6"
                class="text-center"
            >
              <p>
                Potencionálna celková fakturácia
              </p>

              <h1>
                {{ Statistic.invoiceTotal }} €
              </h1>
            </v-col>

            <v-col
                cols="12"
                lg="6"
                class="text-center"
            >
              <p>
                Potencionálne celkové podiely
              </p>

              <h1>
                {{ Statistic.shareTotal }} €
              </h1>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import Statistics from '@/services/statistics'

  export default {
    name: 'Statistics',
    data() {
      return {
        loading: false,
        Statistic: {}
      }
    },
    metaInfo() {
      return {
        title: 'Sumár'
      }
    },
    mounted() {
      this.init()
    },
    computed: {
      plusMinusPie() {
        return {
          title: {
            text: 'Vyjadruje aký máme pomer plusov a mínusov'
          },
          credits: false,
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          legend: {
            enabled: true,
            labelFormat: '{name}: {y:.2f} € ({percentage:.2f} %)'
          },
          series: [
            {
              type: 'pie',
              name: 'Hodnoty',
              data: [
                {
                  name: 'Plusy',
                  color: '#1b7f0e',
                  y: this.Statistic.pricePlus
                },
                {
                  name: 'Mínusy',
                  color: '#7f0e0e',
                  y: this.Statistic.priceMinus
                }
              ]
            }
          ]
        }
      },
      minusPie() {
        return {
          title: {
            text: 'Vyjadruje aký máme pomer mínusov'
          },
          credits: false,
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          legend: {
            enabled: true,
            labelFormat: '{name}: {y:.2f} € ({percentage:.2f} %)'
          },
          series: [
            {
              type: 'pie',
              name: 'Mínusy',
              data: [
                {
                  name: 'Výplaty',
                  color: '#b34343',
                  y: this.Statistic.priceSpend
                },
                {
                  name: 'Výdavky',
                  color: '#ba6767',
                  y: this.Statistic.priceExpense
                },
                {
                  name: 'Bonusy',
                  color: '#ef8f8f',
                  y: this.Statistic.priceBonus
                },
                {
                  name: 'Provízie',
                  color: '#f5adad',
                  y: this.Statistic.priceProvision
                }
              ]
            }
          ]
        }
      },
      spendPie() {
        return {
          title: {
            text: 'Vyjadruje aký máme pomer výplat'
          },
          credits: false,
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          legend: {
            enabled: true,
            labelFormat: '{name}: {y:.2f} € ({percentage:.2f} %)'
          },
          series: [
            {
              type: 'pie',
              name: 'Výplaty',
              data: [
                {
                  name: 'Nezaplatené',
                  color: '#ef9a9a',
                  y: this.Statistic.priceSpendNotPaid
                },
                {
                  name: 'Zaplatené',
                  color: '#a5d6a7',
                  y: this.Statistic.priceSpendPaid
                }
              ]
            }
          ]
        }
      },
      spendWillPie() {
        return {
          title: {
            text: 'Vyjadruje aký máme pomer nezaplatených výplat'
          },
          credits: false,
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          legend: {
            enabled: true,
            labelFormat: '{name}: {y:.2f} € ({percentage:.2f} %)'
          },
          series: [
            {
              type: 'pie',
              name: 'Výplaty',
              data: [
                {
                  name: 'Nebudú zaplatené',
                  color: '#ef9a9a',
                  y: this.Statistic.priceSpendWillNotBePaid
                },
                {
                  name: 'Budú zaplatené',
                  color: '#a5d6a7',
                  y: this.Statistic.priceSpendWillBePaid
                }
              ]
            }
          ]
        }
      },
      bonusPie() {
        return {
          title: {
            text: 'Vyjadruje aký máme pomer bonusov'
          },
          credits: false,
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          legend: {
            enabled: true,
            labelFormat: '{name}: {y:.2f} € ({percentage:.2f} %)'
          },
          series: [
            {
              type: 'pie',
              name: 'Bonusy',
              data: [
                {
                  name: 'Nezaplatené',
                  color: '#ef9a9a',
                  y: this.Statistic.priceBonusNotPaid
                },
                {
                  name: 'Zaplatené',
                  color: '#a5d6a7',
                  y: this.Statistic.priceBonusPaid
                }
              ]
            }
          ]
        }
      },
      bonusWillPie() {
        return {
          title: {
            text: 'Vyjadruje aký máme pomer nezaplatených bonusov'
          },
          credits: false,
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          legend: {
            enabled: true,
            labelFormat: '{name}: {y:.2f} € ({percentage:.2f} %)'
          },
          series: [
            {
              type: 'pie',
              name: 'Výplaty',
              data: [
                {
                  name: 'Nebudú zaplatené',
                  color: '#ef9a9a',
                  y: this.Statistic.priceBonusWillNotBePaid
                },
                {
                  name: 'Budú zaplatené',
                  color: '#a5d6a7',
                  y: this.Statistic.priceBonusWillBePaid
                }
              ]
            }
          ]
        }
      },
      provisionPie() {
        return {
          title: {
            text: 'Vyjadruje aký máme pomer provízií'
          },
          credits: false,
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          legend: {
            enabled: true,
            labelFormat: '{name}: {y:.2f} € ({percentage:.2f} %)'
          },
          series: [
            {
              type: 'pie',
              name: 'Provízie',
              data: [
                {
                  name: 'Nezaplatené',
                  color: '#ef9a9a',
                  y: this.Statistic.priceProvisionNotPaid
                },
                {
                  name: 'Zaplatené',
                  color: '#a5d6a7',
                  y: this.Statistic.priceProvisionPaid
                }
              ]
            }
          ]
        }
      },
      provisionWillPie() {
        return {
          title: {
            text: 'Vyjadruje aký máme pomer nezaplatených provízií'
          },
          credits: false,
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          legend: {
            enabled: true,
            labelFormat: '{name}: {y:.2f} € ({percentage:.2f} %)'
          },
          series: [
            {
              type: 'pie',
              name: 'Výplaty',
              data: [
                {
                  name: 'Nebudú zaplatené',
                  color: '#ef9a9a',
                  y: this.Statistic.priceProvisionWillNotBePaid
                },
                {
                  name: 'Budú zaplatené',
                  color: '#a5d6a7',
                  y: this.Statistic.priceProvisionWillBePaid
                }
              ]
            }
          ]
        }
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      loadData() {
        this.loading = true

        Statistics
          .readDash()
          .then((response) => {
            this.Statistic = response.data.data.Statistic
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
