<template>
  <Tickets
      type="rejected"
      title="Zamietnuté tikety"
  />
</template>

<script>
  import Tickets from '@/views/Tickets'

  export default {
    name: 'TicketsRejected',
    components: {
      Tickets
    }
  }
</script>
