<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="success"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-plus
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          Vytvoriť bonus

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre vytvorenie bonusu je potrebné vyplniť všetky povinné polia.
          </p>

          <SuggestUsers
              v-model="BonusForm.User"
              :error-messages="errors.User"
              :outlined="true"
              :dense="true"
              label="Pracovník"
              :clearable="true"
              :createButton="true"
          />

          <v-text-field
              v-model="BonusForm.title"
              :error-messages="errors.title"
              :outlined="true"
              :dense="true"
              label="Názov"
          />

          <v-text-field
              v-model="BonusForm.priceBonus"
              :error-messages="errors.priceBonus"
              :outlined="true"
              :dense="true"
              label="Suma (€)"
          />

          <v-text-field
              v-model="BonusForm.workedAt"
              :error-messages="errors.workedAt"
              :outlined="true"
              :dense="true"
              label="Dátum vykonania"
          />

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="success"
              @click="submit"
          >
            <v-icon>
              mdi-plus
            </v-icon>

            Vytvoriť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import { dateFormat } from '@vuejs-community/vue-filter-date-format'

  import Bonuses from '@/services/bonuses'

  import SuggestUsers from '@/components/Users/Suggest'

  export default {
    name: 'Create',
    components: {
      SuggestUsers
    },
    data() {
      return {
        loading: false,
        dialog: false,
        BonusForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = true

        this.BonusForm = {
          User: null,
          title: '',
          priceBonus: 0,
          workedAt: dateFormat(new Date(), 'DD.MM.YYYY')
        }

        this.errors = {}
      },
      submit() {
        this.loading = true

        Bonuses
          .create({
            data: this.BonusForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
