import Vue from 'vue'

class TicketExpenses {
  async createTicket({ticketId, data}) {
    return await Vue.axios
      .post('admin/ticket-expenses-by-ticket/' + ticketId, data)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('admin/ticket-expenses/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('admin/ticket-expenses/' + id)
  }
}

const ticketExpenses = new TicketExpenses()

export default ticketExpenses
