import Vue from 'vue'

class Files {
  async uploadImage({data}) {
    return await Vue.axios
      .post('admin/files/upload/image', data)
  }
}

const files = new Files()

export default files
