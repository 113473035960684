<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="primary"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-file
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="1000"
    >
      <v-card>
        <v-card-title>
          {{ Revenue.title }}

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle>
          {{ new Date(Revenue.workedAt)|dateFormat('DD.MM.YYYY') }}
        </v-card-subtitle>

        <v-card-text>
          <v-simple-table>
            <template
                v-slot:default
            >
              <thead>
                <tr>
                  <th
                      class="fixed"
                  >
                    Dátum doručenia
                  </th>

                  <th>
                    Predmet
                  </th>

                  <th
                      class="green lighten-3"
                  >
                    Časť (€)
                  </th>

                  <th
                      class="text-right fixed"
                  />
                </tr>
              </thead>

              <tbody>
                <template
                    v-if="Revenue.ticketRevenues.length > 0"
                >
                  <tr
                      v-for="(TicketRevenue, i) in sortedTicketRevenues"
                      :key="i"
                      :class="$store.state.register.Ticket.statusItems[TicketRevenue.Ticket.statusItems].class"
                  >
                    <td
                        class="fixed"
                    >
                      {{ new Date(TicketRevenue.Ticket.deliveredAt)|dateFormat('DD.MM.YYYY HH:mm') }}
                    </td>

                    <td>
                      <span
                          class="text-truncate"
                          style="max-width: 300px"
                      >
                        {{ TicketRevenue.Ticket.subject }}
                      </span>
                    </td>

                    <td
                        class="green lighten-3"
                    >
                      {{ TicketRevenue.pricePart }}
                    </td>

                    <td
                        class="text-right fixed"
                    >
                      <v-btn
                          :icon="true"
                          :outlined="true"
                          color="primary"
                          :to="'/ticket/' + TicketRevenue.Ticket.id"
                      >
                        <v-icon>
                          mdi-magnify
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template
                    v-else
                >
                  <tr>
                    <td
                        :colspan="4"
                        class="text-center"
                    >
                      Nenašli sa žiadne tikety.
                    </td>
                  </tr>
                </template>
              </tbody>

              <tfoot>
                <tr>
                  <th
                      :colspan="2"
                  >
                    Medzisúčet (€)
                  </th>

                  <th
                      class="green lighten-3"
                  >
                    {{ Revenue.pricePartTickets }}
                  </th>

                  <th/>
                </tr>

                <tr>
                  <th
                      :colspan="2"
                  >
                    Suma (€)
                  </th>

                  <th
                      class="green lighten-3"
                  >
                    {{ Revenue.priceRevenue }}
                  </th>

                  <th/>
                </tr>

                <tr>
                  <th
                      :colspan="2"
                  >
                    Zostatok (€)
                  </th>

                  <th
                      class="green lighten-3"
                  >
                    {{ Revenue.balance }}
                  </th>

                  <th/>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Revenues from '@/services/revenues'

  export default {
    name: 'Tickets',
    props: [
      'id'
    ],
    data() {
      return {
        loading: false,
        dialog: false,
        Revenue: {}
      }
    },
    computed: {
      sortedTicketRevenues() {
        const sortedTicketRevenues = this.Revenue.ticketRevenues

        sortedTicketRevenues.sort((a, b) => new Date(b.Ticket.deliveredAt) - new Date(a.Ticket.deliveredAt))

        return sortedTicketRevenues
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Revenues
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Revenue = response.data.data.Revenue
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.dialog = true

            this.loading = false
          })
      }
    }
  }
</script>
