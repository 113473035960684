<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="warning"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-pencil
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          Upraviť recenziu

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre upravenie recenzie je potrebné vyplniť všetky povinné polia.
          </p>

          <ImageFiles
              :outlined="true"
              :dense="true"
              label="Obrázok"
              :File="ReviewForm.File"
              :errorsFile="errors.File"
              @setFile="setFile"
          />

          <v-text-field
              v-model="ReviewForm.author"
              :error-messages="errors.author"
              :outlined="true"
              :dense="true"
              label="Autor"
          />

          <SuggestClients
              v-model="ReviewForm.Client"
              :error-messages="errors.Client"
              :outlined="true"
              :dense="true"
              label="Klient"
              :clearable="true"
              :createButton="['ROLE_MASTER'].includes($store.state.auth.User.role)"
              @change="() => {
                ReviewForm.Project = ''
              }"
          />

          <SuggestProjects
              v-model="ReviewForm.Project"
              :error-messages="errors.Project"
              :outlined="true"
              :dense="true"
              label="Projekt"
              :clearable="true"
              :createButton="['ROLE_MASTER'].includes($store.state.auth.User.role)"
              :support="(projects) => projects.filter((Project) => (
                ReviewForm.Client
                  ? Project.Client.id === ReviewForm.Client
                  : false
              ))"
          />

          <v-textarea
              v-model="ReviewForm.description"
              :error-messages="errors.description"
              :outlined="true"
              :dense="true"
              label="Popis"
              :rows="6"
          />

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="warning"
              @click="submit"
          >
            <v-icon>
              mdi-pencil
            </v-icon>

            Upraviť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Reviews from '@/services/reviews'

  import SuggestClients from '@/components/Clients/Suggest'
  import SuggestProjects from '@/components/Projects/Suggest'

  import ImageFiles from '@/components/Files/Image'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    components: {
      SuggestClients,
      SuggestProjects,
      ImageFiles
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Review: {},
        ReviewForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Reviews
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Review = response.data.data.Review

            this.ReviewForm = {
              File: (
                response.data.data.Review.File
                  ? response.data.data.Review.File.id
                  : ''
              ),
              author: response.data.data.Review.author,
              Client: (
                response.data.data.Review.Client
                  ? response.data.data.Review.Client.id
                  : ''
              ),
              Project: (
                response.data.data.Review.Project
                  ? response.data.data.Review.Project.id
                  : ''
              ),
              description: response.data.data.Review.description
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.dialog = true

            this.loading = false
          })
      },
      setFile(File) {
        this.ReviewForm.File = File
      },
      submit() {
        this.loading = true

        Reviews
          .update({
            id: this.id,
            data: this.ReviewForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
