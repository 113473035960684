import Vue from 'vue'

class ReportStatistics {
  async allAccountReportedAtYear({accountId, reportedAtYear}) {
    return await Vue.axios
      .get('admin/report-statistics/all-by-account-and-reported-at-year/' + accountId + '/' + reportedAtYear)
  }
}

const reportStatistics = new ReportStatistics()

export default reportStatistics
