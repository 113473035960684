<template>
  <Tickets
      type="in-progress"
      title="Rozpracované tikety"
  />
</template>

<script>
  import Tickets from '@/views/Tickets'

  export default {
    name: 'TicketsInProgress',
    components: {
      Tickets
    }
  }
</script>
